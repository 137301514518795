import React, { Component } from "react";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default class ProjectSelecter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            status: "Actif",
            anchorEl: null,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(event){ this.setState({anchorEl: event.currentTarget})  }

    selectProject(status){
        this.setState(
            {
                status:  status,
                anchorEl: null
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(status)
                }
            }        
        )
    }

    render() { 

        const {status, anchorEl} = this.state

        return(
            <React.Fragment>
                <Card elevation={0}  sx={{ px:2, py: 0.5, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center'}}>
                    <Typography sx={{marginRight: 4, fontSize: 12}}>Statut: <span style={{color:'#306bff'}}>{status}</span></Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton onClick={this.handleClick}>
                        <ExpandMoreIcon sx={{color:'#306bff', fontSize: 12}}/>
                    </IconButton>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => {this.setState({ anchorEl: null})}}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            sx: {
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 2,
                                ml: -2
                            }
                        }}
                    >
                        <MenuItem 
                            onClick={() => this.selectProject("Actif")} 
                            sx={status === "Actif" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                        >
                            Actif
                        </MenuItem>

                        <MenuItem 
                            onClick={() => this.selectProject("Non actif")} 
                            sx={status === "Non actif" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                        >
                            Non actif
                        </MenuItem>

                        <MenuItem 
                            onClick={() => this.selectProject("Tout")} 
                            sx={status === "Tout" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                        >
                            Tout
                        </MenuItem>
                    </Menu>
                </Card>
            </React.Fragment>
        )
    }

}