import React, { Component } from "react";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList'; 
import StatusSelecter from '../../Components/StatusSelecter'
import Search from "../../Components/Search"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const initialStats = {
    name: "",
    dashbord:{
        read: false,
    },

    projects: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    banks: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    registers: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    invoices: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    employes: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    letters: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    events: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    userss: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    roless: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    csociales: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    sanction: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    conge: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    partners: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    providers: {
        read: false,
        write: false,
        remove: false,
        approve:false
    },

    logs: {
        read: false
    },
}

export default class Roles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            roles: [],
            accesses: [],
            users: [],

            open: false,
            add: false,
            edit: false,
            id_role: null,

            valuetab:"1",
            ...initialStats,

            status: "Actif",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",
            anchorEl: [],
        }
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Rôles`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/roles/all', config)
        .then(response => {            
            this.setState({ 
                roles: response.data,
                loadingPage: false, loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        });   
    }

    ChangeStatus(status){
        this.setState({status: status}) 
    }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    ChangeSearch(search){ this.setState({search: search}) }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            name: this.state.name,
            accesses: [
                {
                    "module": "Dashboard",
                    "read": this.state.dashbord.read
                },
                {
                    "module": "Gestion des Projets",
                    "read": this.state.projects.read,
                    "write": this.state.projects.write,
                    "remove": this.state.projects.remove,
                    "approve": this.state.projects.approve
                },
                {
                    "module": "Gestion des banques",
                    "read": this.state.banks.read,
                    "write": this.state.banks.write,
                    "remove": this.state.banks.remove,
                    "approve": this.state.banks.approve
                },
                {
                    "module": "Gestion des caisses",
                    "read": this.state.registers.read,
                    "write": this.state.registers.write,
                    "remove": this.state.registers.remove,
                    "approve": this.state.registers.approve
                },
                {
                    "module": "Facturation",
                    "read": this.state.invoices.read,
                    "write": this.state.invoices.write,
                    "remove": this.state.invoices.remove,
                    "approve": this.state.invoices.approve
                },
                {
                    "module": "Gestion RH",
                    "read": this.state.employes.read,
                    "write": this.state.employes.write,
                    "remove": this.state.employes.remove,
                    "approve": this.state.employes.approve
                },
                {
                    "module": "Gestion des courriers",
                    "read": this.state.letters.read,
                    "write": this.state.letters.write,
                    "remove": this.state.letters.remove,
                    "approve": this.state.letters.approve
                },
                {
                    "module": "Gestion des événements",
                    "read": this.state.events.read,
                    "write": this.state.events.write,
                    "remove": this.state.events.remove,
                    "approve": this.state.events.approve
                },
                {
                    "module": "Gestion des utilisateurs",
                    "read": this.state.userss.read,
                    "write": this.state.userss.write,
                    "remove": this.state.userss.remove,
                    "approve": this.state.userss.approve
                },
                {
                    "module": "Gestion des rôles",
                    "read": this.state.roless.read,
                    "write": this.state.roless.write,
                    "remove": this.state.roless.remove,
                    "approve": this.state.roless.approve
                },
                {
                    "module": "Cotisations sociales",
                    "read": this.state.csociales.read,
                    "write": this.state.csociales.write,
                    "remove": this.state.csociales.remove,
                    "approve": this.state.csociales.approve
                },
                {
                    "module": "Sanctions disciplinaire",
                    "read": this.state.sanction.read,
                    "write": this.state.sanction.write,
                    "remove": this.state.sanction.remove,
                    "approve": this.state.sanction.approve
                },
                {
                    "module": "Types de congés",
                    "read": this.state.conge.read,
                    "write": this.state.conge.write,
                    "remove": this.state.conge.remove,
                    "approve": this.state.conge.approve
                },
                {
                    "module": "Gestion des partenaires techniques et financiers",
                    "read": this.state.partners.read,
                    "write": this.state.partners.write,
                    "remove": this.state.partners.remove,
                    "approve": this.state.partners.approve
                },
                {
                    "module": "Gestion des fournisseurs",
                    "read": this.state.providers.read,
                    "write": this.state.providers.write,
                    "remove": this.state.providers.remove,
                    "approve": this.state.providers.approve
                },
                {
                    "module": "Traces des activites des utilisateurs",
                    "read": this.state.logs.read
                },
            ]
        }
        axios.post( 
            '/api/v1/roles',
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ message:"Rôle enregistré avec succès", add: false, isDid: true});
            this.componentDidMount()   
            this.props.onChange()       
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            name: this.state.name,
            accesses: [
                {
                    "module": "Dashboard",
                    "read": this.state.dashbord.read
                },
                {
                    "module": "Gestion des Projets",
                    "read": this.state.projects.read,
                    "write": this.state.projects.write,
                    "remove": this.state.projects.remove,
                    "approve": this.state.projects.approve
                },
                {
                    "module": "Gestion des banques",
                    "read": this.state.banks.read,
                    "write": this.state.banks.write,
                    "remove": this.state.banks.remove,
                    "approve": this.state.banks.approve
                },
                {
                    "module": "Gestion des caisses",
                    "read": this.state.registers.read,
                    "write": this.state.registers.write,
                    "remove": this.state.registers.remove,
                    "approve": this.state.registers.approve
                },
                {
                    "module": "Facturation",
                    "read": this.state.invoices.read,
                    "write": this.state.invoices.write,
                    "remove": this.state.invoices.remove,
                    "approve": this.state.invoices.approve
                },
                {
                    "module": "Gestion RH",
                    "read": this.state.employes.read,
                    "write": this.state.employes.write,
                    "remove": this.state.employes.remove,
                    "approve": this.state.employes.approve
                },
                {
                    "module": "Gestion des courriers",
                    "read": this.state.letters.read,
                    "write": this.state.letters.write,
                    "remove": this.state.letters.remove,
                    "approve": this.state.letters.approve
                },
                {
                    "module": "Gestion des événements",
                    "read": this.state.events.read,
                    "write": this.state.events.write,
                    "remove": this.state.events.remove,
                    "approve": this.state.events.approve
                },
                {
                    "module": "Gestion des utilisateurs",
                    "read": this.state.userss.read,
                    "write": this.state.userss.write,
                    "remove": this.state.userss.remove,
                    "approve": this.state.userss.approve
                },
                {
                    "module": "Gestion des rôles",
                    "read": this.state.roless.read,
                    "write": this.state.roless.write,
                    "remove": this.state.roless.remove,
                    "approve": this.state.roless.approve
                },
                {
                    "module": "Cotisations sociales",
                    "read": this.state.csociales.read,
                    "write": this.state.csociales.write,
                    "remove": this.state.csociales.remove,
                    "approve": this.state.csociales.approve
                },
                {
                    "module": "Sanctions disciplinaire",
                    "read": this.state.sanction.read,
                    "write": this.state.sanction.write,
                    "remove": this.state.sanction.remove,
                    "approve": this.state.sanction.approve
                },
                {
                    "module": "Types de congés",
                    "read": this.state.conge.read,
                    "write": this.state.conge.write,
                    "remove": this.state.conge.remove,
                    "approve": this.state.conge.approve
                },
                {
                    "module": "Gestion des partenaires techniques et financiers",
                    "read": this.state.partners.read,
                    "write": this.state.partners.write,
                    "remove": this.state.partners.remove,
                    "approve": this.state.partners.approve
                },
                {
                    "module": "Gestion des fournisseurs",
                    "read": this.state.providers.read,
                    "write": this.state.providers.write,
                    "remove": this.state.providers.remove,
                    "approve": this.state.providers.approve
                },
                {
                    "module": "Traces des activites des utilisateurs",
                    "read": this.state.logs.read
                },
            ]
        }
        axios.put( 
            '/api/v1/roles/' + this.state.id_role,
            bodyParameters,
            config
        ).then(response => {                     
            this.setState({ message:"Rôle modifié avec succès", isDid: true, edit:false, anchorEl: []});
            this.componentDidMount()  
            this.props.onChange()        
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });  
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    show(id){
        this.setState({loading: true}) 
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/roles/' + id, config)
        .then(response => {            
            this.setState({ 
                name: response.data.name,
                accesses: response.data.accesses,
                users: response.data.users,
                open: true,
                loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    edit(id){
        this.setState({loading: true}) 
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/roles/' + id, config)
        .then(response => {            
            this.setState({ 
                id_role: id,
                name: response.data.name,
                dashbord: response.data.accesses[0], 
                projects: response.data.accesses[1], 
                banks: response.data.accesses[2], 
                registers: response.data.accesses[3], 
                invoices: response.data.accesses[4], 
                employes: response.data.accesses[5], 
                letters: response.data.accesses[6], 
                events: response.data.accesses[7], 
                userss: response.data.accesses[8], 
                roless: response.data.accesses[9], 
                csociales: response.data.accesses[10], 
                sanction: response.data.accesses[11], 
                conge: response.data.accesses[12], 
                partners: response.data.accesses[13], 
                providers: response.data.accesses[14], 
                logs: response.data.accesses[15], 
                loading: false,
                edit: true
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    delete(id){
        this.setState({loading: true}) 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/roles/' + id,
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true });
            this.componentDidMount()
            this.props.onChange()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    render() {

        const {
            roles,
            accesses,
            users,
            open,
            add,
            edit,
            name,

            valuetab,
            search,

            dashbord,
            projects,
            banks,
            registers,
            invoices,
            employes,
            letters,
            events,
            userss,
            roless,
            csociales,
            sanction,
            conge,
            partners,
            providers,
            logs,

            status,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl

        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2
            },
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton disabled={!this.props.accesses.approve} onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton disabled={!this.props.accesses.approve} onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem onClick={() => { this.edit(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            },
        ]

        let Dataroles=[]
        if(status == 'Tout'){
            Dataroles = roles
        }
        if(status == 'Actif'){
            Dataroles = roles.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            Dataroles = roles.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            Dataroles = Dataroles.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({add: true, ...initialStats})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouveau</Button>
                    </Grid> 

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={Dataroles}
                                columns={column}
                                loading={loadingPage}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un rôle
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit} style={{marginTop:-20}}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du rôle"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Sélectionner tout:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                dashbord.read && projects.read && banks.read && registers.read && 
                                                invoices.read && employes.read && letters.read && events.read && 
                                                userss.read && roless.read && csociales.read && sanction.read && 
                                                conge.read && partners.read && providers.read && logs.read
                                            } 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    dashbord: {read:e.target.checked},
                                                    projects:{
                                                        read:e.target.checked,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: this.state.projects.approve
                                                    },
                                                    banks: {
                                                        read:e.target.checked,
                                                        write: this.state.banks.write,
                                                        remove: this.state.banks.remove,
                                                        approve: this.state.banks.approve
                                                    },
                                                    registers: {
                                                        read:e.target.checked,
                                                        write: this.state.registers.write,
                                                        remove: this.state.registers.remove,
                                                        approve: this.state.registers.approve
                                                    },
                                                    invoices: {
                                                        read:e.target.checked,
                                                        write: this.state.invoices.write,
                                                        remove: this.state.invoices.remove,
                                                        approve: this.state.invoices.approve
                                                    },
                                                    employes: {
                                                        read:e.target.checked,
                                                        write: this.state.employes.write,
                                                        remove: this.state.employes.remove,
                                                        approve: this.state.employes.approve
                                                    },
                                                    letters: {
                                                        read:e.target.checked,
                                                        write: this.state.letters.write,
                                                        remove: this.state.letters.remove,
                                                        approve: this.state.letters.approve
                                                    },
                                                    events: {
                                                        read:e.target.checked,
                                                        write: this.state.events.write,
                                                        remove: this.state.events.remove,
                                                        approve: this.state.events.approve
                                                    },
                                                    userss: {
                                                        read:e.target.checked,
                                                        write: this.state.userss.write,
                                                        remove: this.state.userss.remove,
                                                        approve: this.state.userss.approve
                                                    },
                                                    roless: {
                                                        read:e.target.checked,
                                                        write: this.state.roless.write,
                                                        remove: this.state.roless.remove,
                                                        approve: this.state.roless.approve
                                                    },
                                                    csociales: {
                                                        read:e.target.checked,
                                                        write: this.state.csociales.write,
                                                        remove: this.state.csociales.remove,
                                                        approve: this.state.csociales.approve
                                                    },
                                                    sanction: {
                                                        read:e.target.checked,
                                                        write: this.state.sanction.write,
                                                        remove: this.state.sanction.remove,
                                                        approve: this.state.sanction.approve  
                                                    },
                                                    conge: {
                                                        read:e.target.checked,
                                                        write: this.state.conge.write,
                                                        remove: this.state.conge.remove,
                                                        approve: this.state.conge.approve  
                                                    },
                                                    partners: {
                                                        read:e.target.checked,
                                                        write: this.state.partners.write,
                                                        remove: this.state.partners.remove,
                                                        approve: this.state.partners.approve  
                                                    },
                                                    providers: {
                                                        read:e.target.checked,
                                                        write: this.state.providers.write,
                                                        remove: this.state.providers.remove,
                                                        approve: this.state.providers.approve
                                                    },
                                                    logs: {
                                                        read:e.target.checked,
                                                        write: this.state.logs.write,
                                                        remove: this.state.logs.remove,
                                                        approve: this.state.logs.approve  
                                                    },
                                                })
                                            }
                                        />                                        

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                projects.write && banks.write && registers.write && 
                                                invoices.write && employes.write && letters.write && events.write && 
                                                userss.write && roless.write && csociales.write && sanction.write && 
                                                conge.write && partners.write && providers.write
                                            } 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    projects:{
                                                        write:e.target.checked,
                                                        read: this.state.projects.read,
                                                        remove: this.state.projects.remove,
                                                        approve: this.state.projects.approve
                                                    },
                                                    banks: {
                                                        write:e.target.checked,
                                                        read: this.state.banks.read,
                                                        remove: this.state.banks.remove,
                                                        approve: this.state.banks.approve
                                                    },
                                                    registers: {
                                                        write:e.target.checked,
                                                        read: this.state.registers.read,
                                                        remove: this.state.registers.remove,
                                                        approve: this.state.registers.approve
                                                    },
                                                    invoices: {
                                                        write:e.target.checked,
                                                        read: this.state.invoices.read,
                                                        remove: this.state.invoices.remove,
                                                        approve: this.state.invoices.approve
                                                    },
                                                    employes: {
                                                        write:e.target.checked,
                                                        read: this.state.employes.read,
                                                        remove: this.state.employes.remove,
                                                        approve: this.state.employes.approve
                                                    },
                                                    letters: {
                                                        write:e.target.checked,
                                                        read: this.state.letters.read,
                                                        remove: this.state.letters.remove,
                                                        approve: this.state.letters.approve
                                                    },
                                                    events: {
                                                        write:e.target.checked,
                                                        read: this.state.events.read,
                                                        remove: this.state.events.remove,
                                                        approve: this.state.events.approve
                                                    },
                                                    userss: {
                                                        write:e.target.checked,
                                                        read: this.state.userss.read,
                                                        remove: this.state.userss.remove,
                                                        approve: this.state.userss.approve
                                                    },
                                                    roless: {
                                                        write:e.target.checked,
                                                        read: this.state.roless.read,
                                                        remove: this.state.roless.remove,
                                                        approve: this.state.roless.approve
                                                    },
                                                    csociales: {
                                                        write:e.target.checked,
                                                        read: this.state.csociales.read,
                                                        remove: this.state.csociales.remove,
                                                        approve: this.state.csociales.approve
                                                    },
                                                    sanction: {
                                                        write:e.target.checked,
                                                        read: this.state.sanction.read,
                                                        remove: this.state.sanction.remove,
                                                        approve: this.state.sanction.approve  
                                                    },
                                                    conge: {
                                                        write:e.target.checked,
                                                        read: this.state.conge.write,
                                                        remove: this.state.conge.remove,
                                                        approve: this.state.conge.approve  
                                                    },
                                                    partners: {
                                                        write:e.target.checked,
                                                        read: this.state.partners.read,
                                                        remove: this.state.partners.remove,
                                                        approve: this.state.partners.approve  
                                                    },
                                                    providers: {
                                                        write:e.target.checked,
                                                        read: this.state.providers.read,
                                                        remove: this.state.providers.remove,
                                                        approve: this.state.providers.approve
                                                    }
                                                })
                                            }
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                projects.remove && banks.remove && registers.remove && 
                                                invoices.remove && employes.remove && letters.remove && events.remove && 
                                                userss.remove && roless.remove && csociales.remove && sanction.remove && 
                                                conge.remove && partners.remove && providers.remove
                                            } 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    projects:{
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    banks: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    registers: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    invoices: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    employes: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    letters: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    events: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    userss: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    roless: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    csociales: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    sanction: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve  
                                                    },
                                                    conge: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve 
                                                    },
                                                    partners: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve 
                                                    },
                                                    providers: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    }
                                                })
                                            }
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                projects.approve && banks.approve && registers.approve && 
                                                invoices.approve && employes.approve && letters.approve && events.approve && 
                                                userss.approve && roless.approve && csociales.approve && sanction.approve && 
                                                conge.approve && partners.approve && providers.approve
                                            } 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    projects:{
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked  
                                                    },
                                                    banks: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    registers: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    invoices: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    employes: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    letters: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    events: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    userss: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    roless: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    csociales: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    sanction: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    conge: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    partners: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    providers: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    }
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Grid>    

                                <Grid item xs={12} md={6}></Grid>                          

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Dashboard:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={dashbord.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ dashbord: {read:e.target.checked} })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Traces des activites des utilisateurs:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={logs.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ logs: {read: e.target.checked} })}
                                        />
                                    </FormGroup>
                                </Grid>  

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des Projets:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: e.target.checked,
                                                    write: this.state.projects.write,
                                                    remove: this.state.projects.remove,
                                                    approve: this.state.projects.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: this.state.projects.read,
                                                    write: e.target.checked,
                                                    remove: this.state.projects.remove,
                                                    approve: this.state.projects.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: this.state.projects.read,
                                                    write: this.state.projects.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.projects.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: this.state.projects.read,
                                                    write: this.state.projects.write,
                                                    remove: this.state.projects.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des banques:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: e.target.checked,
                                                    write: this.state.banks.write,
                                                    remove: this.state.banks.remove,
                                                    approve: this.state.banks.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: this.state.banks.read,
                                                    write: e.target.checked,
                                                    remove: this.state.banks.remove,
                                                    approve: this.state.banks.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: this.state.banks.read,
                                                    write: this.state.banks.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.banks.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: this.state.banks.read,
                                                    write: this.state.banks.write,
                                                    remove: this.state.banks.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>                                

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des caisses:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: e.target.checked,
                                                    write: this.state.registers.write,
                                                    remove: this.state.registers.remove,
                                                    approve: this.state.registers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: this.state.registers.read,
                                                    write: e.target.checked,
                                                    remove: this.state.registers.remove,
                                                    approve: this.state.registers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: this.state.registers.read,
                                                    write: this.state.registers.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.registers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: this.state.registers.read,
                                                    write: this.state.registers.write,
                                                    remove: this.state.registers.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Facturation:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: e.target.checked,
                                                    write: this.state.invoices.write,
                                                    remove: this.state.invoices.remove,
                                                    approve: this.state.invoices.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: this.state.invoices.read,
                                                    write: e.target.checked,
                                                    remove: this.state.invoices.remove,
                                                    approve: this.state.invoices.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: this.state.invoices.read,
                                                    write: this.state.invoices.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.invoices.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: this.state.invoices.read,
                                                    write: this.state.invoices.write,
                                                    remove: this.state.invoices.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion RH:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: e.target.checked,
                                                    write: this.state.employes.write,
                                                    remove: this.state.employes.remove,
                                                    approve: this.state.employes.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: this.state.employes.read,
                                                    write: e.target.checked,
                                                    remove: this.state.employes.remove,
                                                    approve: this.state.employes.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: this.state.employes.read,
                                                    write: this.state.employes.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.employes.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: this.state.employes.read,
                                                    write: this.state.employes.write,
                                                    remove: this.state.employes.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des courriers:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: e.target.checked,
                                                    write: this.state.letters.write,
                                                    remove: this.state.letters.remove,
                                                    approve: this.state.letters.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: this.state.letters.read,
                                                    write: e.target.checked,
                                                    remove: this.state.letters.remove,
                                                    approve: this.state.letters.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: this.state.letters.read,
                                                    write: this.state.letters.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.letters.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: this.state.letters.read,
                                                    write: this.state.letters.write,
                                                    remove: this.state.letters.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des événements:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: e.target.checked,
                                                    write: this.state.events.write,
                                                    remove: this.state.events.remove,
                                                    approve: this.state.events.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: this.state.events.read,
                                                    write: e.target.checked,
                                                    remove: this.state.events.remove,
                                                    approve: this.state.events.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: this.state.events.read,
                                                    write: this.state.events.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.events.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: this.state.events.read,
                                                    write: this.state.events.write,
                                                    remove: this.state.events.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des utilisateurs:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: e.target.checked,
                                                    write: this.state.userss.write,
                                                    remove: this.state.userss.remove,
                                                    approve: this.state.userss.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: this.state.userss.read,
                                                    write: e.target.checked,
                                                    remove: this.state.userss.remove,
                                                    approve: this.state.userss.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: this.state.userss.read,
                                                    write: this.state.userss.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.userss.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: this.state.userss.read,
                                                    write: this.state.userss.write,
                                                    remove: this.state.userss.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des rôles:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: e.target.checked,
                                                    write: this.state.roless.write,
                                                    remove: this.state.roless.remove,
                                                    approve: this.state.roless.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: this.state.roless.read,
                                                    write: e.target.checked,
                                                    remove: this.state.roless.remove,
                                                    approve: this.state.roless.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: this.state.roless.read,
                                                    write: this.state.roless.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.roless.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: this.state.roless.read,
                                                    write: this.state.roless.write,
                                                    remove: this.state.roless.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Cotisations sociales:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: e.target.checked,
                                                    write: this.state.csociales.write,
                                                    remove: this.state.csociales.remove,
                                                    approve: this.state.csociales.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: this.state.csociales.read,
                                                    write: e.target.checked,
                                                    remove: this.state.csociales.remove,
                                                    approve: this.state.csociales.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: this.state.csociales.read,
                                                    write: this.state.csociales.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.csociales.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: this.state.csociales.read,
                                                    write: this.state.csociales.write,
                                                    remove: this.state.csociales.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Sanctions disciplinaire:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: e.target.checked,
                                                    write: this.state.sanction.write,
                                                    remove: this.state.sanction.remove,
                                                    approve: this.state.sanction.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: this.state.sanction.read,
                                                    write: e.target.checked,
                                                    remove: this.state.sanction.remove,
                                                    approve: this.state.sanction.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: this.state.sanction.read,
                                                    write: this.state.sanction.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.sanction.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: this.state.sanction.read,
                                                    write: this.state.sanction.write,
                                                    remove: this.state.sanction.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Types de congés:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: e.target.checked,
                                                    write: this.state.conge.write,
                                                    remove: this.state.conge.remove,
                                                    approve: this.state.conge.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: this.state.conge.read,
                                                    write: e.target.checked,
                                                    remove: this.state.conge.remove,
                                                    approve: this.state.conge.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: this.state.conge.read,
                                                    write: this.state.conge.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.conge.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: this.state.conge.read,
                                                    write: this.state.conge.write,
                                                    remove: this.state.conge.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des partenaires techniques et financiers:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: e.target.checked,
                                                    write: this.state.partners.write,
                                                    remove: this.state.partners.remove,
                                                    approve: this.state.partners.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: this.state.partners.read,
                                                    write: e.target.checked,
                                                    remove: this.state.partners.remove,
                                                    approve: this.state.partners.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: this.state.partners.read,
                                                    write: this.state.partners.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.partners.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: this.state.partners.read,
                                                    write: this.state.partners.write,
                                                    remove: this.state.partners.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des fournisseurs:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: e.target.checked,
                                                    write: this.state.providers.write,
                                                    remove: this.state.providers.remove,
                                                    approve: this.state.providers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: this.state.providers.read,
                                                    write: e.target.checked,
                                                    remove: this.state.providers.remove,
                                                    approve: this.state.providers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: this.state.providers.read,
                                                    write: this.state.providers.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.providers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                            componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: this.state.providers.read,
                                                    write: this.state.providers.write,
                                                    remove: this.state.providers.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>                                     
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {name}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TabContext value={valuetab}  >
                                    <Box sx={{  borderColor: 'divider'}}>
                                        <TabList onChange={this.handleChangetab} variant="scrollable" scrollButtons="auto">
                                            <Tab label="Rôle et permissions" value="1" />       
                                            <Tab label="Comptes associés" value="2" />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            {accesses.map((item) => {
                                                return(
                                                    <Grid item xs={12} md={6}>
                                                        <p style={{color:"#313339", fontSize:16}} >{item.module}:</p>

                                                        <FormGroup aria-label="position" row>
                                                            <FormControlLabel
                                                                componentsProps={{typography: {fontSize: 12}}}
                                                                checked={item.read} 
                                                                control={<Checkbox />}
                                                                label="Lecture"
                                                                labelPlacement="end"
                                                            />
                                                            
                                                            <FormControlLabel
                                                                componentsProps={{typography: {fontSize: 12}}}
                                                                checked={item.write} 
                                                                control={<Checkbox />}
                                                                label="Écriture"
                                                                labelPlacement="end"
                                                            />

                                                            <FormControlLabel
                                                                componentsProps={{typography: {fontSize: 12}}}
                                                                checked={item.remove} 
                                                                control={<Checkbox />}
                                                                label="Archivage / Suppression"
                                                                labelPlacement="end"
                                                            />

                                                            <FormControlLabel
                                                                componentsProps={{typography: {fontSize: 12}}}
                                                                checked={item.approve} 
                                                                control={<Checkbox />}
                                                                label="Approuver"
                                                                labelPlacement="end"
                                                            />
                                                        </FormGroup>
                                                    </Grid>
                                                )})
                                            }   
                                        </Grid>                                    
                                    </TabPanel>

                                    <TabPanel value="2" sx={{p:2, minHeight:"60vh"}}>
                                        <table className="table table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nom</th>
                                                    <th scope="col">Email</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {users.map((item) => {
                                                    return(
                                                        <tr>
                                                            <td>{item.name}</td>
                                                            <td>{item.email}</td> 
                                                        </tr>
                                                    )
                                                })} 
                                            </tbody>
                                        </table>
                                    </TabPanel>
                                </TabContext>
                            </Grid>          
                        </Grid>
                    </DialogContent >
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier un rôle
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEdit} style={{marginTop:-40}}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du rôle"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Sélectionner tout:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                         componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                dashbord.read && projects.read && banks.read && registers.read && 
                                                invoices.read && employes.read && letters.read && events.read && 
                                                userss.read && roless.read && csociales.read && sanction.read && 
                                                conge.read && partners.read && providers.read && logs.read
                                            } 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    dashbord: {read:e.target.checked},
                                                    projects:{
                                                        read:e.target.checked,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: this.state.projects.approve
                                                    },
                                                    banks: {
                                                        read:e.target.checked,
                                                        write: this.state.banks.write,
                                                        remove: this.state.banks.remove,
                                                        approve: this.state.banks.approve
                                                    },
                                                    registers: {
                                                        read:e.target.checked,
                                                        write: this.state.registers.write,
                                                        remove: this.state.registers.remove,
                                                        approve: this.state.registers.approve
                                                    },
                                                    invoices: {
                                                        read:e.target.checked,
                                                        write: this.state.invoices.write,
                                                        remove: this.state.invoices.remove,
                                                        approve: this.state.invoices.approve
                                                    },
                                                    employes: {
                                                        read:e.target.checked,
                                                        write: this.state.employes.write,
                                                        remove: this.state.employes.remove,
                                                        approve: this.state.employes.approve
                                                    },
                                                    letters: {
                                                        read:e.target.checked,
                                                        write: this.state.letters.write,
                                                        remove: this.state.letters.remove,
                                                        approve: this.state.letters.approve
                                                    },
                                                    events: {
                                                        read:e.target.checked,
                                                        write: this.state.events.write,
                                                        remove: this.state.events.remove,
                                                        approve: this.state.events.approve
                                                    },
                                                    userss: {
                                                        read:e.target.checked,
                                                        write: this.state.userss.write,
                                                        remove: this.state.userss.remove,
                                                        approve: this.state.userss.approve
                                                    },
                                                    roless: {
                                                        read:e.target.checked,
                                                        write: this.state.roless.write,
                                                        remove: this.state.roless.remove,
                                                        approve: this.state.roless.approve
                                                    },
                                                    csociales: {
                                                        read:e.target.checked,
                                                        write: this.state.csociales.write,
                                                        remove: this.state.csociales.remove,
                                                        approve: this.state.csociales.approve
                                                    },
                                                    sanction: {
                                                        read:e.target.checked,
                                                        write: this.state.sanction.write,
                                                        remove: this.state.sanction.remove,
                                                        approve: this.state.sanction.approve  
                                                    },
                                                    conge: {
                                                        read:e.target.checked,
                                                        write: this.state.conge.write,
                                                        remove: this.state.conge.remove,
                                                        approve: this.state.conge.approve  
                                                    },
                                                    partners: {
                                                        read:e.target.checked,
                                                        write: this.state.partners.write,
                                                        remove: this.state.partners.remove,
                                                        approve: this.state.partners.approve  
                                                    },
                                                    providers: {
                                                        read:e.target.checked,
                                                        write: this.state.providers.write,
                                                        remove: this.state.providers.remove,
                                                        approve: this.state.providers.approve
                                                    },
                                                    logs: {
                                                        read:e.target.checked,
                                                        write: this.state.logs.write,
                                                        remove: this.state.logs.remove,
                                                        approve: this.state.logs.approve  
                                                    },
                                                })
                                            }
                                        />                                        

                                        <FormControlLabel
                                         componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                projects.write && banks.write && registers.write && 
                                                invoices.write && employes.write && letters.write && events.write && 
                                                userss.write && roless.write && csociales.write && sanction.write && 
                                                conge.write && partners.write && providers.write
                                            } 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    projects:{
                                                        write:e.target.checked,
                                                        read: this.state.projects.read,
                                                        remove: this.state.projects.remove,
                                                        approve: this.state.projects.approve
                                                    },
                                                    banks: {
                                                        write:e.target.checked,
                                                        read: this.state.banks.read,
                                                        remove: this.state.banks.remove,
                                                        approve: this.state.banks.approve
                                                    },
                                                    registers: {
                                                        write:e.target.checked,
                                                        read: this.state.registers.read,
                                                        remove: this.state.registers.remove,
                                                        approve: this.state.registers.approve
                                                    },
                                                    invoices: {
                                                        write:e.target.checked,
                                                        read: this.state.invoices.read,
                                                        remove: this.state.invoices.remove,
                                                        approve: this.state.invoices.approve
                                                    },
                                                    employes: {
                                                        write:e.target.checked,
                                                        read: this.state.employes.read,
                                                        remove: this.state.employes.remove,
                                                        approve: this.state.employes.approve
                                                    },
                                                    letters: {
                                                        write:e.target.checked,
                                                        read: this.state.letters.read,
                                                        remove: this.state.letters.remove,
                                                        approve: this.state.letters.approve
                                                    },
                                                    events: {
                                                        write:e.target.checked,
                                                        read: this.state.events.read,
                                                        remove: this.state.events.remove,
                                                        approve: this.state.events.approve
                                                    },
                                                    userss: {
                                                        write:e.target.checked,
                                                        read: this.state.userss.read,
                                                        remove: this.state.userss.remove,
                                                        approve: this.state.userss.approve
                                                    },
                                                    roless: {
                                                        write:e.target.checked,
                                                        read: this.state.roless.read,
                                                        remove: this.state.roless.remove,
                                                        approve: this.state.roless.approve
                                                    },
                                                    csociales: {
                                                        write:e.target.checked,
                                                        read: this.state.csociales.read,
                                                        remove: this.state.csociales.remove,
                                                        approve: this.state.csociales.approve
                                                    },
                                                    sanction: {
                                                        write:e.target.checked,
                                                        read: this.state.sanction.read,
                                                        remove: this.state.sanction.remove,
                                                        approve: this.state.sanction.approve  
                                                    },
                                                    conge: {
                                                        write:e.target.checked,
                                                        read: this.state.conge.write,
                                                        remove: this.state.conge.remove,
                                                        approve: this.state.conge.approve  
                                                    },
                                                    partners: {
                                                        write:e.target.checked,
                                                        read: this.state.partners.read,
                                                        remove: this.state.partners.remove,
                                                        approve: this.state.partners.approve  
                                                    },
                                                    providers: {
                                                        write:e.target.checked,
                                                        read: this.state.providers.read,
                                                        remove: this.state.providers.remove,
                                                        approve: this.state.providers.approve
                                                    }
                                                })
                                            }
                                        />

                                        <FormControlLabel
                                         componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                projects.remove && banks.remove && registers.remove && 
                                                invoices.remove && employes.remove && letters.remove && events.remove && 
                                                userss.remove && roless.remove && csociales.remove && sanction.remove && 
                                                conge.remove && partners.remove && providers.remove
                                            } 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    projects:{
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    banks: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    registers: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    invoices: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    employes: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    letters: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    events: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    userss: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    roless: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    csociales: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    },
                                                    sanction: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve  
                                                    },
                                                    conge: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve 
                                                    },
                                                    partners: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve 
                                                    },
                                                    providers: {
                                                        remove:e.target.checked,
                                                        write: this.state.projects.write,
                                                        read: this.state.projects.read,
                                                        approve: this.state.projects.approve
                                                    }
                                                })
                                            }
                                        />

                                        <FormControlLabel
                                         componentsProps={{typography: {fontSize: 12}}}
                                            checked={
                                                projects.approve && banks.approve && registers.approve && 
                                                invoices.approve && employes.approve && letters.approve && events.approve && 
                                                userss.approve && roless.approve && csociales.approve && sanction.approve && 
                                                conge.approve && partners.approve && providers.approve
                                            } 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => 
                                                this.setState({ 
                                                    projects:{
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked  
                                                    },
                                                    banks: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    registers: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    invoices: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    employes: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    letters: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    events: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    userss: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    roless: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    csociales: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    sanction: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    conge: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    partners: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    },
                                                    providers: {
                                                        read: this.state.projects.read,
                                                        write: this.state.projects.write,
                                                        remove: this.state.projects.remove,
                                                        approve: e.target.checked 
                                                    }
                                                })
                                            }
                                        />
                                    </FormGroup>
                                </Grid>    

                                <Grid item xs={12} md={6}></Grid>                          

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Dashboard:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={dashbord.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ dashbord: {read:e.target.checked} })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Traces des activites des utilisateurs:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={logs.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ logs: {read: e.target.checked} })}
                                        />
                                    </FormGroup>
                                </Grid>  

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des Projets:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: e.target.checked,
                                                    write: this.state.projects.write,
                                                    remove: this.state.projects.remove,
                                                    approve: this.state.projects.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: this.state.projects.read,
                                                    write: e.target.checked,
                                                    remove: this.state.projects.remove,
                                                    approve: this.state.projects.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: this.state.projects.read,
                                                    write: this.state.projects.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.projects.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={projects.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                projects: {
                                                    read: this.state.projects.read,
                                                    write: this.state.projects.write,
                                                    remove: this.state.projects.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des banques:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: e.target.checked,
                                                    write: this.state.banks.write,
                                                    remove: this.state.banks.remove,
                                                    approve: this.state.banks.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: this.state.banks.read,
                                                    write: e.target.checked,
                                                    remove: this.state.banks.remove,
                                                    approve: this.state.banks.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: this.state.banks.read,
                                                    write: this.state.banks.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.banks.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={banks.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                banks: {
                                                    read: this.state.banks.read,
                                                    write: this.state.banks.write,
                                                    remove: this.state.banks.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>                                

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des caisses:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: e.target.checked,
                                                    write: this.state.registers.write,
                                                    remove: this.state.registers.remove,
                                                    approve: this.state.registers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: this.state.registers.read,
                                                    write: e.target.checked,
                                                    remove: this.state.registers.remove,
                                                    approve: this.state.registers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: this.state.registers.read,
                                                    write: this.state.registers.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.registers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={registers.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                registers: {
                                                    read: this.state.registers.read,
                                                    write: this.state.registers.write,
                                                    remove: this.state.registers.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Facturation:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: e.target.checked,
                                                    write: this.state.invoices.write,
                                                    remove: this.state.invoices.remove,
                                                    approve: this.state.invoices.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: this.state.invoices.read,
                                                    write: e.target.checked,
                                                    remove: this.state.invoices.remove,
                                                    approve: this.state.invoices.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: this.state.invoices.read,
                                                    write: this.state.invoices.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.invoices.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={invoices.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                invoices: {
                                                    read: this.state.invoices.read,
                                                    write: this.state.invoices.write,
                                                    remove: this.state.invoices.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion RH:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: e.target.checked,
                                                    write: this.state.employes.write,
                                                    remove: this.state.employes.remove,
                                                    approve: this.state.employes.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: this.state.employes.read,
                                                    write: e.target.checked,
                                                    remove: this.state.employes.remove,
                                                    approve: this.state.employes.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: this.state.employes.read,
                                                    write: this.state.employes.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.employes.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={employes.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                employes: {
                                                    read: this.state.employes.read,
                                                    write: this.state.employes.write,
                                                    remove: this.state.employes.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des courriers:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: e.target.checked,
                                                    write: this.state.letters.write,
                                                    remove: this.state.letters.remove,
                                                    approve: this.state.letters.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: this.state.letters.read,
                                                    write: e.target.checked,
                                                    remove: this.state.letters.remove,
                                                    approve: this.state.letters.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: this.state.letters.read,
                                                    write: this.state.letters.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.letters.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={letters.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                letters: {
                                                    read: this.state.letters.read,
                                                    write: this.state.letters.write,
                                                    remove: this.state.letters.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des événements:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: e.target.checked,
                                                    write: this.state.events.write,
                                                    remove: this.state.events.remove,
                                                    approve: this.state.events.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: this.state.events.read,
                                                    write: e.target.checked,
                                                    remove: this.state.events.remove,
                                                    approve: this.state.events.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: this.state.events.read,
                                                    write: this.state.events.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.events.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={events.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                events: {
                                                    read: this.state.events.read,
                                                    write: this.state.events.write,
                                                    remove: this.state.events.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des utilisateurs:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: e.target.checked,
                                                    write: this.state.userss.write,
                                                    remove: this.state.userss.remove,
                                                    approve: this.state.userss.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: this.state.userss.read,
                                                    write: e.target.checked,
                                                    remove: this.state.userss.remove,
                                                    approve: this.state.userss.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: this.state.userss.read,
                                                    write: this.state.userss.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.userss.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={userss.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                userss: {
                                                    read: this.state.userss.read,
                                                    write: this.state.userss.write,
                                                    remove: this.state.userss.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des rôles:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: e.target.checked,
                                                    write: this.state.roless.write,
                                                    remove: this.state.roless.remove,
                                                    approve: this.state.roless.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: this.state.roless.read,
                                                    write: e.target.checked,
                                                    remove: this.state.roless.remove,
                                                    approve: this.state.roless.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: this.state.roless.read,
                                                    write: this.state.roless.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.roless.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={roless.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                roless: {
                                                    read: this.state.roless.read,
                                                    write: this.state.roless.write,
                                                    remove: this.state.roless.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Cotisations sociales:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: e.target.checked,
                                                    write: this.state.csociales.write,
                                                    remove: this.state.csociales.remove,
                                                    approve: this.state.csociales.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: this.state.csociales.read,
                                                    write: e.target.checked,
                                                    remove: this.state.csociales.remove,
                                                    approve: this.state.csociales.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: this.state.csociales.read,
                                                    write: this.state.csociales.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.csociales.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={csociales.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                csociales: {
                                                    read: this.state.csociales.read,
                                                    write: this.state.csociales.write,
                                                    remove: this.state.csociales.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Sanctions disciplinaire:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: e.target.checked,
                                                    write: this.state.sanction.write,
                                                    remove: this.state.sanction.remove,
                                                    approve: this.state.sanction.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: this.state.sanction.read,
                                                    write: e.target.checked,
                                                    remove: this.state.sanction.remove,
                                                    approve: this.state.sanction.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: this.state.sanction.read,
                                                    write: this.state.sanction.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.sanction.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={sanction.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                sanction: {
                                                    read: this.state.sanction.read,
                                                    write: this.state.sanction.write,
                                                    remove: this.state.sanction.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Types de congés:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: e.target.checked,
                                                    write: this.state.conge.write,
                                                    remove: this.state.conge.remove,
                                                    approve: this.state.conge.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: this.state.conge.read,
                                                    write: e.target.checked,
                                                    remove: this.state.conge.remove,
                                                    approve: this.state.conge.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: this.state.conge.read,
                                                    write: this.state.conge.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.conge.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={conge.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                conge: {
                                                    read: this.state.conge.read,
                                                    write: this.state.conge.write,
                                                    remove: this.state.conge.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des partenaires techniques et financiers:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: e.target.checked,
                                                    write: this.state.partners.write,
                                                    remove: this.state.partners.remove,
                                                    approve: this.state.partners.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: this.state.partners.read,
                                                    write: e.target.checked,
                                                    remove: this.state.partners.remove,
                                                    approve: this.state.partners.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: this.state.partners.read,
                                                    write: this.state.partners.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.partners.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={partners.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                partners: {
                                                    read: this.state.partners.read,
                                                    write: this.state.partners.write,
                                                    remove: this.state.partners.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <p style={{color:"#313339", fontSize:16}} >Gestion des fournisseurs:</p>

                                    <FormGroup aria-label="position" row>
                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.read} 
                                            control={<Checkbox />}
                                            label="Lecture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: e.target.checked,
                                                    write: this.state.providers.write,
                                                    remove: this.state.providers.remove,
                                                    approve: this.state.providers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.write} 
                                            control={<Checkbox />}
                                            label="Écriture"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: this.state.providers.read,
                                                    write: e.target.checked,
                                                    remove: this.state.providers.remove,
                                                    approve: this.state.providers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.remove} 
                                            control={<Checkbox />}
                                            label="Archivage / Suppression"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: this.state.providers.read,
                                                    write: this.state.providers.write,
                                                    remove: e.target.checked,
                                                    approve: this.state.providers.approve
                                                } 
                                            })}
                                        />

                                        <FormControlLabel
                                             componentsProps={{typography: {fontSize: 12}}}
                                            checked={providers.approve} 
                                            control={<Checkbox />}
                                            label="Approuver"
                                            labelPlacement="end"
                                            onChange={e => this.setState({ 
                                                providers: {
                                                    read: this.state.providers.read,
                                                    write: this.state.providers.write,
                                                    remove: this.state.providers.remove,
                                                    approve: e.target.checked
                                                } 
                                            })}
                                        />
                                    </FormGroup>
                                </Grid>                                     
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}