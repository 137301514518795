const formatDate = (strDate) => {
    const date = new Date(strDate)
    return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
}

const formatToInputDate = (strDate) => {
    const date = new Date(strDate)
    return `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}`
    // return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    // return date
}
const findPaymentMethode = (name) => {
    switch (name) {
        case "Check":
            return "Chèque";
        case "Cash":
            return "Espèces";
        case "ElectronicPayment":
            return "Paiement électronique";
        case "MobilePayment":
            return "Paiement mobile";
        case "TransferOrder":
            return "Ordre de virement";
        case "Other":
            return "Autre";
        default:
            return "Autre";
    }
}

export {
    formatDate,
    formatToInputDate,
    findPaymentMethode
}