import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import StatusSelecter from '../../Components/StatusSelecter'
import Search from "../../Components/Search"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default class Conge extends Component {

    constructor(props) {
        super(props)
        this.state = {
            vacations: [],

            add: false,
            edit: false,
            id_conge: null,
            name: "",
            days: 0,

            status: "Actif",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",

            anchorEl: []
        }
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Types de congés`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/vacation-types/all', config)
        .then(response => {            
            this.setState({ 
                vacations: response.data,
                loadingPage: false, loading: false
            }); 
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        });           
    }

    ChangeStatus(status){ this.setState({status: status}) }

    ChangeSearch(search){ this.setState({search: search}) }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event) {
        event.preventDefault()      
        this.setState({loading: true })    
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            name: this.state.name,
            days: parseFloat(this.state.days)
        }
        axios.post( 
            '/api/v1/vacation-types',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ message:"type de congé enregistré avec succès", add: false, isDid: true, });
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            name: this.state.name,
            days: parseFloat(this.state.days)
        }
        axios.put( 
            '/api/v1/vacation-types/' + this.state.id_conge,
            bodyParameters,
            config
        ).then(res => {
            this.setState({ message:"Type de congé modifié avec succès", isDid: true, edit:false, anchorEl: []});
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    edit(id){
        this.setState({loading: true })  
        const item = this.state.vacations.filter(c => c["id"] === id)[0]
        this.setState({
            id_conge: id,
            name: item.name,
            days: item.days,
            loading: false,
            edit: true
        })
    }

    delete(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/vacation-types/' + id,
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true, anchorEl: []});
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    render() {

        const {
            vacations,
            add,
            edit,
            name,
            days,
            status,
            search,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl
        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2
            },
            { 
                field: 'days', 
                headerName: 'Nombre de jour',
                flex: 1/2
            },            
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton 
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.delete(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>
                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.delete(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                            
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem style={{fontSize: 12, minHeight: 20}}
                                    disabled={!this.props.accesses.write}
                                    onClick={() => { this.edit(cellValues.row.id) }}
                                >
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            }
        ]

        let Datavacations=[]
        if(status == 'Tout'){
            Datavacations = vacations
        }
        if(status == 'Actif'){
            Datavacations = vacations.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            Datavacations = vacations.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            Datavacations = Datavacations.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({add: true})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouveau</Button>
                    </Grid> 

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={Datavacations}
                                columns={column}
                                loading={loadingPage}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un type de congé
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit} style={{marginTop:-20}}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du congé"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Nombre de jour"
                                        name="days"
                                        style={{ width: '100%' }}
                                        value={days}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 0.5
                                        }}
                                    />
                                </Grid> 

                                
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier un type de congé
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEdit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du congé"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Nombre de jour"
                                        name="days"
                                        style={{ width: '100%' }}
                                        value={days}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 0.5
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}