import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dropzone from 'react-dropzone'
import Avatar from '@mui/material/Avatar';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList'; 
import Search from "../../Components/Search"
import DescriptionIcon from '@mui/icons-material/Description';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { formatDate, formatToInputDate } from "../../tools/functions";
import { Link } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const employeeInitialState = {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    placeOfBirth: "",
    nationality: "",
    phone: "",
    email: "",
    matricule: "",
    inCaseOfEmergency: "",
    workplacename:"",
    department: "",
    zone: "",
    typeEmploye: "Salarié",
    typeAutre: "",
    baseSalary: 0,
    start: new Date(),
    end: new Date(),
    type: "CDD",
    photo: null,
    cv: null,
    contractDocument: null,

    socialContribution: [],
    
    sex: "Homme",
    children: 0,
    matrialSituation: "Célibataire",
    region: "",
    commune: "",
    quarter: "",
    // cvPath: "",
}

const contractChangeInitialState = {
    type: "CDD",
    baseSalary: 0,
    start: new Date(),
    end: new Date(),
    workplacename:"",
    department: "",
}
const permissionInitialState = {
    reason: "",
    start: new Date(),
    end: new Date(),
}
const sanctionInitialState = {
    disciplineTypeId: '',
    reason: ''
}
const vacationInitialState = {
    vacationTypeId: "",
    start: new Date(),
    end: new Date(),
}
const suplementaireInitialState = {
    hour: 0,
    rate: 0,
    start: new Date(),
}


export default class Employes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id_employee: null,
            id_permission: null,
            id_sanction: null,
            id_vacation: null,
            id_suplementaire: null,

            refuseReason: "",

            employees: [],
            csociales: [],
            disciplines: [],
            vacations: [],

            disciplinesArray: [],
            vacationsArray: [],
            additionalHours: [],

            employee: { ...employeeInitialState },
            contractChange: { ...contractChangeInitialState },
            permission: { ...permissionInitialState },
            sanction: {...sanctionInitialState},
            vacation: { ...vacationInitialState },
            suplementaire: { ...suplementaireInitialState },

            open: false,
            add: false,
            addSup: false,
            edit: false,
            editpermission: false,
            editSanction: false,
            editSanctionStatus: false,      
            editPermissionStatus: false,      
            editConge: false,
            editSup: false,
            remove: false,
            addpermission: false,
            addSanction: false,
            addConge: false,
            editCongeStatus: false,
            editSupStatus: false,
            
            disciplineTypeId: null,
            
            additionalHourId: null,
            startp: new Date(),
            endp: new Date(),
            lineSocialContributions: [],
            reason: "",
            SanctionStatus: "Processing",
            CongeStatus: "Processing",
            SupStatus: "Processing",
            workplace: {},
            contract: {},
            contractHistories:[],
            workplaceHistories: [],
            permissions: [],

            valuetab:"1",
            search: "",
            status: "Actif",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",

            anchorEl: []
        }
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleEmployeeChange = this.handleEmployeeChange.bind(this)
        this.handleContractChange = this.handleContractChange.bind(this)
        this.handlePermissionChange = this.handlePermissionChange.bind(this)
        this.handleSanctionChange = this.handleSanctionChange.bind(this)
        this.handleVacationChange = this.handleVacationChange.bind(this)
        this.handleHourChange = this.handleHourChange.bind(this)
                
        this.handleClick = this.handleClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
        this.handleSubmitRemove = this.handleSubmitRemove.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleSubmitPermission = this.handleSubmitPermission.bind(this)
        this.handleSubmitSanction = this.handleSubmitSanction.bind(this)
        this.handleSubmitSanctionStatus = this.handleSubmitSanctionStatus.bind(this)
        this.handleSubmitPermissionStatus = this.handleSubmitPermissionStatus.bind(this)
        this.handleSubmitConge = this.handleSubmitConge.bind(this)
        this.handleSubmitCongeStatus = this.handleSubmitCongeStatus.bind(this)
        this.handleSubmitSup = this.handleSubmitSup.bind(this)
        this.handleSubmitSupStatus = this.handleSubmitSupStatus.bind(this)
    }

    fetchEmployees(){
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/employees/all', config)
        .then(response => {
            this.setState({ 
                employees: response.data,
                loadingPage: false, loading: false
            }); 
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        });   
        
    }

    fetchSocialContributions(config){
        axios.get('/api/v1/social-contributions', config)   
        .then(response => {   
            this.setState({ 
                csociales: response.data
            });
        }).catch(_ => {});
    }

    fetchDisciplineTypes(config){
        axios.get('/api/v1/discipline-types', config)   
        .then(response => {   
            this.setState({ 
                disciplines: response.data
            });
        }).catch(_ => {});
    }

    fetchVacationTypes(config){
        axios.get('/api/v1/vacation-types', config)   
        .then(response => {   
            this.setState({ 
                vacations: response.data
            });
        }).catch(_ => {});
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Gestion des ressources humaines`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        this.fetchEmployees()
        this.fetchSocialContributions(config)
        this.fetchDisciplineTypes(config)
        this.fetchVacationTypes(config)
    }

    ChangeSearch(search){ this.setState({search: search}) }

    employeeFileDrop(acceptedFiles, name){ 
        const { employee } = this.state
        this.setState({ employee: { ...employee, [name]: acceptedFiles[0] }  })
    }


    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleEmployeeChange(e) {
        const { name, value } = e.target;
        const { employee } = this.state

        if(name === 'socialContribution'){
            const socialContribution = typeof value === 'string' ? value.split(',') : value 
            this.setState({ employee: { ...employee, [name]: socialContribution } }); 
            return
        }
        this.setState({ employee: { ...employee, [name]: value } });
    }

    handleContractChange(e) {
        const { name, value } = e.target;
        const { contractChange } = this.state
        this.setState({ contractChange: { ...contractChange, [name]: value } });
    }
    handlePermissionChange(e) {
        const { name, value } = e.target;
        const { permission } = this.state
        this.setState({ permission: { ...permission, [name]: value } });
    }

    handleSanctionChange(e) {
        const { name, value } = e.target;
        const { sanction } = this.state
        this.setState({ sanction: { ...sanction, [name]: value } });
    }

    handleVacationChange(e) {
        const { name, value } = e.target;
        const { vacation } = this.state
        this.setState({ vacation: { ...vacation, [name]: value } });
    }
    handleHourChange(e) {
        const { name, value } = e.target;
        const { suplementaire } = this.state
        this.setState({ suplementaire: { ...suplementaire, [name]: value } });
    }


    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }


    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }


    handleSubmit(event) {        
        event.preventDefault()      
        this.setState({loading: true})   
        const { employee, id_employee } = this.state

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        console.log("employee", employee)        

        const workplace = {
            "name": employee.workplacename,
            "department": employee.department,
            "zone": employee.zone
        }

        const contract = {
            "baseSalary": parseFloat(employee.baseSalary),
            "start": employee.start,
            "end": employee.end,
            "type": employee.type ? employee.type : employee.typeAutre
        }

        let socialContributions = []

        employee.socialContribution.forEach(element => {
            const b = {id: element}
            socialContributions = socialContributions.concat(b)
        });
        // employee.socialContribution.map((elem) => {
        //     return
        // })

        const bodyParameters = new FormData();
        bodyParameters.append("photo", employee.photo)
        bodyParameters.append("cv", employee.cv)
        bodyParameters.append("type", employee.typeEmploye)        
        bodyParameters.append("contractDocument", employee.contractDocument)
        bodyParameters.append("firstName", employee.firstName)        
        bodyParameters.append("lastName", employee.lastName)
        bodyParameters.append("dateOfBirth", employee.dateOfBirth)           
        bodyParameters.append("placeOfBirth", employee.placeOfBirth)
        bodyParameters.append("sex", employee.sex)
        bodyParameters.append("children", parseInt(employee.children))
        bodyParameters.append("matrialSituation", employee.matrialSituation)
        bodyParameters.append("region", employee.region)
        bodyParameters.append("commune", employee.commune)
        bodyParameters.append("quarter", employee.quarter)
        bodyParameters.append("nationality", employee.nationality)
        bodyParameters.append("phone", employee.phone)
        bodyParameters.append("email", employee.email)
        bodyParameters.append("matricule", employee.matricule)
        bodyParameters.append("inCaseOfEmergency", employee.inCaseOfEmergency)
        bodyParameters.append("workplace", JSON.stringify(workplace))  
        bodyParameters.append("contract", JSON.stringify(contract))
        bodyParameters.append("socialContribution", JSON.stringify(socialContributions))

        if(id_employee){
            axios.put(
                `/api/v1/employees/${id_employee}`, 
                bodyParameters,
                config
            )
            .then(_ => {  
                this.setState({
                    message:"Employé modifié avec succès",
                    isDid: true,
                    add: false,
                    loading: false
                });
                this.fetchEmployees()            
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });
        }else{
            axios.post(
                '/api/v1/employees', 
                bodyParameters,
                config
            )
            .then(_ => {  
                this.setState({
                    message:"Employé ajouté avec succès",
                    isDid: true,
                    add: false,
                    loading: false
                });
                this.fetchEmployees()            
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });
        }
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true })
        const { contractChange } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const workplace = {
            "name": contractChange.workplacename,
            "department": contractChange.department,
            "zone": contractChange.zone
        }

        const bodyParameters = {
            "baseSalary": parseFloat(contractChange.baseSalary),
            "start": contractChange.start,
            "end":contractChange.end,
            "type": contractChange.type,
            "workplace": workplace
        }
        
        
        axios.put( 
            '/api/v1/employees/' + this.state.id_employee + '/contract',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ 
                message:"Contrat changé avec succès", 
                isDid: true, 
                edit:false, 
                anchorEl: [],
                loading: false
            });
            this.fetchEmployees()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitRemove(event) {
        event.preventDefault()
        this.setState({loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const bodyParameters = {
            reason: this.state.reason
        }
        
        axios.put( 
            '/api/v1/employees/' + this.state.id_employee + '/break',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ 
                message:"Contrat rompu avec succès", 
                isDid: true, 
                remove:false, 
                anchorEl: [],
                loading: true
            });
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitPermission(event) {
        event.preventDefault()
        this.setState({loading: true })
        const { permission, id_employee, id_permission } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const bodyParameters = {
            reason: permission.reason,
            start: permission.start,
            end: permission.end
        }
        
        const endpoint = id_permission ? `/api/v1/employees/${id_employee}/permission/${id_permission}` : `/api/v1/employees/${id_employee}/permission`
        axios.put( 
            endpoint,
            bodyParameters,
            config
        ).then(_ => {
            this.setState({ 
                message: id_permission ? "Permission modifiée avec succès" : "Permission ajoutée avec succès", 
                isDid: true, 
                addpermission: false,
                anchorEl: [],
                loading: true
            });
            this.show(id_employee)
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitConge(event) {
        event.preventDefault()
        this.setState({loading: true })
        const { vacation, id_employee, id_vacation } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        
        const endpoint = id_vacation ? `/api/v1/employees/${id_employee}/vacation/${id_vacation}` : `/api/v1/employees/${id_employee}/vacation`
        axios.put( 
            endpoint,
            vacation,
            config
        ).then(res => {
            this.setState({ 
                message: id_vacation ? "Congé modifié avec succè" : "Congé ajouté avec succès", 
                isDid: true, 
                addConge: false,
                anchorEl: [],
                loading: true
            });
            this.show(this.state.id_employee)
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitSanction(event) {
        event.preventDefault()
        this.setState({loading: true })
        const { sanction, id_employee, id_sanction } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const endpoint = id_sanction ? `/api/v1/employees/${id_employee}/discipline/${id_sanction}` : `/api/v1/employees/${id_employee}/discipline`
        
        axios.put( 
            endpoint,
            sanction,
            config
        ).then(_ => {
            this.setState({ 
                message: id_sanction ? "Sanction modifiée avec succès" : "Sanction ajoutée avec succès", 
                isDid: true, 
                addSanction: false,
                anchorEl: [],
                loading: true
            });
            this.show(this.state.id_employee)
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitSup(event) {
        event.preventDefault()
        this.setState({loading: true })
        const { suplementaire, id_employee, id_suplementaire } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            rate: parseFloat(suplementaire.rate),
            hours: parseFloat(suplementaire.hour),
            date: suplementaire.start
        }  
        const endpoint = id_suplementaire ? `/api/v1/employees/${id_employee}/additional-hour/${id_suplementaire}` : `/api/v1/employees/${id_employee}/additional-hour`
        axios.put( 
            endpoint,
            bodyParameters,
            config
        ).then(_ => {
            this.setState({ 
                message: id_suplementaire ? "Heure suplementaire modifiée avec succès" : "Heure suplementaire ajoutée avec succès", 
                isDid: true, 
                addSup: false,
                anchorEl: [],
                loading: true
            });
            this.show(this.state.id_employee)
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitSanctionStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.SanctionStatus
        }
        axios.post(
            '/api/v1/employees/' + this.state.id_employee + '/discipline/' + this.state.id_sanction, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut changée avec succès",
                isDid: true, 
                editSanctionStatus:false, 
                anchorEl: [],
                loading: false
            });
            this.show(this.state.id_employee)          
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitPermissionStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.SanctionStatus,
            refuseReason: this.state.refuseReason
        }
        axios.post(
            '/api/v1/employees/' + this.state.id_employee + '/permission/' + this.state.id_permission, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut changée avec succès",
                isDid: true, 
                editPermissionStatus:false, 
                anchorEl: [],
                loading: false
            });
            this.show(this.state.id_employee)          
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitCongeStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.CongeStatus,
            refuseReason: this.state.refuseReason
        }
        axios.post(
            '/api/v1/employees/' + this.state.id_employee + '/vacation/' + this.state.id_vacation, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut changée avec succès",
                isDid: true, 
                editCongeStatus:false, 
                anchorEl: [],
                loading: false
            });
            this.show(this.state.id_employee)         
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitSupStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.SupStatus,
            refuseReason: this.state.refuseReason
        }
        axios.post(
            '/api/v1/employees/' + this.state.id_employee + '/additional-hour/' + this.state.additionalHourId, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut changée avec succès",
                isDid: true, 
                editSupStatus:false, 
                anchorEl: [],
                loading: false
            });
            this.show(this.state.id_employee)          
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    show(id){
        this.setState({loading: true })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/employees/' + id, config)
        .then(response => {
            const data = response.data
            const employee = {
                firstName: data.firstName,
                lastName: data.lastName,
                dateOfBirth: data.dateOfBirth,
                placeOfBirth: data.placeOfBirth,
                nationality: data.nationality,
                phone: data.phone,
                email: data.email,
                matricule: data.matricule,
                inCaseOfEmergency: data.inCaseOfEmergency,
                // workplacename: data.workplace ? data.workplace.name : '',
                // department:  data.workplace ? data.workplace.department : '',
                // zone: data.workplace ? data.workplace.department : '',
                typeEmploye: data.type,
                // baseSalary: data.contract ? data.contract.baseSalary : 0,
                // start: data.contract ? formatToInputDate(data.contract.start) : "",
                // end: data.contract ? formatToInputDate(data.contract.end ): "",
                // type: data.contract ? ['CDD', 'CDI', 'Bénévole', 'Volontaire', 'Prestataire', 'Stagiaire'].includes(data.contract.type) ? data.contract.type : "Autres" : "",
                // typeAutre: data.contract ? !['CDD', 'CDI', 'Bénévole', 'Volontaire', 'Prestataire', 'Stagiaire'].includes(data.contract.type) ? data.contract.type : "" : "",
                photo: data.photo ? data.photo : null,
                cv: data.cv ? data.cv : null,
                contractDocument: data.contractDocument ? data.contractDocument : null,
            
                // socialContribution: socialContributions,
                
                sex: data.sex,
                children: data.children,
                matrialSituation: data.matrialSituation,
                region: data.region,
                commune: data.commune,
                quarter: data.quarter,
            }

            this.setState({ 
                id_employee: id,
                employee: { ...this.state.employee, ...employee },
                // path: data.photo,
                // cvPath: data.cv,
                // contractPath: data.contractDocument,

                // firstName: data.firstName,
                // lastName: data.lastName,
                // dateOfBirth: data.dateOfBirth,
                // placeOfBirth: data.placeOfBirth,
                // typeEmploye: data.type,
                // phone: data.phone,
                // email: data.email,
                // matricule: data.matricule,
                // nationality: data.nationality,
                // sex:data.sex,
                // children: data.children,
                // matrialSituation: data.matrialSituation,
                // region: data.region,
                // commune: data.commune,
                // quarter: data.quarter,
                // dateOfPromotion: data.dateOfPromotion,
                // inCaseOfEmergency: data.inCaseOfEmergency,

                workplace: data.workplace,
                contract: data.contract,
                vacationsArray: data.vacations,
                contractHistories: data.contractHistories,
                workplaceHistories: data.workplaceHistories,
                permissions: data.permissions,
                disciplinesArray: data.disciplines,
                additionalHours: data.additionalHours,
                lineSocialContributions: data.lineSocialContributions,
                loading: false,
                open: true
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    edit(id){
        this.setState({
            id_employee: id,
            edit: true
        })
    }

    editpermission(id){
        this.setState({loading: true })  
        const item = this.state.permissions.filter(c => c.id === id)[0]
        this.setState({
            id_permission: id,
            permission: {
                reason: item.reason,
                start: formatToInputDate(item.start),
                end: formatToInputDate(item.end),
            },
            loading: false,
            addpermission: true
        })
    }

    editSanction(id){
        this.setState({loading: true })  
        const item = this.state.disciplinesArray.filter(c => c.id === id)[0]
        this.setState({
            id_sanction: id,
            sanction: {
                reason: item.reason,
                disciplineTypeId: item.disciplineTypeId,
            },
            loading: false,
            addSanction: true
        })
    }

    editSanctionStatus(id){
        this.setState({loading: true })  
        const item = this.state.disciplinesArray.filter(c => c["id"] === id)[0]
        this.setState({
            SanctionStatus: item.status,
            refuseReason: "",
            id_sanction: id,
            loading: false,
            editSanctionStatus: true
        })
    }

    editPermissionStatus(id){
        this.setState({loading: true })  
        const item = this.state.permissions.filter(c => c["id"] === id)[0]
        this.setState({
            SanctionStatus: item.status,
            refuseReason: item.refuseReason ? item.refuseReason : "",
            id_permission: id,
            loading: false,
            editPermissionStatus: true
        })
    }

    editCongeStatus(id){
        this.setState({loading: true })  
        const item = this.state.vacationsArray.filter(c => c["id"] === id)[0]
        this.setState({
            CongeStatus: item.status,
            refuseReason: item.refuseReason ? item.refuseReason : "",
            id_vacation: id,
            loading: false,
            editCongeStatus: true
        })
    }

    editSupStatus(id){
        this.setState({loading: true })  
        const item = this.state.additionalHours.filter(c => c["id"] === id)[0]
        this.setState({
            SupStatus: item.status,
            refuseReason: item.refuseReason ? item.refuseReason : "",
            additionalHourId: id,
            loading: false,
            editSupStatus: true
        })
    }

    editConge(id){
        this.setState({loading: true })  
        const item = this.state.vacationsArray.filter(c => c.id === id)[0]
        this.setState({
            id_vacation: id,
            vacation: {
                vacationTypeId: item.vacationTypeId,
                start: formatToInputDate(item.start),
                end: formatToInputDate(item.end),
            },
            loading: false,
            addConge: true
        })
    }

    editSup(id){
        this.setState({loading: true })  
        const item = this.state.additionalHours.filter(c => c.id === id)[0]

        this.setState({
            id_suplementaire: item.id,
            suplementaire: {
                hour: item.hours,
                start: formatToInputDate(item.date),
                rate: item.rate,
            },
            loading: false,
            addSup: true
        })
    }

    removepermission(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/employees/' + this.state.id_employee + '/permission/' + id,
            config
        ).then(response => {     
            this.setState({message:"Permission supprimée avec succès", isDid: true, anchorEl: []});
            this.show(this.state.id_employee)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    removeSanction(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/employees/' + this.state.id_employee + '/discipline/' + id,
            config
        ).then(response => {     
            this.setState({message:"Sanction supprimée avec succès", isDid: true, anchorEl: []});
            this.show(this.state.id_employee)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    removeConge(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/employees/' + this.state.id_employee + '/vacation/' + id,
            config
        ).then(response => {     
            this.setState({message:"Congé supprimée avec succès", isDid: true, anchorEl: []});
            this.show(this.state.id_employee)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    rompre(id){
        this.setState({
            id_employee: id,
            remove: true
        })
    }

    removeSup(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/employees/' + this.state.id_employee + '/additional-hour/' + id,
            config
        ).then(response => {     
            this.setState({message:"Heure suplementaire supprimée avec succès", isDid: true, anchorEl: []});
            this.show(this.state.id_employee)
            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    download(){
        this.setState({loading: true })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token},
            responseType: 'blob'
        };
        axios.get('/api/v1/employees/' + this.state.id_employee + '/print', config)
        .then(response => {  
            this.setState({ 
                message:"Imprimer avec succès", 
                isDid: true,
                loading: false
            });
            window.open(URL.createObjectURL(response.data));            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    editRH(id){
        this.setState({loading: true })  
        // const item = this.state.employees.find(c => c.id === id)

        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/employees/' + id, config)
        .then(response => {            
            console.log(response)
            const data = response.data

            const socialContributions = []

            data.lineSocialContributions.forEach(item => {
                const line = item.socialContribution
                socialContributions.push(line.id)
            });

            const employee = {
                firstName: data.firstName,
                lastName: data.lastName,
                dateOfBirth: formatToInputDate(data.dateOfBirth),
                placeOfBirth: data.placeOfBirth,
                nationality: data.nationality,
                phone: data.phone,
                email: data.email,
                matricule: data.matricule,
                inCaseOfEmergency: data.inCaseOfEmergency,
                workplacename: data.workplace ? data.workplace.name : '',
                department:  data.workplace ? data.workplace.department : '',
                zone: data.workplace ? data.workplace.department : '',
                typeEmploye: data.type,
                baseSalary: data.contract ? data.contract.baseSalary : 0,
                start: data.contract ? formatToInputDate(data.contract.start) : "",
                end: data.contract ? formatToInputDate(data.contract.end ): "",
                type: data.contract ? ['CDD', 'CDI', 'Bénévole', 'Volontaire', 'Prestataire', 'Stagiaire'].includes(data.contract.type) ? data.contract.type : "Autres" : "",
                typeAutre: data.contract ? !['CDD', 'CDI', 'Bénévole', 'Volontaire', 'Prestataire', 'Stagiaire'].includes(data.contract.type) ? data.contract.type : "" : "",
                photo: data.photo ? data.photo : null,
                cv: data.cv ? data.cv : null,
                contractDocument: data.contractDocument ? data.contractDocument : null,
            
                socialContribution: socialContributions,
                
                sex: data.sex,
                children: data.children,
                matrialSituation: data.matrialSituation,
                region: data.region,
                commune: data.commune,
                quarter: data.quarter,
            }
            this.setState({
                id_employee: id,
                employee: { ...employee }, 
                add: true, 
                loading: false
            })


        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
        
    }

    render() {

        const {
            id_employee,
            id_permission,
            id_sanction,
            id_vacation,
            id_suplementaire,

            refuseReason,

            employees,
            employee,
            contractChange,
            permission,
            sanction,
            vacation,
            suplementaire,

            csociales,
            disciplines,
            disciplinesArray,
            vacations,
            vacationsArray,

            additionalHours,
            SanctionStatus,
            editSanctionStatus,
            editPermissionStatus,
            editCongeStatus,
            CongeStatus,
            addSup,
            open,
            search,
            add,
            edit,
            editSupStatus,
            SupStatus,
            remove,
            addpermission,
            addSanction,
            addConge,


            lineSocialContributions,
            type,

            workplace,
            contract,
            contractHistories,
            workplaceHistories,
            permissions,
            reason,
            

            valuetab,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl

        } = this.state

        const column = [
            { 
                field: 'image', 
                headerName: 'Image',
                width: 100,
                headerAlign: 'center', 
                align:'center',
                renderCell: (cellValues) => {
                    if(cellValues.row.photo !== ""){
                        return(
                            <img 
                                style={{width:50}}
                                src={cellValues.row.photo}
                            />
                        )    
                    }else{
                        return(
                            <img 
                                style={{width:50}}
                                src={"https://i.pngimg.me/thumb/f/720/m2i8m2A0K9H7N4m2.jpg"}
                            />
                        )  
                    }
                    
                }
            },
            { 
                field: 'matricule', 
                headerName: 'Matricule',
                flex: 1/4
            },
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2,
                renderCell: (cellValues) => {
                    return `${cellValues.row.firstName } ${cellValues.row.lastName}`                   
                }
            },
            { 
                field: 'email', 
                headerName: 'Adresse email',
                flex: 1/2
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem  disabled={!this.props.accesses.write} onClick={() => { this.editRH(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>

                                <MenuItem  disabled={!this.props.accesses.write} onClick={() => { this.edit(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Changer le contrat
                                </MenuItem>

                                <MenuItem disabled={!this.props.accesses.remove} onClick={() => {if(window.confirm('Êtes-vous sûr de le rompre ?')){this.rompre(cellValues.row.id)}}} style={{color:"red", fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><DoNotDisturbIcon fontSize="small" style={{color:"red"}} /></ListItemIcon>Rompre le contrat
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            },
            

        ]

        let Dataemployees = employees    
        if(search !== ''){
            Dataemployees = Dataemployees.filter(elem => { return `${elem.firstName} ${elem.lastName}`.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        const displaythem = (selected) => {
            let resultat = ""
            selected.map((elem) => {
                if(csociales.filter(c => c["id"] === elem)[0]){
                    resultat = resultat + csociales.filter(c => c["id"] === elem)[0].name + ", "    
                }                
            })

            return resultat
        }

        var dateMax = new Date();
        dateMax.setFullYear(dateMax.getFullYear() - 16);


        const displaySalaireNet = (salairenet) => {
            let result = salairenet
            let taux = 0
            lineSocialContributions.map((item) => {
                taux = taux + ((salairenet * item.socialContribution.employeePart) / 100)
            })
            result = result - taux

            return result
        }

        const GetDIffence = (first, second) => {
            let result = new Date(second).getTime() - new Date(first).getTime();
            result = Math.round(result / (1000 * 3600 * 24));
            return result
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={8} >
                        <Button variant="contained" disabled={!this.props.accesses.write} 
                            onClick={() => this.setState({id_employee: null, employee: { ...employeeInitialState }, add: true} )} 
                            sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouveau</Button>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <Search  onChange={this.ChangeSearch} />   
                        </Box>                                  
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={Dataemployees}
                                columns={column}
                                loading={loadingPage}
                                disableColumnMenu
                                sortModel={[{
                                    field: 'name',
                                    sort: 'asc',
                                    
                                }]}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_employee === null ? "Ajouter un employé" : "Modifier un employé"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <Box 
                                        display="flex" 
                                        justifyContent="center" 
                                        alignItems="center" 
                                    >
                                        <Button sx={{'&:hover': {backgroundColor: 'white'} }} >
                                            <Dropzone onDrop={acceptedFiles  => this.employeeFileDrop(acceptedFiles, 'photo')} accept={"image/*"}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} multiple={false} accept="image/*" /> 

                                                            {employee.photo === null &&
                                                                <Avatar 
                                                                    sx={{
                                                                        boxShadow: 12, 
                                                                        // color: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
                                                                        // backgroundColor: "primary.main",
                                                                        width: 80, height: 80
                                                                    }} 
                                                                    elevation={10}
                                                                >
                                                                    <AccountCircleIcon sx={{ fontSize: 80, color: 'black' }}/>
                                                                </Avatar>
                                                            }
                                                                
                                                            {employee.photo !== null && typeof employee.photo === 'object' &&
                                                                <>
                                                                    <img 
                                                                        style={{height:180, width: 120}}
                                                                        src={URL.createObjectURL(employee.photo) }
                                                                    /><br />
                                                                    <span style={{textTransform: 'none'}}>{employee.photo?.name}</span>
                                                                </>
                                                            }
                                                            {typeof employee.photo === 'string' &&
                                                                <>
                                                                    <img 
                                                                        style={{height:180, width: 120}}
                                                                        src={employee.photo}
                                                                    /><br />
                                                                    <span style={{textTransform: 'none'}}>{employee.photo}</span>
                                                                </>
                                                            }
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>
                                        </Button>
                                        {employee.photo &&
                                            <Button onClick={()=>this.setState({ employee: { ...employee, photo: null }})}>
                                                <DeleteIcon />
                                            </Button>
                                        }
                                    </Box>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Prenom"
                                        name="firstName"
                                        style={{ width: '100%' }}
                                        value={employee.firstName}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Nom"
                                        name="lastName"
                                        style={{ width: '100%' }}
                                        value={employee.lastName}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        select
                                        label="Type d'employé"
                                        name="typeEmploye"
                                        style={{ width: '100%' }}
                                        value={employee.typeEmploye}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    >
                                        <MenuItem key="Salarié" value="Salarié">Salarié</MenuItem>
                                        <MenuItem key="Fonctionnaire" value="Fonctionnaire">Fonctionnaire</MenuItem>
                                        <MenuItem key="Autre" value="Autre">Autre</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        required
                                        label="La date de naissance"
                                        type="date"
                                        name="dateOfBirth"
                                        style={{ width: '100%' }}
                                        value={employee.dateOfBirth}
                                        onChange={this.handleEmployeeChange} 
                                        size="small"
                                    />
                                </Grid> 

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Lieu de naissance"
                                        name="placeOfBirth"
                                        style={{ width: '100%' }}
                                        value={employee.placeOfBirth}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        select
                                        label="Sexe"
                                        name="sex"
                                        style={{ width: '100%' }}
                                        value={employee.sex}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    >
                                        <MenuItem key="Homme" value="Homme">Homme</MenuItem>
                                        <MenuItem key="Femme" value="Femme">Femme</MenuItem>
                                    </TextField>
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Nombre d'enfant"
                                        name="children"
                                        style={{ width: '100%' }}
                                        value={employee.children}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                        inputProps={{
                                            max: 20,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>  

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        select
                                        label="Situation matrimoniale"
                                        name="matrialSituation"
                                        style={{ width: '100%' }}
                                        value={employee.matrialSituation}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    >
                                        <MenuItem key="Célibataire" value="Célibataire">Célibataire</MenuItem>
                                        <MenuItem key="Marié(e)" value="Marié(e)">Marié(e)</MenuItem>
                                        <MenuItem key="Veuf/Veuve" value="Veuf/Veuve">Veuf/Veuve</MenuItem>
                                        <MenuItem key="Divorcé(e)" value="Divorcé(e)">Divorcé(e)</MenuItem>
                                        <MenuItem key="Autre" value="Autre">Autre</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Région"
                                        name="region"
                                        style={{ width: '100%' }}
                                        value={employee.region}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Commune"
                                        name="commune"
                                        style={{ width: '100%' }}
                                        value={employee.commune}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Quartier"
                                        name="quarter"
                                        style={{ width: '100%' }}
                                        value={employee.quarter}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Nationalité"
                                        name="nationality"
                                        style={{ width: '100%' }}
                                        value={employee.nationality}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Phone"
                                        name="phone"
                                        style={{ width: '100%' }}
                                        value={employee.phone}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Email"
                                        name="email"
                                        style={{ width: '100%' }}
                                        value={employee.email}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label="Matricule"
                                        name="matricule"
                                        style={{ width: '100%' }}
                                        value={employee.matricule}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Poste"
                                        name="workplacename"
                                        style={{ width: '100%' }}
                                        value={employee.workplacename}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>  

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Département"
                                        name="department"
                                        style={{ width: '100%' }}
                                        value={employee.department}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Zone d'affectation"
                                        name="zone"
                                        style={{ width: '100%' }}
                                        value={employee.zone}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Salaire de base"
                                        name="baseSalary"
                                        style={{ width: '100%' }}
                                        value={employee.baseSalary}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 0.5,
                                            min: 0
                                        }}
                                    />
                                </Grid>  

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        select
                                        label="Type de contrat"
                                        name="type"
                                        style={{ width: '100%' }}
                                        value={employee.type}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                    >
                                        <MenuItem key="CDD" value="CDD">CDD</MenuItem>
                                        <MenuItem key="CDI" value="CDI">CDI</MenuItem>
                                        <MenuItem key="Bénévole" value="Bénévole">Bénévole</MenuItem>
                                        <MenuItem key="Volontaire" value="Volontaire">Volontaire</MenuItem>
                                        <MenuItem key="Prestataire" value="Prestataire">Prestataire</MenuItem>
                                        <MenuItem key="Stagiaire" value="Stagiaire">Stagiaire</MenuItem>
                                        <MenuItem key="Autres" value="Autres">Autres</MenuItem>
                                    </TextField>
                                </Grid>

                                {employee.type === "Autres" && 
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            label="Autre type de contrat"
                                            name="typeAutre"
                                            style={{ width: '100%' }}
                                            value={employee.typeAutre}
                                            onChange={this.handleEmployeeChange}
                                            size="small"
                                        />
                                    </Grid>
                                }

                                <Grid item xs={6}>                                    
                                    <TextField
                                        type="date"
                                        name="start"
                                        label="Debut du contrat"
                                        style={{ width: '100%' }}
                                        value={employee.start}
                                        onChange={this.handleEmployeeChange} 
                                        size="small"
                                    />
                                </Grid>  

                                <Grid item xs={6}>                                    
                                    <TextField
                                        disabled={employee.type === "CDI"}
                                        type="date"
                                        name="end"
                                        label="Fin du contrat"
                                        style={{ width: '100%' }}
                                        value={employee.end}
                                        onChange={this.handleEmployeeChange} 
                                        size="small"
                                    />
                                </Grid>    

                                <Grid item xs={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel style={{top:'-8px'}}>Cotisation sociale / Impôt</InputLabel>
                                        <Select
                                            multiple
                                            label="Cotisation sociale / Impôt"
                                            name="socialContribution"
                                            style={{ width: '100%' }}
                                            value={employee.socialContribution}
                                            renderValue={(selected) => displaythem(selected)}
                                            onChange={this.handleEmployeeChange}
                                            size="small"
                                        >
                                            {csociales.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    <Checkbox checked={employee.socialContribution.indexOf(answer.id) > -1} sx={{color:'#2f4cdd', '&.Mui-checked': {color:'#2f4cdd'}}} />
                                                    <ListItemText primary={answer.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        label="En cas d'urgence"
                                        name="inCaseOfEmergency"
                                        style={{ width: '100%' }}
                                        value={employee.inCaseOfEmergency}
                                        onChange={this.handleEmployeeChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={12} style={{display: "flex", alignItems: 'start', justifyContent: 'center'}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.employeeFileDrop(acceptedFiles, 'cv')}>
                                        {({getRootProps, getInputProps}) => (
                                            <section style={{ textAlign: 'center'}}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} multiple={false} accept="image/*, application/pdf" />

                                                    {employee.cv === null &&
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez le CV ici, ou cliquez pour sélectionner le fichier</p>
                                                        </React.Fragment>
                                                    }
                                                    {employee.cv !== null &&
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  /> <br />
                                                            <span style={{textTransform: 'none'}}>{employee.cv?.name || employee.cv}</span>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {employee.cv &&
                                            <Button onClick={()=>this.setState({ employee: { ...employee, cv: null }})}>
                                                <DeleteIcon />
                                            </Button>
                                        }
                                </Grid>

                                <Grid item xs={12} md={12} style={{display: "flex", alignItems: 'start', justifyContent: 'center'}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.employeeFileDrop(acceptedFiles, 'contractDocument')}>
                                        {({getRootProps, getInputProps}) => (
                                            <section style={{ textAlign: 'center'}}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} multiple={false} accept="application/pdf" />

                                                    {employee.contractDocument === null &&
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez le Contrat ici, ou cliquez pour sélectionner le fichier</p>
                                                        </React.Fragment>
                                                    }
                                                    {employee.contractDocument !== null &&
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  /> <br />
                                                            <span style={{textTransform: 'none'}}>{employee.contractDocument?.name || employee.contractDocument}</span>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                    {employee.contractDocument &&
                                        <Button onClick={()=>this.setState({ employee: { ...employee, contractDocument: null }})}>
                                            <DeleteIcon />
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                
                                {id_employee === null ? "Cliquer pour ajouter" : "Cliquer pour modifier"}
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullScreen                    
                    TransitionComponent={Transition}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <AppBar sx={{ position: 'relative', background: '#fff' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1, color:"#313339" }} variant="h6" component="div">
                                Dossier de l'empoyé
                            </Typography>

                            <Button 
                                variant="contained" 
                                onClick={() => this.download()} 
                                sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                            >
                                Imprimer la fiche métier
                            </Button>

                            <IconButton onClick={() =>  this.setState({open: false })} >   
                                <CloseIcon /> 
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TabContext value={valuetab}  >
                                    <Box sx={{  borderColor: 'divider'}}>
                                        <TabList onChange={this.handleChangetab} variant="scrollable" scrollButtons="auto">
                                            <Tab label="Profil" value="1" style={{fontSize: 12, fontWeight: 'bold'}} />       
                                            <Tab label="Historique de contrat" value="2" style={{fontSize: 12, fontWeight: 'bold'}} />
                                            <Tab label="Cotisations sociales" value="4" style={{fontSize: 12, fontWeight: 'bold'}} /> 
                                            <Tab label="Historique du poste" value="3" style={{fontSize: 12, fontWeight: 'bold'}} />                                            
                                            <Tab label="Heure supplementaire" value="5" style={{fontSize: 12, fontWeight: 'bold'}} />                                      
                                            <Tab label="Historique de congé" value="6" style={{fontSize: 12, fontWeight: 'bold'}} />                                            
                                            <Tab label="Historique de permissions" value="7" style={{fontSize: 12, fontWeight: 'bold'}} />
                                            <Tab label="Sanction disciplinaire" value="8" style={{fontSize: 12, fontWeight: 'bold'}} />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} md={2}>
                                                    <img 
                                                        style={{width:100}}
                                                        src={employee.photo || "https://i.pngimg.me/thumb/f/720/m2i8m2A0K9H7N4m2.jpg"}
                                                    />

                                                {employee.cv  &&
                                                    <React.Fragment>
                                                        <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12, marginTop:8}}><strong>Curriculum vitæ</strong></Typography>
                                                        <Link color="inherit" href={employee.cv} target={"_blank"} style={{ textDecoration: 'none', }}>
                                                            <Button
                                                                variant="contained" elevation={0}
                                                                sx={{ background: '#306bff', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: '#306bff', color: 'white' } }}
                                                            >
                                                                Voir
                                                            </Button>
                                                        </Link>
                                                    </React.Fragment>                                                
                                                }

                                                {employee.contractDocument &&
                                                    <React.Fragment>
                                                        <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12, marginTop:8}}><strong>Fichier du contrat</strong></Typography>
                                                        <Link color="inherit" href={employee.contractDocument} target={"_blank"} style={{ textDecoration: 'none', }}>
                                                            <Button
                                                                variant="contained"  elevation={0}
                                                                sx={{ background: '#306bff', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: '#306bff', color: 'white' } }}
                                                            >
                                                                Voir
                                                            </Button>
                                                        </Link>
                                                    </React.Fragment>                                                
                                                }
                                            </Grid>

                                            <Grid item xs={12} md={10}>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Matricule</strong>: {employee.matricule}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Prenom</strong>: {employee.firstName}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Nom</strong>: {employee.lastName}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Date de naissance</strong>: {formatDate(employee.dateOfBirth)}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Lieu de naissance</strong>: {employee.placeOfBirth}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Nationalité</strong>: {employee.nationality}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Phone</strong>: {employee.phone}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Email</strong>: {employee.email}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Type</strong>: {employee.typeEmploye}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Genre</strong>: {employee.sex}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Nombre d'enfant</strong>: {employee.children}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Situation matrimoniale</strong>: {employee.matrialSituation}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Région</strong>: {employee.region}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Commune</strong>: {employee.commune}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Quartier</strong>: {employee.quarter}</Typography>
                                                {/* <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Date de la promotion</strong>: {formatDate(employee.dateOfPromotion)}</Typography> */}
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>en cas d'urgence</strong>: {employee.inCaseOfEmergency}</Typography>
                                            </Grid>
                                        </Grid>                                    
                                    </TabPanel>

                                    <TabPanel value="2" sx={{p:2, minHeight:"60vh"}}>
                                        <table className="table table-hover" style={{fontSize: 12 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Type de contrat</th>
                                                    <th scope="col">Salaire brut</th>
                                                    <th scope="col">Salaire net</th>
                                                    <th scope="col">Debut du contrat</th>                                        
                                                    <th scope="col">Fin du contrat</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>{contract.type}</td>
                                                    <td>{parseFloat(contract.baseSalary).toLocaleString()}</td>
                                                    <td>{displaySalaireNet(parseFloat(contract.baseSalary)).toLocaleString()}</td>
                                                    <td>{formatDate(contract.start)}</td>
                                                    <td>{contract.end ? formatDate(contract.end) : "Indéfini(CDI)"}</td>
                                                </tr>
                                        
                                                {contractHistories.length > 0 &&
                                                    contractHistories.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.type}</td>
                                                                <td>{item.baseSalary}</td>
                                                                <td>{displaySalaireNet(parseFloat(item.baseSalary)).toLocaleString()}</td>
                                                                <td>{new Date(item.start).toLocaleDateString()}</td> 
                                                                {/* {item.type === "CDD" ? <td>{new Date(item.end).toLocaleDateString()}</td> : <td>Indéfini(CDI)</td>} */}
                                                                <td>{item.end ? formatDate(item.end) : "Indéfini(CDI)"}</td>
                                                            </tr>        
                                                        )
                                                    })
                                                }                             
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="3" sx={{p:2, minHeight:"60vh"}}>
                                        <table className="table table-hover" style={{fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Poste</th>
                                                    <th scope="col">Département</th>
                                                    <th scope="col">Zone d'affectation</th>
                                                    <th scope="col">Debut</th>                                        
                                                    <th scope="col">Fin</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                <tr>
                                                    <td>{workplace.name}</td>
                                                    <td>{workplace.department}</td>
                                                    <td>{workplace.zone}</td>
                                                    <td>{new Date(workplace.start).toLocaleDateString()}</td> 
                                                    <td>En cours</td>
                                                </tr> 

                                                {workplaceHistories.length > 0 &&
                                                    workplaceHistories.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>{item.department}</td>
                                                                <td>{item.zone}</td>
                                                                <td>{new Date(item.start).toLocaleDateString()}</td> 
                                                                <td>{new Date(item.end).toLocaleDateString()}</td>
                                                            </tr>        
                                                        )
                                                    })
                                                }                             
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="4" sx={{p:2, minHeight:"60vh"}}>
                                        <table className="table table-hover" style={{fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nom</th>
                                                    <th scope="col">part de employé</th>
                                                    <th scope="col">Part de employeur</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {lineSocialContributions.length > 0 &&
                                                    lineSocialContributions.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.socialContribution.name}</td>
                                                                <td>{item.socialContribution.employeePart}</td>
                                                                <td>{item.socialContribution.employerPart}</td>
                                                            </tr>        
                                                        )
                                                    })
                                                }                             
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="5" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({ hour: { ...suplementaireInitialState }, id_suplementaire: null, addSup: true})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Ajouter une heure supplementaire</Button>
                                            </Grid> 
                                        </Grid> 
                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Date</th>
                                                    <th scope="col">Prix horaire</th>
                                                    <th scope="col">Heure</th>
                                                    <th scope="col">Montant</th>
                                                    <th scope="col">Statut</th> 
                                                    <th scope="col">Motif refus</th> 
                                                    <th scope="col">Modification</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {additionalHours.length > 0 &&
                                                    additionalHours.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{new Date(item.date).toLocaleDateString()}</td>
                                                                <td>{item.rate.toLocaleString()}</td>
                                                                <td>{item.hours.toLocaleString()}</td>
                                                                <td>{(item.hours * item.rate).toLocaleString()}</td>
                                                                <td 
                                                                    onClick={!this.props.accesses.approve ? null : () => { this.editSupStatus(item.id) }}
                                                                    style={{cursor: 'pointer'}}
                                                                >
                                                                    {item.status === "Processing" && "Traitement"}
                                                                    {item.status === "Approved" && "Approuvé"}
                                                                    {item.status === "Disaproved" && "Refusé"}
                                                                </td>
                                                                <td>{item.refuseReason}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editSup(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeSup(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>        
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="6" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({vacation: { ...vacationInitialState }, id_vacation: null, addConge: true})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Ajouter une congé</Button>
                                            </Grid> 
                                        </Grid> 

                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Libelle</th>
                                                    <th scope="col">Debut</th>                                        
                                                    <th scope="col">Fin</th>                                                    
                                                    <th scope="col">Congés pris</th>
                                                    {/* <th scope="col">Congés restant</th> */}
                                                    <th scope="col">Durée totale</th>
                                                    <th scope="col">Statut</th>
                                                    <th scope="col">Motif refus</th>
                                                    <th scope="col">Modification</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {vacationsArray.length > 0 &&
                                                    vacationsArray.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.vacationType.name}</td>
                                                                <td>{formatDate(item.start)}</td> 
                                                                <td>{formatDate(item.end)}</td>                                                                  
                                                                <td>{GetDIffence(item.start, item.end)} Jrs</td> 
                                                                {/* <td>{item.vacationType.days - GetDIffence(item.start, item.end)} Jrs</td>  */}
                                                                <td>{item.vacationType.days} Jrs</td>
                                                                <td onClick={!this.props.accesses.approve ? null : () => { this.editCongeStatus(item.id) }} style={{cursor: 'pointer'}}>
                                                                    {item.status === "Processing" && "Traitement"}
                                                                    {item.status === "Approved" && "Approuvé"}
                                                                    {item.status === "Disaproved" && "Refusé"}
                                                                </td>
                                                                <td>{item.refuseReason}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editConge(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeConge(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>        
                                                    )})
                                                }                                 
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="7" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({permission: {...permissionInitialState}, id_permission: null, addpermission: true})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Ajouter une permission</Button>
                                            </Grid> 
                                        </Grid> 

                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Raison</th>
                                                    <th scope="col">Debut</th>                                        
                                                    <th scope="col">Fin</th>
                                                    <th scope="col">Statut</th> 
                                                    <th scope="col">Motif refus</th> 
                                                    <th scope="col">Modification</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {permissions.length > 0 &&
                                                    permissions.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.reason}</td>
                                                                <td>{formatDate(item.start)}</td> 
                                                                <td>{formatDate(item.end)}</td>
                                                                <td onClick={!this.props.accesses.approve ? null : () => { this.editPermissionStatus(item.id) }} style={{cursor: 'pointer'}}>
                                                                    {item.status === "Processing" && "Traitement"}
                                                                    {item.status === "Approved" && "Approuvé"}
                                                                    {item.status === "Disaproved" && "Refusé"}
                                                                </td>
                                                                <td>{item.refuseReason}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editpermission(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removepermission(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>        
                                                        )
                                                    })
                                                }                                
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="8" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({sanction: {sanctionInitialState}, id_sanction: null, addSanction: true})} sx={{mr:2,fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Ajouter une sanction</Button>
                                            </Grid> 
                                        </Grid> 

                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Type de saction</th>  
                                                    <th scope="col">Raison</th>
                                                    <th scope="col">Statut</th> 
                                                    <th scope="col">Modification</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {disciplinesArray.length > 0 &&
                                                    disciplinesArray.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.disciplineType.name}</td> 
                                                                <td>{item.reason}</td>
                                                                <td onClick={!this.props.accesses.approve ? null : () => { this.editSanctionStatus(item.id) }} style={{cursor: 'pointer'}}>
                                                                    {item.status === "Processing" && "Traitement"}
                                                                    {item.status === "Approved" && "Approuvé"}
                                                                    {item.status === "Disaproved" && "Refusé"}
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editSanction(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeSanction(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>        
                                                        )
                                                    })
                                                }                                
                                            </tbody>
                                        </table>
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </DialogContent >
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le contrat d'un employé
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEdit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        select
                                        label="Type de contrat"
                                        name="type"
                                        style={{ width: '100%' }}
                                        value={contractChange.type}
                                        onChange={this.handleContractChange}
                                        size="small"
                                    >
                                        <MenuItem key="CDD" value="CDD">CDD</MenuItem>
                                        <MenuItem key="CDI" value="CDI">CDI</MenuItem>
                                        <MenuItem key="Bénévole" value="Bénévole">Bénévole</MenuItem>
                                        <MenuItem key="Volontaire" value="Volontaire">Volontaire</MenuItem>
                                        <MenuItem key="Prestataire" value="Prestataire">Prestataire</MenuItem>
                                        <MenuItem key="Stagiaire" value="Stagiaire">Stagiaire</MenuItem>
                                        <MenuItem key="Autres" value="Autres">Autres</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={4}>                                    
                                    <TextField
                                        required
                                        type="date"
                                        name="start"
                                        label="Debut du contrat"
                                        style={{ width: '100%' }}
                                        value={contractChange.start}
                                        onChange={this.handleContractChange} 
                                        size="small"
                                    />
                                </Grid> 
                                
                                <Grid item xs={4}>                                    
                                    <TextField
                                        type="date"
                                        name="end"
                                        disabled={type === "CDI"}
                                        label="Fin du contrat"
                                        style={{ width: '100%' }}
                                        value={contractChange.end}
                                        onChange={this.handleContractChange} 
                                        size="small"
                                    />
                                </Grid> 

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Salaire de base"
                                        name="baseSalary"
                                        style={{ width: '100%' }}
                                        value={contractChange.baseSalary}
                                        onChange={this.handleContractChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 0.5,
                                            min: 0
                                        }}
                                    />
                                </Grid> 

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Poste"
                                        name="workplacename"
                                        style={{ width: '100%' }}
                                        value={contractChange.workplacename}
                                        onChange={this.handleContractChange}
                                        size="small"
                                    />
                                </Grid>  

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Département"
                                        name="department"
                                        style={{ width: '100%' }}
                                        value={contractChange.department}
                                        onChange={this.handleContractChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>                

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={remove}
                    onClose={() => this.setState({remove: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Rompre le contrat
                        </Typography>

                        <IconButton onClick={() =>  this.setState({remove: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitRemove} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Les motifs pour rompre le contrat"
                                        name="reason"
                                        style={{ width: '100%' }}
                                        value={reason}
                                        onChange={this.handleChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour rompre
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addpermission}
                    onClose={() => this.setState({addpermission: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_permission ? "Modifier une permission" : "Ajouter une permission"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addpermission: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitPermission} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        label="La raison de la permission"
                                        name="reason"
                                        style={{ width: '100%' }}
                                        value={permission.reason}
                                        onChange={this.handlePermissionChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        required
                                        label="Debut de la permission"
                                        type="date"
                                        name="start"
                                        style={{ width: '100%' }}
                                        value={permission.start}
                                        onChange={this.handlePermissionChange} 
                                        size="small"
                                        />
                                </Grid> 
                                
                                <Grid item xs={6}>                                    
                                    <TextField
                                        required
                                        disabled={type === "CDI"}
                                        label="Fin de la permission"
                                        type="date"
                                        name="end"
                                        style={{ width: '100%' }}
                                        value={permission.end}
                                        onChange={this.handlePermissionChange} 
                                        size="small"
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            
                            {id_permission ? "Cliquer pour modifier" : "Cliquer pour ajouter"}
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addSanction}
                    onClose={() => this.setState({addSanction: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_sanction ? "Modifier une sanction disciplinaire" : "Ajouter une sanction disciplinaire"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addSanction: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitSanction} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        label="La raison de la sanction"
                                        name="reason"
                                        style={{ width: '100%' }}
                                        value={sanction.reason}
                                        onChange={this.handleSanctionChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Type de sanction disciplinaire"
                                        name="disciplineTypeId"
                                        style={{ width: '100%' }}
                                        value={sanction.disciplineTypeId}
                                        onChange={this.handleSanctionChange}
                                        size="small"
                                    >
                                        {disciplines.map((answer) => (
                                            <MenuItem key={answer.id} value={answer.id}>{answer.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            {id_sanction ? "Cliquer pour modifier" : "Cliquer pour ajouter"}
                            
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editSanctionStatus}
                    onClose={() => this.setState({editSanctionStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut d'une sanction
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editSanctionStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitSanctionStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de la sanction"
                                        name="SanctionStatus"
                                        style={{ width: '100%' }}
                                        value={SanctionStatus}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editPermissionStatus}
                    onClose={() => this.setState({editPermissionStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut d'une permission
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editPermissionStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitPermissionStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de la permission"
                                        name="SanctionStatus"
                                        style={{ width: '100%' }}
                                        value={SanctionStatus}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                                {SanctionStatus === "Disaproved" &&
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            label="Motif du refus"
                                            name="refuseReason"
                                            style={{ width: '100%' }}
                                            value={refuseReason}
                                            onChange={this.handleChange}
                                            size="small"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addConge}
                    onClose={() => this.setState({addConge: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_vacation ? "Modifier un congé" : "Ajouter un congé"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addConge: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitConge} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Type de congé"
                                        name="vacationTypeId"
                                        style={{ width: '100%' }}
                                        value={vacation.vacationTypeId}
                                        onChange={this.handleVacationChange}
                                        size="small"
                                    >
                                        {vacations.map((answer) => (
                                            <MenuItem key={answer.id} value={answer.id}>{answer.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        label="Debut"
                                        type="date"
                                        name="start"
                                        style={{ width: '100%' }}
                                        value={vacation.start}
                                        onChange={this.handleVacationChange} 
                                        size="small"
                                    />
                                </Grid> 
                                
                                <Grid item xs={6}>                                    
                                    <TextField
                                        label="Fin"
                                        type="date"
                                        name="end"
                                        style={{ width: '100%' }}
                                        value={vacation.end}
                                        onChange={this.handleVacationChange} 
                                        size="small"
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            
                            {id_vacation ? "Cliquer pour modifier" : "Cliquer pour ajouter"}
                        </Button>   
                    </form>
                </Dialog>
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editCongeStatus}
                    onClose={() => this.setState({editCongeStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut du congé
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editCongeStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitCongeStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut du congé"
                                        name="CongeStatus"
                                        style={{ width: '100%' }}
                                        value={CongeStatus}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                                {CongeStatus === "Disaproved" &&
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            label="Motif du refus"
                                            name="refuseReason"
                                            style={{ width: '100%' }}
                                            value={refuseReason}
                                            onChange={this.handleChange}
                                            size="small"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addSup}
                    onClose={() => this.setState({addSup: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_suplementaire ? "Ajouter une heure supplementaire" : "Ajouter une heure supplementaire"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addSup: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitSup} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Taux horaire"
                                        name="rate"
                                        style={{ width: '100%' }}
                                        value={suplementaire.rate}
                                        onChange={this.handleHourChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Nombre d'heure"
                                        name="hour"
                                        style={{ width: '100%' }}
                                        value={suplementaire.hour}
                                        onChange={this.handleHourChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        type="date"
                                        name="start"
                                        label="La date"
                                        style={{ width: '100%' }}
                                        value={suplementaire.start}
                                        onChange={this.handleHourChange} 
                                        size="small"
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                           {id_suplementaire ? "Cliquer pour modifier" : "Cliquer pour ajouter"}
                        </Button>   
                    </form>
                </Dialog>
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editSupStatus}
                    onClose={() => this.setState({editSupStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut de l'heure suplementaire
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editSupStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitSupStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de l'heure suplementaire"
                                        name="SupStatus"
                                        style={{ width: '100%' }}
                                        value={SupStatus}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                                {SupStatus === "Disaproved" &&
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            required
                                            label="Motif du refus"
                                            name="refuseReason"
                                            style={{ width: '100%' }}
                                            value={refuseReason}
                                            onChange={this.handleChange}
                                            size="small"
                                            multiline
                                            rows={2}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>


                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )
    }
}