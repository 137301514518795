import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import StatusSelecter from '../../Components/StatusSelecter' 
import Search from "../../Components/Search"

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const initialStats = {
    name: "",
    company: "",
    email: "",
    address: "",
    nif: "",
    phone: "",
    domain: '',
}

export default class Providers extends Component {

    constructor(props) {
        super(props)
        this.state = {
            providers: [],
            // transactions: [],

            open: false,
            add: false,
            edit: false,
            id_provider: null,


            provider: {},
            ...initialStats,

            valuetab:"1",
            status: "Actif",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",

            anchorEl: [],
        }
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)   
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)   
        this.handleChangetab = this.handleChangetab.bind(this)  
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Gestion des partenaires techniques et financiers`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/providers/all', config)
        .then(response => {            
            this.setState({ 
                providers: response.data,
                loadingPage: false, loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        });   
    }

    ChangeStatus(status){ this.setState({status: status}) }

    ChangeSearch(search){ this.setState({search: search}) }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleSubmit(event) {
        event.preventDefault()      
        this.setState({loading: true })    
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const {name, company, email, address, nif, phone, domain} = this.state

        const bodyParameters = {
            name: name,
            company: company,
            email: email,
            address: address,
            nif: nif,
            phone: phone,
            domain: domain
        }

        axios.post( 
            '/api/v1/providers',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ message:"Fournisseur enregistré avec succès", add: false, isDid: true, });
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const {name, company, email, address, nif, phone, domain} = this.state

        const bodyParameters = {
            name: name,
            company: company,
            email: email,
            address: address,
            nif: nif,
            phone: phone,
            domain: domain
        }
        axios.put( 
            '/api/v1/providers/' + this.state.id_provider,
            bodyParameters,
            config
        ).then(res => {
            this.setState({ message:"Fournissuer modifié avec succès", isDid: true, edit:false, anchorEl: []});
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    show(id){
        this.setState({loading: true })  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/providers/' + id, config)
        .then(response => {            
            this.setState({ 
                provider: {
                    transactions: response.data.transactions,
                    name: response.data.name,
                },
                open: true,
                loading: false 
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false }); 
        });         
    }

    edit(id){
        this.setState({loading: true })  
        const item = this.state.providers.filter(c => c["id"] === id)[0]
        this.setState({
            id_provider: id,
            name: item.name,
            company: item.company,
            email: item.email,
            nif: item.nif,
            phone: item.phone,
            domain: item.domain,
            address: item.address,
            loading: false,
            edit: true
        })
    }

    delete(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/providers/' + id,
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true, anchorEl: []});
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    render(){

        const {
            providers,
            provider,
            open,
            add,
            edit,
            name,
            company,
            email,
            address,
            nif,
            phone,
            domain,

            valuetab,
            status,
            search,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl

        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2
            },
            { 
                field: 'company', 
                headerName: 'Structure',
                flex: 1/2
            },
            
            { 
                field: 'nif', 
                headerName: "NIF",
                flex: 1/2
            },
            { 
                field: 'email', 
                headerName: "Email",
                flex: 1/2
            },
            { 
                field: 'address', 
                headerName: "Addresse",
                flex: 1/2
            },
            { 
                field: 'phone', 
                headerName: "Téléphone",
                flex: 1/2
            },
            { 
                field: 'domain', 
                headerName: "Domaine d'intervention",
                flex: 1
            },
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton disabled={!this.props.accesses.approve} onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton disabled={!this.props.accesses.approve} onClick={() => this.delete(cellValues.row.id)}>   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                            
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem  disabled={!this.props.accesses.write} onClick={() => { this.edit(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            },
        ]

        let Dataproviders =[]
        if(status == 'Tout'){
            Dataproviders = providers
        }
        if(status == 'Actif'){
            Dataproviders = providers.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            Dataproviders = providers.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            Dataproviders = Dataproviders.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({add: true, ...initialStats})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouveau</Button>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={Dataproviders}
                                columns={column}
                                loading={loadingPage}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un fournisseur
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du fournisseur"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de la structure"
                                        name="company"
                                        style={{ width: '100%' }}
                                        value={company}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="NIF du fournisseur"
                                        name="nif"
                                        style={{ width: '100%' }}
                                        value={nif}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Email du fournisseur"
                                        name="email"
                                        style={{ width: '100%' }}
                                        value={email}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Adresse du fournisseur"
                                        name="address"
                                        style={{ width: '100%' }}
                                        value={address}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Téléphone du fournisseur"
                                        name="phone"
                                        style={{ width: '100%' }}
                                        value={phone}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Domaine d'intervention"
                                        name="domain"
                                        style={{ width: '100%' }}
                                        value={domain}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {provider.name}: Transactions associées
                        </Typography>

                        <IconButton onClick={() =>  this.setState({open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Référence</th>
                                            <th scope="col">N° Enr.</th>
                                            <th scope="col">Facture</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Montant</th>
                                            <th scope="col">Code anlytique</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Methode de reglement</th>
                                            <th scope="col">Statut</th>
                                            <th scope="col">Documents</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {provider.transactions?.length > 0 &&
                                            provider.transactions.map((item) => {
                                                return(
                                                    <tr>
                                                        <td>{item.reference}</td>
                                                        <td>{item.number}</td> 
                                                        <td>{item.label}</td> 
                                                        <td>{new Date(item.date).toLocaleDateString()}</td> 
                                                        <td>{item.amount.toLocaleString()}</td>
                                                        <td>{item.analyticCode}</td>
                                                        <td>{item.type}</td>
                                                        <td>{item.paymentMethode}</td>
                                                        <td>{item.status}</td>
                                                        <td>

                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>  
                            </Grid>                            
                        </Grid>
                    </DialogContent >
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier un fournisseur
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEdit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du fournisseur"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de la structure"
                                        name="company"
                                        style={{ width: '100%' }}
                                        value={company}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="NIF du fournisseur"
                                        name="nif"
                                        style={{ width: '100%' }}
                                        value={nif}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Email du fournisseur"
                                        name="email"
                                        style={{ width: '100%' }}
                                        value={email}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Adresse du fournisseur"
                                        name="address"
                                        style={{ width: '100%' }}
                                        value={address}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Téléphone du fournisseur"
                                        name="phone"
                                        style={{ width: '100%' }}
                                        value={phone}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Domaine d'intervention"
                                        name="domain"
                                        style={{ width: '100%' }}
                                        value={domain}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>            
            </React.Fragment>
        )

    }

}