import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Dropzone from 'react-dropzone'
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList'; 
import Search from "../../Components/Search"
import DescriptionIcon from '@mui/icons-material/Description';
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import StatusSelecter from '../../Components/StatusSelecter'
import Link from '@mui/material/Link';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default class Letters extends Component {

    constructor(props) {
        super(props)
        this.state = {
            letters: [],
            employees: [],

            add: false,
            editStatus: false,
            open: false,
            addFileDoc: false,
            // editPar: false,

            id_letter: null,            
            label: "",
            senderName: "",
            senderCompany: "",
            senderAddress: "",
            senderEmail: "",
            senderPhone: "",
            employeeId: null,
            dateOfReceipt: new Date(),
            description: "",
            selectedFile: [],
            status: "Actif",
            statuss: "",
            employee: [],
            documents: [],

            valuetab:"1",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",
            anchorEl: null,
            anchorEl1: []

        }
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleClick3 = this.handleClick3.bind(this)
        this.handleSubmitStatus = this.handleSubmitStatus.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleSubmitDoc = this.handleSubmitDoc.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Gestion des Événements`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/letters/all', config)
        .then(response => {    
            axios.get('/api/v1/employees', config)   
            .then(res => {   
                this.setState({ 
                    letters: response.data,
                    employees:  res.data,
                    loadingPage: false, loading: false
                });
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false }); 
            }); 
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false }); 
        });   
    }

    ChangeSearch(search){ this.setState({search: search}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    ChangeStatus(status){ this.setState({status: status}) }

    handleClick(event){this.setState({anchorEl: event.currentTarget})}

    handleSelect(date){ this.setState({dateOfReceipt: date, anchorEl: null}) }

    fileDrop(acceptedFiles){ this.setState({ selectedFile: acceptedFiles }) }

    handleClick3(id, event){       
        let { anchorEl1 } = this.state
        anchorEl1[id] = event.target;
        this.setState({ anchorEl1 });
    }

    handleSubmit(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        const bodyParameters = new FormData();
        bodyParameters.append("label", this.state.label)
        bodyParameters.append("description", this.state.description)
        bodyParameters.append("dateOfReceipt", this.state.dateOfReceipt)        
        bodyParameters.append("senderName", this.state.senderName)
        bodyParameters.append("senderCompany", this.state.senderCompany)        
        bodyParameters.append("senderAddress", this.state.senderAddress)
        bodyParameters.append("senderEmail", this.state.senderEmail)           
        bodyParameters.append("senderPhone", this.state.senderPhone)
        bodyParameters.append("employeeId", this.state.employeeId)

        this.state.selectedFile.forEach (d => {
            bodyParameters.append('documents', d)            
        })

        // bodyParameters.append("documents[]", this.state.selectedFile)
        axios.post(
            '/api/v1/letters', 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Courrier ajouté avec succès",
                isDid: true,
                add: false,
            });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.statuss
        }
        axios.put(
            '/api/v1/letters/' + this.state.id_letter + '/status', 
            bodyParameters,
            config
        )
        .then(response => { 
            this.setState({
                message:"Statut changée avec succès",
                isDid: true, 
                editStatus:false, 
                anchorEl1: [],
                loading: false
            });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitDoc(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        const bodyParameters = new FormData();
        this.state.selectedFile.forEach (d => {
            bodyParameters.append('documents', d)            
        })
        axios.put(
            '/api/v1/letters/' + this.state.id_letter + '/document', 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Piece jointe ajoutée avec succès",
                isDid: true,
                addFileDoc: false,
            });
            this.show(this.state.id_letter)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    show(id){
        this.setState({loading: true })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/letters/' + id, config)
        .then(response => {            
            this.setState({ 
                id_letter: id,
                label: response.data.label,
                description: response.data.description,
                dateOfReceipt: response.data.dateOfReceipt,
                senderName: response.data.senderName,
                senderCompany: response.data.senderCompany,
                senderAddress: response.data.senderAddress,
                senderEmail: response.data.senderEmail,
                senderPhone: response.data.senderPhone,
                employee: response.data.employee,
                documents: response.data.documents,
                loading: false,
                open: true
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    editStatut(id){
        this.setState({loading: true })  
        const item = this.state.letters.filter(c => c["id"] === id)[0]
        this.setState({
            id_letter: id,
            statuss: item.status,
            editStatus: true,
            loading: false           
        })        
    }

    delete(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/letters/' + id,
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true, anchorEl: []});
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            this.setState({loading: false })  
        }); 
    }

    deleteStatus(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/letters/' + id + '/archive',
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });  
        }); 
    }

    removeDoc(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/letters/' + this.state.id_letter + '/document/' + id,
            config
        ).then(response => {     
            this.setState({message:"Document supprimé avec succès", isDid: true });
            this.show(this.state.id_letter)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        }); 
    }

    render(){

        const {
            letters,
            employees,
            label,
            senderName,
            senderCompany,
            senderAddress,
            senderEmail,
            senderPhone,
            employeeId,
            dateOfReceipt,
            description,
            selectedFile,
            statuss,
            status,
            add,
            editStatus,
            search,    
            open,       
            employee,
            documents,
            addFileDoc,

            valuetab,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl,
            anchorEl1
        } = this.state

        const column = [
            { 
                field: 'label', 
                headerName: 'Objet',
                flex: 1
            },
            { 
                field: 'senderName', 
                headerName: 'Expediteur',
                flex: 1,
            },
            { 
                field: 'description', 
                headerName: 'Desctiption',
                flex: 2
            },
            { 
                field: 'dateOfReceipt', 
                headerName: 'Date',
                maxWidth: 100,
                renderCell: (cellValues) => {return (new Date(cellValues.row.dateOfReceipt).toLocaleDateString())}
            },
            { 
                field: 'status', 
                headerName: 'État',
                maxWidth: 100,
            },
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton 
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.deleteStatus(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton 
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.deleteStatus(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                            
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick3(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl1[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl1[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl1: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{ fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem  style={{ fontSize: 12, minHeight: 20}}
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => { this.editStatut(cellValues.row.id) }}
                                >
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>changer l'état du courrier
                                </MenuItem>

                                <MenuItem style={{ fontSize: 12, minHeight: 20, color:"red"}}
                                    disabled={!this.props.accesses.remove}
                                    onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(cellValues.row.id)}}} 
                                >
                                    <ListItemIcon><DeleteForeverIcon fontSize="small" style={{color:"red"}} /></ListItemIcon>Supprimer
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            },
        ]

        let DataLetters=[]
        if(status == 'Tout'){
            DataLetters = letters
        }
        if(status == 'Actif'){
            DataLetters = letters.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            DataLetters = letters.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            DataLetters = DataLetters.filter(elem => { return elem.label.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() === 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() === 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() === 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() === 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() === 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() === 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() === 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() === 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() === 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() === 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() === 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() === 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        return(
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({add: true})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouveau</Button>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={6}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={DataLetters}
                                columns={column}
                                loading={loadingPage}
                                sortModel={[{
                                    field: 'name',
                                    sort: 'asc',
                                }]}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un courrier
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Objet"
                                        name="label"
                                        style={{ width: '100%' }}
                                        value={label}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de l'expediteur"
                                        name="senderName"
                                        style={{ width: '100%' }}
                                        value={senderName}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Structure de l'expediteur"
                                        name="senderCompany"
                                        style={{ width: '100%' }}
                                        value={senderCompany}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>         

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Adresse de l'expediteur"
                                        name="senderAddress"
                                        style={{ width: '100%' }}
                                        value={senderAddress}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>            

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Email de l'expediteur"
                                        name="senderEmail"
                                        style={{ width: '100%' }}
                                        value={senderEmail}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>         

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Téléphone de l'expediteur"
                                        name="senderPhone"
                                        style={{ width: '100%' }}
                                        value={senderPhone}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>  

                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        select
                                        label="Employé destinateur du courrier"
                                        name="employeeId"
                                        style={{ width: '100%' }}
                                        value={employeeId}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        {
                                            employees.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    {answer.firstName + ' ' + answer.lastName + " (" + answer.email + ")"}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>   

                                <Grid item xs={6}>                                    
                                    <TextField
                                        id="outlined-required"
                                        label="Date de reception du courrier"
                                        style={{ width: '100%' }}
                                        value={displaydate(dateOfReceipt)}
                                        onClick={this.handleClick} 
                                        size="small"
                                    />
                                </Grid> 

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Desctiption du courrier"
                                        name="description"
                                        style={{ width: '100%' }}
                                        value={description}
                                        onChange={this.handleChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {selectedFile.length === 0 ? 
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez Les pieces jointes ici, ou cliquez pour sélectionner les fichiers</p>
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                            {selectedFile.length > 0 &&
                                                                selectedFile.map((item) => {
                                                                    return(
                                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                            {item.path}
                                                                        </Typography>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>


                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button> 
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editStatus}
                    onClose={() => this.setState({editStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer l'état du courrier
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut du courrier"
                                        name="statuss"
                                        style={{ width: '100%' }}
                                        value={statuss}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="En attente" value="En attente">En attente</MenuItem>
                                        <MenuItem key="Recuperé" value="Recuperé">Recuperé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button> 
                    </form>
                </Dialog> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Détails du courrier
                        </Typography>

                        <IconButton onClick={() =>  this.setState({open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TabContext value={valuetab}  >
                                    <Box sx={{  borderColor: 'divider'}}>
                                        <TabList onChange={this.handleChangetab} variant="scrollable" scrollButtons="auto">
                                            <Tab label="Détails du courrier" value="1" style={{fontSize: 12, fontWeight: 'bold'}}/>     
                                            <Tab label="Les pieces jointes" value="2" style={{fontSize: 12, fontWeight: 'bold'}}/>
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1" sx={{p:2, minHeight:"60vh"}}>
                                        <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between'}} >
                                            <Box>
                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Objet: </strong>{label}
                                                </Typography>
                                            </Box>

                                            <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                <strong>{new Date(dateOfReceipt).toLocaleDateString()}</strong>
                                            </Typography>
                                        </Box>

                                        <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', marginTop:16}} >
                                            <Box>
                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Nom: </strong>{senderName}
                                                </Typography>

                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Structure: </strong>{senderCompany}
                                                </Typography>

                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Adresse: </strong>{senderAddress}
                                                </Typography>
                                            </Box>

                                            <Box>
                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Email: </strong>{senderEmail}
                                                </Typography>

                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Téléphone: </strong>{senderPhone}
                                                </Typography>

                                                <Typography component="p" variant="p" style={{fontSize: 12}} color="text.secondary">
                                                    <strong>Employée: </strong>

                                                    {employee.firstName + ' ' + employee.lastName + ' (' + employee.email + ')'}
                                                </Typography>
                                            </Box>
                                        </Box>                                        

                                        <Typography component="p" variant="p" style={{fontSize: 12, marginTop:16}} color="text.secondary" >
                                            <strong>Description: </strong>
                                        </Typography>

                                        {description}
                                    </TabPanel>

                                    <TabPanel value="2" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button 
                                                    disabled={!this.props.accesses.write}
                                                    variant="contained" 
                                                    onClick={() => this.setState({addFileDoc: true})}
                                                    sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                                                >
                                                    Ajouter une piece jointe
                                                </Button>
                                            </Grid> 
                                        </Grid> 

                                    
                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Documents</th>
                                                    <th scope="col">Voir</th>
                                                    <th scope="col">Supression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {documents.map((item) => {
                                                    return(
                                                        <tr>
                                                            <td>{item.file}</td>
                                                            <td>
                                                                <Link color="inherit" href={item.file} target={"_blank"} style={{textDecoration: 'none', }}>                                                                    
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Voir la pièce jointe
                                                                    </Button>
                                                                </Link>
                                                            </td>

                                                            <td>
                                                                <Button
                                                                    disabled={!this.props.accesses.remove}
                                                                    //disabled={elem.status === "Approved"}
                                                                    onClick={() => { this.removeDoc(item.id) }}
                                                                    variant="contained"
                                                                    sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                                >
                                                                    Supprimer
                                                                </Button>
                                                            </td>

                                                            
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </DialogContent>                
                </Dialog>  

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addFileDoc}
                    onClose={() => this.setState({addFileDoc: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une piece jointe
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addFileDoc: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitDoc}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                                        {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {selectedFile.length === 0 ? 
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez Les pieces jointes ici, ou cliquez pour sélectionner les fichiers</p>
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                            {selectedFile.length > 0 &&
                                                                selectedFile.map((item) => {
                                                                    return(
                                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                            {item.path}
                                                                        </Typography>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>


                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button> 
                    </form>
                </Dialog>           

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => {this.setState({ anchorEl: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={dateOfReceipt}
                        onChange={this.handleSelect}
                        locale={locales['fr']}
                        maxDate={new Date()}
                    />
                </Menu>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}