import React, { Component } from "react";
import './style.css'
import axios from "axios";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import adps from '../../Images/adps.png'
import amss from '../../Images/amss.png'
import capdh from '../../Images/capdh.png'
import cndh from '../../Images/cndh.png'
import fav from '../../Images/fav.png'
import kora from '../../Images/kora.png'
import logo from '../../Images/logo.png'
import nordev from '../../Images/nordev.png'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default class Login extends Component { 

    constructor(props) {
        super(props)
        this.state = {
            email: "",
            password: "",

            message: "",
            forget: false,
            isConnected: false,
            isError: false,
            loader: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitreset = this.handleSubmitreset.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Login`
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleSubmit(event){
        event.preventDefault();
        this.setState({loader: true })

        const {email, password} = this.state
    
        axios({            
            method: 'post',
            responseType: 'json',
            url: '/api/v1/auth', 
            data: {
                email,
                password
            },
        }).then(res => {
            this.getAccesses(res.data)
        }).catch(error => {
            this.setState({ isError: true, loader: false, message: "Email et/ou mot de passe incorrect(s)"  })
        });
    }

    getAccesses = (data) =>{
        const config = {
            headers: {'Authorization': 'Bearer '+data.accessToken}
        };
        axios.get('/api/v1/users/' + data.id, config)
        .then(response => {            
            localStorage.setItem("TOKEN", JSON.stringify(data.accessToken));
            localStorage.setItem("RTOKEN", JSON.stringify(data.refreshToken));
            localStorage.setItem("EMAIL", JSON.stringify(data.email));
            localStorage.setItem("ID", JSON.stringify(data.id));
            localStorage.setItem("NAME", JSON.stringify(data.name));
            localStorage.setItem("ACCESSES", JSON.stringify(response.data.role.accesses));            
            this.setState({
                message:"Vous êtes connecté(e)",
                isConnected: true
            })
            setTimeout(() => {
                window.location.href = '/dashboard';                
            }, 300);
        }).catch(error => {
            this.setState({ isError: true, loader: false, message: "Email et/ou mot de passe incorrect(s)" });
            // window.location.href = '/';
        }); 
    }

    handleSubmitreset(event){
        event.preventDefault()        
        this.setState({loader: true})   
        const bodyParameters = {
            "email": this.state.email
        }
        axios.post( 
            '/api/v1/auth/forget-password', 
            bodyParameters
        )
        .then(response => {   
            console.log("response", response) 
            this.setState({
                message:"E-mail envoyé contenant les instructions vous permettant de vous connecter.",
                isConnected: true,
                loader: false,
            });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isError: true, loader: false });
        });
    }   

    render() {

        const {loader, isConnected, isError, message, forget} = this.state
        const ORG_NAME = window.ORG_NAME;
        
        return (
            <div className="container">
                <div className="screen">
                    <div className="screen__content">
                        {!forget ? 
                            <React.Fragment>
                                <form className="login" onSubmit={this.handleSubmit}>
                                    <div className="login__field">
                                        <i className="login__icon fas fa-user"></i>
                                        <input type="text" className="login__input" placeholder="Adresse électronique" name="email" onChange={this.handleChange}/>
                                    </div> 

                                    <div className="login__field">
                                        <i className="login__icon fas fa-lock"></i>
                                        <input type="password" className="login__input" autoComplete="off" placeholder="Mot de passe" name="password" onChange={this.handleChange} />
                                    </div>

                                    <button type="submit" className="button login__submit" disabled={loader}>
                                        <span className="button__text">Se connecter</span>

                                        {loader ?
                                            <CircularProgress className="button__icon" size={24} ml={2} sx={{color:'#7875B5'}} value={100} />
                                        :
                                            <i className="button__icon fas fa-chevron-right"></i>
                                        }
                                    </button>
                                </form>

                                <Link 
                                    variant="subtitle1"
                                    underline="none"
                                    onClick={() => this.setState({forget: !forget})}
                                    sx={{cursor: 'pointer', fontSize: 18, margin: '30px', marginTop: '156px',  }}
                                >
                                    Mot de passe oublié ?
                                </Link>
                            </React.Fragment>
                        :
                            <React.Fragment>
                                <form className="login" onSubmit={this.handleSubmitreset}>
                                    <Typography 
                                        variant="subtitle1" 
                                        color="text.secondary"  
                                        sx={{ mt:-8 }}
                                    >
                                        Merci de renseigner votre adresse e-mail. 
                                        Vous recevrez un e-mail contenant les instructions vous permettant de réinitialiser votre mot de passe.
                                    </Typography>
                                    
                                    <div className="login__field">
                                        <i className="login__icon fas fa-user"></i>
                                        <input type="text" className="login__input" placeholder="Adresse électronique" name="email" onChange={this.handleChange}/>
                                    </div>

                                    <button type="submit" className="button login__submit" disabled={loader}>
                                        <span className="button__text">Envoyer</span>

                                        {loader ?
                                            <CircularProgress className="button__icon" size={24} ml={2} sx={{color:'#7875B5'}} value={100} />
                                        :
                                            <i className="button__icon fas fa-chevron-right"></i>
                                        }
                                    </button>
                                </form>

                                <Link 
                                    variant="subtitle1"
                                    underline="none"
                                    onClick={() => this.setState({forget: !forget})}
                                    className="login"
                                    sx={{cursor: 'pointer', fontSize: 18, margin: '30px', marginTop: '156px',  }}
                                >
                                    Annuler
                                </Link>
                            </React.Fragment>
                        }
                        <div className="social-login">
                            <div className="social-icons">
                                {ORG_NAME === 'ADPS' && <img src={adps}  style={{'width':100}} alt="ADPS" />}
                                {ORG_NAME === 'AMSS' && <img src={amss}  style={{'width':100}} alt="AMSS" />}
                                {ORG_NAME === 'CAPDH' && <img src={capdh}  style={{'width':100}} alt="CAPDH" />}
                                {ORG_NAME === 'CNDH' && <img src={cndh}  style={{'width':100}} alt="CNDH" />}
                                {ORG_NAME === 'Consortium Fav' && <img src={fav}  style={{'width':100}} alt="Consortium fav" />}
                                {ORG_NAME === 'Kora Films' && <img src={kora}  style={{'width':100}} alt="Kora films" />}
                                {ORG_NAME === 'Codesign' && <img src={logo}  style={{'width':100}} alt="Codesign" />}
                                {ORG_NAME === 'NORDEV' && <img src={nordev}  style={{'width':100}} alt="NORDEV" />}
                            </div>
                        </div>
                    </div>
                    <div className="screen__background">
                        <span className="screen__background__shape screen__background__shape4"></span>
                        <span className="screen__background__shape screen__background__shape3"></span>
                        <span className="screen__background__shape screen__background__shape2"></span>
                        <span className="screen__background__shape screen__background__shape1"></span>
                    </div>
                </div>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isConnected} autoHideDuration={6000} onClose={() => this.setState({ isConnected: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isConnected: false })} >
                        {message}
                    </Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isError} autoHideDuration={6000} onClose={() => this.setState({ isError: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isError: false })} >
                        {message}
                    </Alert>
                </Snackbar>
            </div>
        )
    
    }

}
