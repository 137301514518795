import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Search from "../../Components/Search"
import Tooltip from '@mui/material/Tooltip';
import Switch from '@mui/material/Switch';
import StatusSelecter from '../../Components/StatusSelecter'
import { formatDate, formatToInputDate } from "../../tools/functions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const initialStats = {
    name: "",
    partner: "",
    date: new Date(),
    tva: 0,
    status: "Actif",
    search: "",
    label: "",
    quantity: 1,
    unitPrice: 0,   
}
export default class Invoices extends Component {

    constructor(props) {
        super(props)
        this.state = {
            invoices: [],
            lines: [],

            add: false,
            edit: false,
            open: false,
            addLine: false,
            editLine: false,

            id_invoice: null,
            lineId: null,
            ...initialStats,           

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",
            anchorEl: null,
            anchorEl1: []
        }
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleClick3 = this.handleClick3.bind(this)
        this.handleSubmitLine = this.handleSubmitLine.bind(this)
        this.handleSubmitLineEdit = this.handleSubmitLineEdit.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Gestion des factures`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/invoices/all', config)
        .then(response => {    
            this.setState({ 
                invoices: response.data,
                loadingPage: false, loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false }); 
        });   
    }

    ChangeStatus(status){ this.setState({status: status}) }

    ChangeSearch(search){ this.setState({search: search}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleClick(event){this.setState({anchorEl: event.currentTarget})}

    handleSelect(date){ this.setState({start: date, anchorEl: null}) }

    handleClick3(id, event){       
        let { anchorEl1 } = this.state
        anchorEl1[id] = event.target;
        this.setState({ anchorEl1 });
    }

    show(id){
        this.setState({loading: true })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/invoices/' + id, config)
        .then(response => {            
            this.setState({ 
                id_invoice: id,
                name: response.data.name,
                date: response.data.date,
                tva: response.data.tva,
                partner: response.data.partner,
                lines: response.data.lines,
                loading: false,
                open: true
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmit(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        const bodyParameters = {
            "name": this.state.name,
            "date": this.state.date,
            "tva": parseFloat(this.state.tva),
            "partner": this.state.partner
        }
        axios.post( 
            '/api/v1/invoices', 
            bodyParameters,
            config
        )
        .then(_ => {    
            this.setState({
                message:"Facture ajoutée avec succès",
                isDid: true,
                add: false,
                loading: false
            });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        }); 
    }

    handleSubmitEdit(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        const bodyParameters = {
            "name": this.state.name,
            "date": this.state.date,
            "tva": parseFloat(this.state.tva),
            "partner": this.state.partner
        }
        axios.put( 
            '/api/v1/invoices/' + this.state.id_invoice, 
            bodyParameters,
            config
        )
        .then(_ => {    
            this.setState({
                message:"Facture modifié avec succès",
                isDid: true,
                edit: false,
                loading: false
            });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        }); 
    }

    handleSubmitLine(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        const bodyParameters = {
            "label": this.state.label,
            "quantity": parseFloat(this.state.quantity),
            "unitPrice": parseFloat(this.state.unitPrice)
        }
        axios.put(             
            '/api/v1/invoices/' + this.state.id_invoice + '/line', 
            bodyParameters,
            config
        )
        .then(response => {    
            this.setState({
                message:"Ligne ajoutée avec succès",
                isDid: true,
                addLine: false,
            });
            this.show(this.state.id_invoice)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        }); 
    }

    handleSubmitLineEdit(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        } 
        const bodyParameters = {
            "label": this.state.label,
            "quantity": parseFloat(this.state.quantity),
            "unitPrice": parseFloat(this.state.unitPrice)
        }
        axios.put(
            '/api/v1/invoices/' + this.state.id_invoice + '/line/' + this.state.lineId, 
            bodyParameters,
            config
        )
        .then(_ => {    
            this.setState({
                message:"Ligne modifiée avec succès",
                isDid: true,
                editLine: false,
            });
            this.show(this.state.id_invoice)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        }); 
    }

    download(){
        this.setState({loading: true })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token},
            responseType: 'blob'
        };
        axios.get('/api/v1/invoices/' + this.state.id_invoice + '/print', config)
        .then(response => {  
            this.setState({ 
                message:"Téléchargé avec succès", 
                isDid: true,
                loading: false
            });
            window.open(URL.createObjectURL(response.data));            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });

    }

    edit(id){
        this.setState({loading: true })  
        const item = this.state.invoices.filter(c => c.id === id)[0]
        this.setState({
            id_invoice: id,
            name: item.name,
            tva: item.tva,
            date: formatToInputDate(item.date),
            partner: item.partner,
            edit: true,
            loading: false           
        })        
    }

    editLine(id){
        this.setState({loading: true })  
        const item = this.state.lines.filter(c => c.id === id)[0]
        this.setState({
            lineId: id,
            label: item.label,
            quantity: item.quantity,
            unitPrice: item.unitPrice,
            editLine: true,
            loading: false           
        })        
    }

    deleteStatus(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/invoices/' + id,
            config
        ).then(_ => {     
            this.setState({message:"Statut changé avec succès", isDid: true });
            this.componentDidMount()
            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });  
        }); 
    }

    removeLine(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(
            '/api/v1/invoices/' + this.state.id_invoice + '/line/' + id,
            config
        ).then(_ => {     
            this.setState({
                message:"Ligne supprimée avec succès", 
                isDid: true, 
            });
            this.show(this.state.id_invoice)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    render() {

        const {
            invoices,
            lines,
            open,
            add,
            edit,
            addLine,
            editLine,
            name,
            partner,
            date,
            tva,
            status,
            search,
            label,
            quantity,
            unitPrice,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl,
            anchorEl1,
        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1
            },
            { 
                field: 'partner', 
                headerName: 'Partenaire',
                flex: 1,
            },
            { 
                field: 'tva', 
                headerName: 'TVA',
                flex: 1,
                renderCell: (cellValues) => {return `${cellValues.row.tva} %`}
            },
            { 
                field: 'date', 
                headerName: 'Date',
                maxWidth: 100,
                renderCell: (cellValues) => {return formatDate(cellValues.row.date)}
            },
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.deleteStatus(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton 
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.deleteStatus(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                            
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick3(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff", fontSize: 18}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl1[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl1[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl1: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{ fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem style={{ fontSize: 12, minHeight: 20}}
                                    disabled={!this.props.accesses.write} 
                                    onClick={() => { this.edit(cellValues.row.id) }}
                                >
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            },
        ]

        let DataInvoices=[]
        if(status == 'Tout'){
            DataInvoices = invoices
        }
        if(status == 'Actif'){
            DataInvoices = invoices.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            DataInvoices = invoices.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            DataInvoices = DataInvoices.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        const displayMont = () => {
            let resulat = 0
            lines.map((item) => {
                resulat = resulat + (item.unitPrice * item.quantity)
            })
            return resulat
        }

        const totalAmount = displayMont()

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.approve} onClick={() => this.setState({add: true, ...initialStats})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouvelle</Button>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={DataInvoices}
                                columns={column}
                                loading={loadingPage}
                                sortModel={[{
                                    field: 'name',
                                    sort: 'asc',
                                }]}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    boxShadow: 10,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une facture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="La designation de la facture"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Partenaire"
                                        name="partner"
                                        style={{ width: '100%' }}
                                        value={partner}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="TVA"
                                        type="number"
                                        name="tva"
                                        style={{ width: '100%' }}
                                        value={tva}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0,
                                            max: 100
                                        }}
                                    />
                                </Grid> 

                                <Grid item xs={12} md={6}>                                    
                                    <TextField
                                        required
                                        type="date"
                                        name="date"
                                        label="Date"
                                        style={{ width: '100%' }}
                                        value={date}
                                        onChange={this.handleChange} 
                                        size="small"
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button> 
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier une facture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEdit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="La designation de la facture"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Partenaire"
                                        name="partner"
                                        style={{ width: '100%' }}
                                        value={partner}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>  

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="TVA"
                                        type="number"
                                        name="tva"
                                        style={{ width: '100%' }}
                                        value={tva}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0,
                                            max: 100
                                        }}
                                    />
                                </Grid> 

                                <Grid item xs={12} md={6}>                                    
                                    <TextField
                                        required
                                        type="date"
                                        name="date"
                                        label="Date"
                                        style={{ width: '100%' }}
                                        value={date}
                                        onChange={this.handleChange} 
                                        size="small"
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button> 
                    </form>
                </Dialog> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32, paddingBottom:8}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Détails de la facture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({open: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <Box 
                                    style={{
                                        display: 'flex', 
                                        flexDirection:'row', 
                                        justifyContent:'flex-end'
                                    }}
                                >
                                    <Button 
                                        variant="contained"
                                        onClick={() => this.download()} 
                                        sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                                    >
                                        Télécharger la facture
                                    </Button>

                                    <Button 
                                        variant="contained" 
                                        disabled={!this.props.accesses.write}
                                        onClick={() => this.setState({addLine: true, ...initialStats})} 
                                        sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                                    >
                                        Ajouter une ligne
                                    </Button>
                                </Box >

                                <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Désignation</th>
                                            <th scope="col">Quantite</th>
                                            <th scope="col">Prix unitaire</th>
                                            <th scope="col">Montant</th>
                                            <th scope="col">Modification</th>                                        
                                            <th scope="col">Supression</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {lines.map((item) => {
                                            return(
                                                <tr>
                                                    <td>{item.label}</td>
                                                    <td>{item.quantity}</td>
                                                    <td>{item.unitPrice.toLocaleString()}</td>
                                                    <td>{(item.unitPrice * item.quantity).toLocaleString()}</td>   
                                                    <td>
                                                        <Button
                                                            disabled={!this.props.accesses.write}
                                                            onClick={() => { this.editLine(item.id) }}
                                                            variant="contained"
                                                            sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                        >
                                                            Modifier
                                                        </Button>
                                                    </td>

                                                    <td>
                                                        <Button
                                                            disabled={!this.props.accesses.remove}
                                                            onClick={() => { this.removeLine(item.id) }}
                                                            variant="contained"
                                                            sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                        >
                                                            Supprimer
                                                        </Button>
                                                    </td>                                                 
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <p style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end', paddingRight:700}} >
                                    Total HT: {totalAmount.toLocaleString()} <br />
                                    TVA: {tva} % <br />
                                    Total TTC: {(totalAmount - ((tva/100)*totalAmount)).toLocaleString()} <br />
                                </p>
                            </Grid>
                        </Grid>
                    </DialogContent>                
                </Dialog>                 

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addLine}
                    onClose={() => this.setState({addLine: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une ligne sur la facture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addLine: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitLine} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Désignation"
                                        name="label"
                                        style={{ width: '100%' }}
                                        value={label}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Quantité"
                                        name="quantity"
                                        style={{ width: '100%' }}
                                        value={quantity}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1
                                        }}
                                    />
                                </Grid> 

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Prix unitaire"
                                        name="unitPrice"
                                        style={{ width: '100%' }}
                                        value={unitPrice}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1
                                        }}
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>                

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editLine}
                    onClose={() => this.setState({editLine: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier une ligne sur la facture
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editLine: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitLineEdit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Désignation"
                                        name="label"
                                        style={{ width: '100%' }}
                                        value={label}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Quantité"
                                        name="quantity"
                                        style={{ width: '100%' }}
                                        value={quantity}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1
                                        }}
                                    />
                                </Grid> 

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Prix unitaire"
                                        name="unitPrice"
                                        style={{ width: '100%' }}
                                        value={unitPrice}
                                        onChange={this.handleChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1
                                        }}
                                    />
                                </Grid> 
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}