import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import StatusSelecter from '../../Components/StatusSelecter' 
import Search from "../../Components/Search"
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Dropzone from 'react-dropzone'
import DescriptionIcon from '@mui/icons-material/Description';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})
const initialStats = {
    name: "",
    account: "",
    balance: 0,
}
export default class Banks extends Component {

    constructor(props) {
        super(props)
        this.state = {
            banks: [],
            bank: {},
            transactions: [],
            partners: [],
            providers: [],
            projects: [],

            open: false,
            add: false,
            edit: false,            
            id_bank: null,
            ...initialStats,

            addJournal: false,
            editJStatus: false,
            transactionId: null,
            reference: "",
            number:"",
            label:"",
            start: new Date(),
            amount: 0,
            type: "Credit",
            paymentMethode: "Check",
            analyticCode: "",
            partnerId: null,
            providerId: null,
            selectedFile: [],
            option:"partnerId",
            statuss: "Processing",
            statusFilter: "",
            paymentMethodeFilter: "",
            typeModeP: "",

            begin: new Date(),
            end: new Date(),

            valuetab:"1",
            status: "Actif",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",

            anchorEl1: null,
            anchorEl: [],
            anchorElPeriode: null,
            anchorElStatut: null,
            anchorElModeP: null,
            anchorElTypeP: null
        }
        this.handleClick1 = this.handleClick1.bind(this)
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
        this.handleSelect1 = this.handleSelect1.bind(this)
        this.handleSubmitJournal = this.handleSubmitJournal.bind(this)
        this.handleSubmitJStatus = this.handleSubmitJStatus.bind(this)
        this.handleClickPeridoe = this.handleClickPeridoe.bind(this)
        this.handleSelectRageDate = this.handleSelectRageDate.bind(this)
        this.handleClickStatut = this.handleClickStatut.bind(this)
        this.handleClickModep = this.handleClickModep.bind(this)
        this.handleClickTypeModep = this.handleClickTypeModep.bind(this)
    }

    ChangeStatus(status){ this.setState({status: status}) }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    ChangeSearch(search){ this.setState({search: search}) }

    handleSelect1(date){ this.setState({start: date, anchorEl1: null}) }

    handleClickPeridoe(event){this.setState({anchorElPeriode: event.currentTarget})}

    handleClickStatut(event){this.setState({anchorElStatut: event.currentTarget})}

    handleClickModep(event){this.setState({anchorElModeP: event.currentTarget})}

    handleClickTypeModep(event){this.setState({anchorElTypeP: event.currentTarget})}

    handleSelectRageDate(ranges){
        if(ranges.selection.startDate === ranges.selection.endDate){
            ranges.selection.startDate = new Date(new Date(ranges.selection.startDate).setUTCHours(0,0,0,0))
        }
        this.setState(
            {
                begin: ranges.selection.startDate, 
                end:ranges.selection.endDate.setUTCHours(23,59,59,999),
                anchorElPeriode: null,
            }
        )
    }

    selectStatut(item){ this.setState({ statusFilter: item, anchorElStatut: null}) }

    selectModeP(item){ this.setState({ paymentMethodeFilter: item, anchorElModeP: null}) }

    selectTypeModeP(item){ this.setState({ typeModeP: item, anchorElTypeP: null}) }

    GetFilter() {
        this.setState({loading: true })    
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        let bodyParameters = {
            begin: this.state.begin,
            end: this.state.end,
        }

        bodyParameters = {
            begin: this.state.begin,
            end: this.state.end,
            status: this.state.statusFilter,
            paymentMethode: this.state.paymentMethodeFilter,
            type: this.state.typeModeP
        }

        axios.post( 
            '/api/v1/bank-logs/' + this.state.id_bank + '/filter',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ 
                message:"Transaction filtrée avec succès", 
                isDid: true, 
                transactions: res.data,
                loading: false 
        });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }  
    
    GetPrint() {
        this.setState({loading: true })    
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token},
            responseType: 'blob'
        }

        let bodyParameters = {
            begin: this.state.begin,
            end: this.state.end,
        }

        bodyParameters = {
            begin: this.state.begin,
            end: this.state.end,
            status: this.state.statusFilter,
            paymentMethode: this.state.paymentMethodeFilter,
            type: this.state.typeModeP
        }

        axios.post( 
            '/api/v1/bank-logs/' + this.state.id_bank + '/print',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ 
                message:"Imprimer avec succès", 
                isDid: true, 
                loading: false 
            });
            window.open(URL.createObjectURL(res.data)); 
        }).catch(error => {
            this.setState({ message:"Erreur dans le filtrer", isNotDit: true, loading: false });
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    } 

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Gestion des banques`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/banks/all', config)
        .then(response => {    
            axios.get('/api/v1/partners', config)            
            .then(res => {    
                axios.get('/api/v1/providers', config)
                .then(resp => {    
                    axios.get('/api/v1/projects/all', config)
                    .then(respon => {
                        this.setState({ 
                            projects: respon.data,
                            banks: response.data,
                            partners: res.data,
                            providers: resp.data,
                            loadingPage: false,
                            loading: false
                        });
                    }).catch(error => {
                        this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
                    });
                }).catch(error => {
                    this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
                });
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false }); 
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false }); 
        });   
    }

    handleSubmit(event) {
        event.preventDefault()      
        this.setState({loading: true })    
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const {name, account} = this.state

        const bodyParameters = {
            name: this.state.name ,
            account: account
        }

        axios.post( 
            '/api/v1/banks',
            bodyParameters,
            config
        ).then(res => {
            this.setState({ message:"Banque enregistrée avec succès", add: false, isDid: true, loading: false });
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleClick1(event){this.setState({anchorEl1: event.currentTarget})}

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const {name, account} = this.state
        const bodyParameters = {
            name: name,
            account: account
        }
        axios.put( 
            '/api/v1/banks/' + this.state.id_bank,
            bodyParameters,
            config
        ).then(res => {
            this.setState({ message:"Banque modifiée avec succès", isDid: true, edit:false, anchorEl: []});
            this.componentDidMount()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitJournal(){
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        let bodyParameters = new FormData()
        if(this.state.option === "partnerId"){
            bodyParameters.append("reference", this.state.reference)        
            bodyParameters.append("number", this.state.number)
            bodyParameters.append("label", this.state.label)
            bodyParameters.append("date", this.state.start)
            bodyParameters.append("amount", parseFloat(this.state.amount))
            bodyParameters.append("type", this.state.type)
            bodyParameters.append("paymentMethode", this.state.paymentMethode)
            bodyParameters.append("bankId", this.state.id_bank)
            bodyParameters.append("analyticCode", this.state.analyticCode)
            bodyParameters.append("partnerId", this.state.partnerId)
            this.state.selectedFile.forEach (d => {
                bodyParameters.append('documents', d)            
            })
        }else{
            bodyParameters.append("reference", this.state.reference)        
            bodyParameters.append("number", this.state.number)
            bodyParameters.append("label", this.state.label)
            bodyParameters.append("date", this.state.start)
            bodyParameters.append("amount", parseFloat(this.state.amount))
            bodyParameters.append("type", this.state.type)
            bodyParameters.append("paymentMethode", this.state.paymentMethode)
            bodyParameters.append("bankId", this.state.id_bank)
            bodyParameters.append("analyticCode", this.state.analyticCode)
            bodyParameters.append("providerId", this.state.providerId)
            bodyParameters.append("documents", this.state.selectedFile)
            this.state.selectedFile.forEach (d => {
                bodyParameters.append('documents', d)            
            })
        }
        axios.post(
            '/api/v1/bank-logs', 
            bodyParameters,
            config
        )
        .then(response => {     
            this.setState({
                message:"Journal ajouté avec succès",
                isDid: true,
                anchorEl: [],
                addJournal: false,
            });
            this.show(this.state.id_bank)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    fileDrop(acceptedFiles){ 
        this.setState({
            selectedFile: acceptedFiles,
        })        
    }

    handleSubmitJStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.statuss
        }
        axios.post(
            '/api/v1/bank-logs/' + this.state.transactionId , 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut changé avec succès",
                isDid: true, 
                editJStatus:false,
            });
            this.show(this.state.id_bank)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    show(id){
        this.setState({loading: true })  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/banks/' + id, config)
        .then(response => {  
            this.setState({ 
                id_bank: id,
                bank: {
                    name: response.data.name,
                    transactions: response.data.transactions,
                },
                loading: false,
                open: true
            })            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });       
    }

    editJStatus(id){
        this.setState({loading: true })  
        const transactions = this.state.bank.transactions
        const item = transactions?.filter(c => c["id"] === id)[0]
        if(item){
            this.setState({
                transactionId: id,
                statuss: item.status,            
                loading: false,
                editJStatus: true
            })
        }
    }

    edit(id){
        this.setState({loading: true })  
        const item = this.state.banks.filter(c => c["id"] === id)[0]
        this.setState({
            id_bank: id,
            name: item.name,
            account: item.account,
            loading: false,
            edit: true
        })
    }

    delete(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/banks/' + id,
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true, anchorEl: []});
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        }); 
    }

    render() {

        const {
            banks,
            bank,
            partners,
            providers,
            projects,
            anchorElPeriode,
            statusFilter,
            paymentMethodeFilter,
            anchorElModeP,
            typeModeP,
            anchorElTypeP,
            open,
            add,
            edit,
            name,
            account,
            status,
            search,
            addJournal,
            editJStatus,
            reference,
            number,
            label,
            start,
            amount,
            anchorElStatut,
            type,
            paymentMethode,
            analyticCode,
            partnerId,
            providerId,
            selectedFile,
            option,
            statuss,
            begin,
            end,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl,
            anchorEl1
        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1/2
            },
            { 
                field: 'account', 
                headerName: "Compte",
                flex: 1/2
            },
            { 
                field: 'balance', 
                headerName: "Montant",
                flex: 1/2,
                renderCell: (cellValues) => {return cellValues.row.balance.toLocaleString()}
            },
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton 
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.delete(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton 
                                    disabled={!this.props.accesses.approve}
                                    onClick={() => this.delete(cellValues.row.id)}
                                >   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                            
                        )
                    }                                       
                }
            },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{ fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem  
                                    disabled={!this.props.accesses.write}
                                    onClick={() => { this.edit(cellValues.row.id) }}
                                    style={{ fontSize: 12, minHeight: 20}}
                                >
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            },
        ]

        let Databanks=[]
        if(status == 'Tout'){
            Databanks = banks
        }
        if(status == 'Actif'){
            Databanks = banks.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            Databanks = banks.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            Databanks = banks.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        const selectionRange = {
            startDate: begin,
            endDate: end,
            key: 'selection',
        }

        const displayProjet = (id)  => {
            let resultat = "Pas de projet"
            if(id !== null){
                resultat = projects.filter(c => c["id"] === id)[0].name
            }
            return resultat
        }
        
        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({add: true, ...initialStats})} sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Nouvelle</Button>
                    </Grid> 

                    <Grid item xs={6} sm={6} md={4} >     
                        <Box sx={{display: 'flex', justifyContent:"flex-end", }}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={Databanks}
                                columns={column}
                                loading={loadingPage}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    boxShadow: 10,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter une banque
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de la banque"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Numero du compte"
                                        name="account"
                                        style={{ width: '100%' }}
                                        value={account}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullScreen                    
                    TransitionComponent={Transition}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <AppBar sx={{ position: 'relative', background: '#fff' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1, color:"#313339" }} variant="h6" component="div">
                                Journal de banque
                            </Typography>

                            <IconButton onClick={() =>  this.setState({open: false })} >   
                                <CloseIcon /> 
                            </IconButton>
                        </Toolbar>
                    </AppBar>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Box 
                                    style={{
                                        display: 'flex', 
                                        flexDirection:'row', 
                                        justifyContent:'flex-end'
                                    }}
                                >  
                                    <Card elevation={1}  sx={{height:45, mr:2, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                                        <Typography sx={{marginRight: 4, fontSize: 12}}>Type: <span style={{color:'#306bff'}}>                                        
                                            {typeModeP === "" && "Tout"}
                                            {typeModeP === "Credit" && "Crédit"}
                                            {typeModeP === "Debit" && "Débit"}                                            
                                            {typeModeP === "Paiement électronique" && "Paiement électronique"} 
                                        </span></Typography>
                                    
                                        <Box sx={{ flexGrow: 1 }} />

                                        <IconButton onClick={this.handleClickTypeModep}>
                                            <ExpandMoreIcon sx={{color:'#306bff', fontSize: 18}}/>
                                        </IconButton>

                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorElTypeP}
                                            open={Boolean(anchorElTypeP)}
                                            onClose={() => {this.setState({ anchorElTypeP: null})}}
                                            MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 2,
                                                    ml: -2
                                                }
                                            }}
                                        >
                                            <MenuItem 
                                                onClick={() => this.selectTypeModeP("")} 
                                                sx={typeModeP === "" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Tout
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectTypeModeP("Credit")} 
                                                sx={typeModeP === "Credit" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Crédit
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectTypeModeP("Debit")} 
                                                sx={typeModeP === "Debit" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Débit
                                            </MenuItem>
                                        </Menu>
                                    </Card>

                                    <Card elevation={1}  sx={{height:45, mr:2, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                                        <Typography sx={{marginRight: 4, fontSize: 12}}>Mode de paiement: <span style={{color:'#306bff'}}>                                        
                                            {paymentMethodeFilter === "" && "Tout"}
                                            {paymentMethodeFilter === "Check" && "Check"}
                                            {paymentMethodeFilter === "Cash" && "Espèces"}                                            
                                            {paymentMethodeFilter === "Paiement électronique" && "Paiement électronique"}                                            
                                            {paymentMethodeFilter === "Paiement mobile" && "Paiement mobile"}
                                            {paymentMethodeFilter === "Ordre de transfert" && "Ordre de transfert"}
                                            {paymentMethodeFilter === "Autre" && "Autre"}
                                        </span></Typography>
                                    
                                        <Box sx={{ flexGrow: 1 }} />

                                        <IconButton onClick={this.handleClickModep}>
                                            <ExpandMoreIcon sx={{color:'#306bff', fontSize: 18}}/>
                                        </IconButton>

                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorElModeP}
                                            open={Boolean(anchorElModeP)}
                                            onClose={() => {this.setState({ anchorElModeP: null})}}
                                            MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 2,
                                                    ml: -2
                                                }
                                            }}
                                        >
                                            <MenuItem 
                                                onClick={() => this.selectModeP("")} 
                                                sx={paymentMethodeFilter === "" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Tout
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectModeP("Check")} 
                                                sx={paymentMethodeFilter === "Check" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Check
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectModeP("Cash")} 
                                                sx={paymentMethodeFilter === "Cash" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Espèces
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectModeP("Paiement électronique")} 
                                                sx={paymentMethodeFilter === "Paiement électronique" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Paiement électronique
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectModeP("Paiement mobile")} 
                                                sx={paymentMethodeFilter === "Paiement mobile" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Paiement mobile
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectModeP("Ordre de transfert")} 
                                                sx={paymentMethodeFilter === "Ordre de transfert" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Ordre de transfert
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectModeP("Autre")} 
                                                sx={paymentMethodeFilter === "Autre" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Autre
                                            </MenuItem>
                                        </Menu>
                                    </Card>

                                    <Card elevation={1}  sx={{height:45, mr:2, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                                        <Typography sx={{marginRight: 4, fontSize: 12}}>Statut: <span style={{color:'#306bff'}}>                                        
                                            {statusFilter === "" && "Tout"}
                                            {statusFilter === "Processing" && "Traitement"}
                                            {statusFilter === "Approved" && "Approuvé"}
                                            {statusFilter === "Disaproved" && "Refusé"}
                                        </span></Typography>
                                    
                                        <Box sx={{ flexGrow: 1 }} />

                                        <IconButton onClick={this.handleClickStatut}>
                                            <ExpandMoreIcon sx={{color:'#306bff', fontSize: 18}}/>
                                        </IconButton>

                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorElStatut}
                                            open={Boolean(anchorElStatut)}
                                            onClose={() => {this.setState({ anchorElStatut: null})}}
                                            MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                            }}
                                            PaperProps={{
                                                sx: {
                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                    mt: 2,
                                                    ml: -2
                                                }
                                            }}
                                        >
                                            <MenuItem 
                                                onClick={() => this.selectStatut("")} 
                                                sx={statusFilter === "" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Tout
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectStatut("Processing")} 
                                                sx={statusFilter === "Processing" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Traitement
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectStatut("Approved")} 
                                                sx={statusFilter === "Approved" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Approuvé
                                            </MenuItem>

                                            <MenuItem 
                                                onClick={() => this.selectStatut("Disaproved")} 
                                                sx={statusFilter === "Disaproved" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                            >
                                                Refusé
                                            </MenuItem>
                                        </Menu>
                                    </Card>

                                    <Card elevation={1}  sx={{height:45, mr:2, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                                        <CalendarTodayIcon  sx={{color:"#2f4cdd", fontSize: 18 }} />

                                        <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'left', }} >
                                            <Typography sx={{color:'#46505b', fontSize: 12, pl:1}}>Période</Typography>
                                            <Typography sx={{color:'gray', fontSize: 12, pl:1}}>Du {new Date(begin).toLocaleDateString()} - au {new Date(end).toLocaleDateString()}</Typography>
                                        </Box>

                                        <Box sx={{ flexGrow: 1 }} />

                                        <IconButton onClick={this.handleClickPeridoe} >
                                            <ExpandMoreIcon style={{ fontSize: 18}} />
                                        </IconButton>

                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorElPeriode}
                                            open={Boolean(anchorElPeriode)}
                                            onClose={() => {this.setState({ anchorElPeriode: null})}}
                                            PaperProps={{
                                                sx: {
                                                    mt:4,
                                                    ml: '-10px',
                                                }
                                            }}
                                        >
                                            <DateRangePicker
                                                ranges={[selectionRange]}
                                                onChange={this.handleSelectRageDate}
                                                direction="horizontal"
                                                preventSnapRefocus={true}
                                                moveRangeOnFirstSelection={false}
                                                months={2}
                                                scroll = {{enabled: true}}
                                                locale={locales['fr']}
                                            />
                                        </Menu>
                                    </Card>

                                    <Button variant="contained" onClick={() => this.GetFilter()} sx={{mr:2,  fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Filtrer</Button>

                                    <Button variant="contained" onClick={() => this.GetPrint()} sx={{mr:2,  fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Imprimer</Button>
                               
                                    <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({addJournal: true})} sx={{mr:2, height:45, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}>Ajouter une opération</Button>
                                </Box>
                            </Grid>                            

                            <Grid item xs={12} sm={12} md={12}>
                                <table className="table table-hover" style={{ fontSize: 12 }}>
                                    <thead>
                                        <tr>
                                            <th scope="col">Banque</th>
                                            <th scope="col">Projet</th>
                                            <th scope="col">Référence</th>
                                            <th scope="col">N° Enr.</th>
                                            <th scope="col">Facture</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Montant</th>
                                            <th scope="col">C. anlytique</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">M. reglement</th>
                                            <th scope="col">Partenaire</th>
                                            <th scope="col">Fournisseur</th>
                                            <th scope="col">Documents</th>
                                            <th scope="col">Statut</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {bank.transactions?.length > 0 &&
                                            bank.transactions.map((item) => {
                                                return(
                                                    <tr>
                                                        <td>{bank.name}</td>  
                                                        <td>{displayProjet(item.projectId)}</td>                                                      
                                                        <td>{item.reference}</td>
                                                        <td>{item.number}</td> 
                                                        <td>{item.label}</td> 
                                                        <td>{new Date(item.date).toLocaleDateString()}</td> 
                                                        <td>{item.amount.toLocaleString()}</td>
                                                        <td>{item.analyticCode}</td>
                                                        <td>{item.type}</td>
                                                        <td>
                                                            {item.paymentMethode === "Check" && "Chèque"}
                                                            {item.paymentMethode === "Cash" && "Espèces"}
                                                            {item.paymentMethode === "ElectronicPayment" && "Paiement électronique"}
                                                            {item.paymentMethode === "MobilePayment" && "Paiement mobile"}
                                                            {item.paymentMethode === "TransferOrder" && "Ordre de virement"}
                                                            {item.paymentMethode === "Autre" && "Autre"}
                                                        </td>
                                                        <td>{item.partnerId !== null && item.partner.name}</td>
                                                        <td>{item.providerId !== null && item.provider.name}</td>
                                                        <td>
                                                            {item.documents.length > 0 &&
                                                                item.documents.map((elem) => {
                                                                    return(
                                                                        <Link color="inherit" href={elem.file} target={"_blank"} style={{textDecoration: 'none', }}>
                                                                            <VisibilityIcon
                                                                                sx={{color:'#306bff', mr:2}}
                                                                            />
                                                                        </Link>

                                                                    )
                                                                })                                                        
                                                            }
                                                        </td>

                                                        <td
                                                            onClick={!this.props.accesses.approve ? null : () => this.editJStatus(item.id)}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {item.status === "Processing" && "Traitement"}
                                                            {item.status === "Approved" && "Approuvé"}
                                                            {item.status === "Refusé" && "Refusé"}
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Grid>                            
                        </Grid>
                    </DialogContent >
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={edit}
                    onClose={() => this.setState({edit: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier une Banque
                        </Typography>

                        <IconButton onClick={() =>  this.setState({edit: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEdit} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de la banque"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        abel="Numero du compte"
                                        name="account"
                                        style={{ width: '100%' }}
                                        value={account}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour modifier
                        </Button>   
                    </form>
                </Dialog>
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addJournal}
                    onClose={() => this.setState({addJournal: false})}
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Saisir l'opération
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addJournal: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Référence"
                                    name="reference"
                                    style={{ width: '100%' }}
                                    value={reference}
                                    onChange={this.handleChange}
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Numero d'enregistrment"
                                    name="number"
                                    style={{ width: '100%' }}
                                    value={number}
                                    onChange={this.handleChange}
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Libelle de la transaction"
                                    name="label"
                                    style={{ width: '100%' }}
                                    value={label}
                                    onChange={this.handleChange}
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={6}>                                    
                                <TextField
                                    id="outlined-required"
                                    label="Date"
                                    style={{ width: '100%' }}
                                    value={displaydate(start)}
                                    onClick={this.handleClick1} 
                                    size="small"
                                />
                            </Grid> 

                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    type="number"
                                    id="outlined-required"
                                    label="Montant"
                                    name="amount"
                                    style={{ width: '100%' }}
                                    value={amount}
                                    onChange={this.handleChange}
                                    size="small"
                                    inputProps={{
                                        maxLength: 13,
                                        step: 0.5
                                    }}
                                />
                            </Grid> 

                            <Grid item xs={6}>
                                <TextField
                                    required
                                    select
                                    label="Type"
                                    name="type"
                                    style={{ width: '100%' }}
                                    value={type}
                                    onChange={this.handleChange}
                                    size="small"
                                >
                                    <MenuItem key="Credit" value="Credit">Credit</MenuItem>
                                    <MenuItem key="Debit" value="Debit">Debit</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    required
                                    select
                                    label="Methode de reglement"
                                    name="paymentMethode"
                                    style={{ width: '100%' }}
                                    value={paymentMethode}
                                    onChange={this.handleChange}
                                    size="small"
                                >
                                    <MenuItem key="Check" value="Check">Chèque</MenuItem>
                                    <MenuItem key="Cash" value="Cash">Espèces</MenuItem>
                                    <MenuItem key="ElectronicPayment" value="ElectronicPayment">Paiement électronique</MenuItem>
                                    <MenuItem key="Paiement mobile" value="MobilePayment">Paiement mobile</MenuItem>
                                    <MenuItem key="Ordre de transfert" value="TransferOrder">Ordre de virement</MenuItem>
                                    <MenuItem key="Autre" value="Other">Autre</MenuItem>
                                </TextField>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <TextField
                                    id="outlined-required"
                                    label="Code analytique"
                                    name="analyticCode"
                                    style={{ width: '100%' }}
                                    value={analyticCode}
                                    onChange={this.handleChange}
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    select
                                    label="Option"
                                    name="option"
                                    style={{ width: '100%' }}
                                    value={option}
                                    onChange={this.handleChange}
                                    size="small"
                                >
                                    <MenuItem key="partnerId" value="partnerId">Partenaire</MenuItem>
                                    <MenuItem key="providerId" value="providerId">Fournisseur</MenuItem>
                                </TextField>
                            </Grid>

                            {option === "partnerId" ?
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        label="Partenaire"
                                        name="partnerId"
                                        style={{ width: '100%' }}
                                        value={partnerId}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        {
                                            partners.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>{answer.name}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            :
                                <Grid item xs={6}>
                                    <TextField
                                        select
                                        label="Fournissuer"
                                        name="providerId"
                                        style={{ width: '100%' }}
                                        value={providerId}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        {
                                            providers.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>{answer.name}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            }

                            <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                <Dropzone onDrop={acceptedFiles  => this.fileDrop(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />

                                                {selectedFile.length === 0 ? 
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                        {selectedFile.length > 0 &&
                                                            selectedFile.map((item) => {
                                                                return(
                                                                    <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                        {item.path}
                                                                    </Typography>
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>                                
                        </Grid>
                    </DialogContent >

                    <DialogActions>
                        <Button  
                            onClick={() => this.handleSubmitJournal()}
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button>    
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editJStatus}
                    onClose={() => this.setState({editJStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editJStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitJStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut"
                                        name="statuss"
                                        style={{ width: '100%' }}
                                        value={statuss}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorEl1}
                    open={Boolean(anchorEl1)}
                    onClose={() => {this.setState({ anchorEl1: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={start}
                        onChange={this.handleSelect1}
                        locale={locales['fr']}
                    />
                </Menu>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}