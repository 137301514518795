import React, { Component } from "react";
import TextField from '@mui/material/TextField';

export default class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            search: "",
        }
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(
            { 
                [name]: value 
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(value)
                }
            }  
        );
    }

    render() { 

        const {search} = this.state

        return(
            <React.Fragment>
                <TextField 
                    id="standard-basic" 
                    label="Rechercher" 
                    variant="standard" 
                    size="small"
                    name="search"
                    placeholder="Mot-clé"
                    style={{ width: '100%', fontSize: 12}}
                    value={search}
                    onChange={this.handleChange}
                    inputProps={{
                        style: { fontSize: 12}
                    }}
                />   
            </React.Fragment>
        )
    }

}