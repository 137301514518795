import React, { Component } from "react";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default class Logs extends Component {

    constructor(props) {
        super(props)
        this.state = {
            logs: [],

            action:'Create',

            begin: new Date(),
            end: new Date(),

            // begin: (new Date()).setUTCHours(0,0,0,0),
            // end: (new Date()).setUTCHours(23,59,59,999),

            anchorEl: null,
            anchorElAction: null,

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",
        }
        this.handleClick = this.handleClick.bind(this)
        this.handleSelect = this.handleSelect.bind(this)
        this.handleClickAction = this.handleClickAction.bind(this)
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Traces des activites des utilisateurs`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/logs', config)
        .then(response => {     
            this.setState({ 
                logs: response.data,
                loadingPage: false, loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        });   
    }

    handleClick(event){this.setState({anchorEl: event.currentTarget})}

    handleClickAction(event){this.setState({anchorElAction: event.currentTarget})}

    handleSelect(ranges){
        if(ranges.selection.startDate === ranges.selection.endDate){
            ranges.selection.startDate = new Date(new Date(ranges.selection.startDate).setUTCHours(0,0,0,0))
        }
        this.setState(
            {
                begin: ranges.selection.startDate, 
                end:ranges.selection.endDate.setUTCHours(23,59,59,999),
                anchorEl: null,
            },
            () => this.GetFilter(this.state.action, ranges.selection.startDate, ranges.selection.endDate)
        )
    }

    GetFilter(action, begin, end){
        this.setState({loading: true})   
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/logs?action=' + action + '&begin=' + begin + '&end=' + end, config)
        .then(response => {       
            this.setState({ 
                logs: response.data,
                loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });  
    }

    selectAction(action){
        this.setState(
            {
                action:  action,
                anchorElAction: null
            },
            () => this.GetFilter(action, this.state.begin, this.state.end)      
        )
    }

    render(){

        const {
            logs,
            action,
            begin,
            end,
            anchorEl,
            anchorElAction,
            loading,
            loadingPage,
            isDid,
            isNotDit,
            message
        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Nom',
                flex: 1,
                renderCell: (cellValues) => {return  cellValues.row.executeBy.name}
            },
            { 
                field: 'email', 
                headerName: 'Email',
                flex: 1,
                renderCell: (cellValues) => {return  cellValues.row.executeBy.email}
            },
            { 
                field: 'action', 
                headerName: 'Action',
                flex: 1/2,
                renderCell: (cellValues) => {
                    if(cellValues.row.action === "Create"){
                        return  "Ajout"
                    }
                    else if(cellValues.row.action === "Update"){
                        return  "Modification"
                    }
                    else if(cellValues.row.action === "Delete"){
                        return  "Archivage / Suppression"
                    }
                    else if(cellValues.row.action === "Approval"){
                        return  "Approbation"
                    }
                    else if(cellValues.row.action === "EnableDesable"){
                        return  "Activer / Désactiver"
                    }
                    else if(cellValues.row.action === "Print"){
                        return  "Imprimer"
                    }
                    else if(cellValues.row.action === "Auth"){
                        return  "Authentification"
                    }
                    else if(cellValues.row.action === "ResetPassword"){
                        return  "Réinitialiser le mot de passe"
                    }
                    else{
                        return cellValues.row.action
                    }
                }
            },
            { 
                field: 'description', 
                headerName: 'Description',
                flex: 2,
            },
            { 
                field: 'date', 
                headerName: 'Date',
                flex: 1/2,
                renderCell: (cellValues) => {return  new Date(cellValues.row.date).toLocaleString()}
            },
        ]

        const selectionRange = {
            startDate: begin,
            endDate: end,
            key: 'selection',
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >    
                    <Grid item xs={12} sm={6} md={3} >
                        <Card elevation={0}  sx={{height:70, p:2, mr:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center'}}>
                            <Typography sx={{marginRight: 4, fontSize: 12}}>Action: <span style={{color:'#306bff'}}>{action}</span></Typography>
                        
                            <Box sx={{ flexGrow: 1 }} />

                            <IconButton onClick={this.handleClickAction}>
                                <ExpandMoreIcon sx={{color:'#306bff'}}/>
                            </IconButton>

                            <Menu
                                id="basic-menu"
                                anchorEl={anchorElAction}
                                open={Boolean(anchorElAction)}
                                onClose={() => {this.setState({ anchorElAction: null})}}
                                MenuListProps={{'aria-labelledby': 'basic-button', }}
                                PaperProps={{
                                    sx: {
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 2,
                                        ml: -2
                                    }
                                }}
                            >
                                <MenuItem 
                                    onClick={() => this.selectAction("Create")} 
                                    sx={action === "Create" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Ajout
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("Update")} 
                                    sx={action === "Update" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Modification
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("Delete")} 
                                    sx={action === "Delete" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Archivage / Suppression
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("Approval")} 
                                    sx={action === "Approval" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Approbation
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("EnableDesable")} 
                                    sx={action === "EnableDesable" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Activer / Désactiver
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("Print")} 
                                    sx={action === "Print" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Imprimer
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("Auth")} 
                                    sx={action === "Auth" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Authentification
                                </MenuItem>

                                <MenuItem 
                                    onClick={() => this.selectAction("ResetPassword")} 
                                    sx={action === "ResetPassword" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                >
                                    Réinitialiser le mot de passe
                                </MenuItem>
                            </Menu>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                            <Card elevation={0}  sx={{height:70, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                                <CalendarTodayIcon  sx={{color:"#2f4cdd", fontSize: 18 }} />

                                <Box sx={{display: 'flex', flexDirection:'column', alignItems: 'left', }} >
                                    <Typography sx={{color:'#46505b', pl:1, fontSize: 12}}>Période</Typography>
                                    <Typography sx={{color:'gray', pl:1, fontSize:12}}>Du {new Date(begin).toLocaleDateString()} - au {new Date(end).toLocaleDateString()}</Typography>
                                </Box>

                                <Box sx={{ flexGrow: 1 }} />

                                <IconButton onClick={this.handleClick} >
                                    <ExpandMoreIcon style={{fontSize: 12}} />
                                </IconButton>

                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => {this.setState({ anchorEl: null})}}
                                    PaperProps={{
                                        sx: {
                                            mt:4,
                                            ml: '-10px',
                                        }
                                    }}
                                >
                                    <DateRangePicker
                                        ranges={[selectionRange]}
                                        onChange={this.handleSelect}
                                        direction="horizontal"
                                        preventSnapRefocus={true}
                                        moveRangeOnFirstSelection={false}
                                        months={2}
                                        scroll = {{enabled: true}}
                                        locale={locales['fr']}
                                    />
                                </Menu>
                            </Card>
                    </Grid>                

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={logs}
                                columns={column}
                                loading={loadingPage}
                                sortModel={[{
                                    field: 'date',
                                    sort: 'desc',
                                }]}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}