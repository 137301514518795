import React, { Component } from "react";
import Button from '@mui/material/Button';
import Dropzone from 'react-dropzone'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Checkbox from '@mui/material/Checkbox';
import DescriptionIcon from '@mui/icons-material/Description';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import Search from "../../Components/Search"
import { Link } from "@mui/material";
import EventSwitch from "../../Components/EventSwitch";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const defaultExternalParticipant = {
    externalParticipantDialog: false,
    externalParticipantId: null,
    externalParticipantName: '',
    externalParticipantEmail: '',
    externalParticipantCompany: '',
}

const defaultGalerie = {
    selectedGalerieFiles: [],
    galerieDialog: false
}

const defaultReport = {
    selectedReportFile: [],
    reportDialog: false,
    reportDocumentName: ''
}

const defaultEvent = {
    name: "",
    type: "",
    place: "",
    begin: new Date(),
    end: new Date(),
    hour: '',
    description: "",
}

export default class Events extends Component {

    constructor(props) {
        super(props)
        this.state = {
            events: [],
            participants: [],
            externalParticipants: [],
            reports: [],
            galeries: [],
            employees: [],

            add: false,
            edit: false,
            open: false,
            addPar: false,
            editPar: false,

            ...defaultExternalParticipant,
            ...defaultGalerie,
            ...defaultReport,

            id_event: null,
            participantId: null,
            OldparticipantId: null,

            ...defaultEvent,
            participantselect: [],

            valuetab: "1",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message: "",
            anchorEl: [],
        }
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleSubmitEdit = this.handleSubmitEdit.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleChangeAcces = this.handleChangeAcces.bind(this)
        this.handleSubmitPar = this.handleSubmitPar.bind(this)
        this.handleSubmitEditPar = this.handleSubmitEditPar.bind(this)

        this.handleSubmitExternalParticipant = this.handleSubmitExternalParticipant.bind(this)
        this.handleSubmitEditExternalParticipant = this.handleSubmitEditExternalParticipant.bind(this)
        this.handleSubmitReportFiles = this.handleSubmitReportFiles.bind(this)
        this.handleSubmitGaleryFiles = this.handleSubmitGaleryFiles.bind(this)
    }

    fetchEvent(status="Tout") {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        const endpoint = status === "Tout" ? "/api/v1/events/all" : "/api/v1/events"
        axios.get(endpoint, config)
            .then(response => {
                this.setState({
                    events: response.data,
                    loadingPage: false, loading: false
                });
            }).catch(error => {
                this.setState({ loadingPage: false })
            });
    }

    fetchEmployee() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.get('/api/v1/employees', config)
            .then(response => {
                this.setState({
                    employees: response.data,
                });
            }).catch(_ => {
                // this.setState({loading: false }) 
            });
    }

    componentDidMount() {
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Gestion des Événements`
        this.fetchEvent()
        this.fetchEmployee()  
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    ChangeSearch(search) { this.setState({ search: search }) }

    handleChangeAcces(event) {
        const { target: { value }, } = event;
        this.setState({ participantselect: typeof value === 'string' ? value.split(',') : value });
    }

    handleClick(id, event) {
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }


    handleChangetab(_, newValue) { this.setState({ valuetab: newValue }) }

    handleSubmit(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }
        const bodyParameters = {
            name: this.state.name,
            begin: this.state.begin,
            end: this.state.end,
            hour: this.state.hour,
            place: this.state.place,
            type: this.state.type,
            description: this.state.description,
        }
        axios.post(
            '/api/v1/events',
            bodyParameters,
            config
        ).then(_ => {
            this.fetchEvent()
            this.setState({ message: "Événement enregistré avec succès", ...defaultEvent, add: false, isDid: true });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur lors de l'enregistrement", isNotDit: true, loading: false });
        });
    }

    handleSubmitEdit(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }
        const bodyParameters = {
            name: this.state.name,
            begin: this.state.begin,
            end: this.state.end,
            hour: this.state.hour,
            place: this.state.place,
            type: this.state.type,
            description: this.state.description,
        }
        axios.put(
            '/api/v1/events/' + this.state.id_event,
            bodyParameters,
            config
        ).then(_ => {
            this.setState({
                message: "Événement changé avec succès",
                isDid: true,
                edit: false,
                anchorEl: [],
                loading: false,
                ...defaultEvent
            });
            this.fetchEvent()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur dans le modification", isNotDit: true, loading: false });
        });
    }

    handleSubmitPar(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }

        let participants = []
        this.state.participantselect.map((elem) => {
            const b = { id: elem }
            participants = participants.concat(b)
        })

        const bodyParameters = {
            "participants": participants,
        }
        axios.put(
            '/api/v1/events/' + this.state.id_event + '/participant',
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                message: "participant(s) externe ajouté avec succès", isDid: true, anchorEl: [],
                participants: response.data.participants,
                participantselect: [],
                loading: false,
                addPar: false,
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur d'ajout", isNotDit: true, loading: false });
        });
    }

    handleSubmitEditPar(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }

        const bodyParameters = {
            "participant": this.state.participantId,
        }
        axios.put(
            '/api/v1/events/' + this.state.id_event + '/participant/' + this.state.OldparticipantId,
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                message: "participant(e) modifié avec succès", isDid: true, anchorEl: [],
                participants: response.data.participants,
                participantselect: [],
                loading: false,
                editPar: false,
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de modification", isNotDit: true, loading: false });
        });
    }


    handleSubmitExternalParticipant(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }
        const bodyParameters = {
            name: this.state.externalParticipantName,
            email: this.state.externalParticipantEmail,
            organization: this.state.externalParticipantCompany,
        }
        console.log()
        axios.put(
            '/api/v1/events/' + this.state.id_event + '/external-participant',
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                message: "Participant externe ajouté avec succès", isDid: true, anchorEl: [],
                externalParticipants: response.data.externalParticipants,
                loading: false,
                ...defaultExternalParticipant
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur d'ajout", isNotDit: true, loading: false });
        });
    }

    handleSubmitEditExternalParticipant(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }

        const bodyParameters = {
            name: this.state.externalParticipantName,
            email: this.state.externalParticipantEmail,
            organization: this.state.externalParticipantCompany,
        }

        axios.put(
            '/api/v1/events/' + this.state.id_event + '/external-participant/' + this.state.externalParticipantId,
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                message: "Participant externe modifié avec succès", isDid: true, anchorEl: [],
                externalParticipants: response.data.externalParticipants,
                loading: false,
                ...defaultExternalParticipant
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de modification", isNotDit: true, loading: false });
        });
    }

    handleSubmitGaleryFiles(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }

        const bodyParameters = new FormData();
        this.state.selectedGalerieFiles.forEach(doc => {
            bodyParameters.append('documents', doc)
        })

        axios.put(
            '/api/v1/events/' + this.state.id_event + '/galery',
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                message: "Participant externe modifié avec succès", isDid: true, anchorEl: [],
                galeries: response.data.galeries,
                loading: false,
                ...defaultGalerie
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de modification", isNotDit: true, loading: false });
        });
    }

    handleSubmitReportFiles(event) {
        event.preventDefault()
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        }

        const bodyParameters = new FormData();
        bodyParameters.append('name', this.state.reportDocumentName)
        this.state.selectedReportFile.forEach(doc => {
            bodyParameters.append('documents', doc)
        })

        axios.put(
            '/api/v1/events/' + this.state.id_event + '/report',
            bodyParameters,
            config
        ).then(response => {
            this.setState({
                message: "Participant externe modifié avec succès", isDid: true, anchorEl: [],
                reports: response.data.reports,
                loading: false,
                ...defaultReport
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de modification", isNotDit: true, loading: false });
        });
    }

    show(id) {
        this.setState({ loading: true })
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.get('/api/v1/events/' + id, config)
            .then(response => {
                this.setState({
                    id_event: id,
                    name: response.data.name,
                    begin: response.data.begin,
                    end: response.data.end,
                    hour: response.data.hour,
                    place: response.data.place,
                    type: response.data.type,
                    description: response.data.description,
                    participants: response.data.participants,
                    externalParticipants: response.data.externalParticipants,
                    reports: response.data.reports,
                    galeries: response.data.galeries,
                    loading: false,
                    open: true
                });
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });
    }

    edit(id) {
        this.setState({ loading: true })
        const item = this.state.events.filter(c => c["id"] === id)[0]
        this.setState({
            id_event: id,
            name: item.name,
            type: item.type,
            place: item.place,
            begin: new Date(item.begin),
            end: new Date(item.end),
            hour: item.hour,
            description: item.description,
            loading: false,
            edit: true
        })
    }

    delete(id) {
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.delete(
            '/api/v1/events/' + id,
            config
        ).then(_ => {
            this.setState({ message: "Événement supprimé avec succès", isDid: true });
            this.fetchEvent()
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de suppression", loading: false, isNotDit: true });
        });
    }

    editPar(id) {
        this.setState({ loading: true })
        this.setState({
            participantId: id,
            OldparticipantId: id,
            loading: false,
            editPar: true
        })
    }

    removePar(id) {
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.delete(
            '/api/v1/events/' + this.state.id_event + '/participant/' + id,
            config
        ).then(response => {
            this.setState({
                message: "Participant enlevé avec succès", isDid: true, anchorEl: [],
                participants: response.data.participants,
                loading: false,
            });

        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de suppression", isNotDit: true, loading: false });
        });
    }

    removeExternalParticipant(id) {
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.delete(
            '/api/v1/events/' + this.state.id_event + '/external-participant/' + id,
            config
        ).then(response => {
            this.setState({
                message: "Participant externe enlevé avec succès", isDid: true, anchorEl: [],
                externalParticipants: response.data.externalParticipants,
                loading: false,
            });

        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de suppression", isNotDit: true, loading: false });
        });
    }

    editExternalParticipant(id) {
        console.log('id', id)
        this.setState({ loading: true })
        const { externalParticipants } = this.state
        const element = externalParticipants.find(elt => elt.id === id)
        if (!element) {
            this.setState({ message: "Id de l'élément est introuvable", isNotDit: true, loading: false });
        } else {
            this.setState({
                externalParticipantId: id,
                externalParticipantName: element.name,
                externalParticipantEmail: element.email,
                externalParticipantCompany: element.organization,
                loading: false,
                externalParticipantDialog: true
            })
        }
    }

    galeryFileDrop(acceptedFiles) { this.setState({ selectedGalerieFiles: acceptedFiles }) }

    removeGaleryImage(id) {
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.delete(
            '/api/v1/events/' + this.state.id_event + '/galery/' + id,
            config
        ).then(response => {
            this.setState({
                message: "Image de galerie enlevé avec succès", isDid: true, anchorEl: [],
                galeries: response.data.galeries,
                loading: false,
            });

        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de suppression", isNotDit: true, loading: false });
        });
    }

    reportFileDrop(acceptedFiles) { this.setState({ selectedReportFile: acceptedFiles }) }

    removeReport(id) {
        this.setState({ loading: true })
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);
        const config = {
            headers: { 'Authorization': 'Bearer ' + token }
        };
        axios.delete(
            '/api/v1/events/' + this.state.id_event + '/report/' + id,
            config
        ).then(response => {
            this.setState({
                message: "Rapport enlevé avec succès", isDid: true, anchorEl: [],
                reports: response.data.reports,
                loading: false,
                // open: true
            });

        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Erreur de suppression", isNotDit: true, loading: false });
        });
    }

    formatDate = (strDate) => {
        const date = new Date(strDate)
        return `${("0" + date.getDate()).slice(-2)}/${("0" + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    }

    eventSwitch = (status)=>{
        this.setState({ loading: true, loadingPage: true })
        this.fetchEvent(status)
    }

    render() {

        const {
            events,
            participants,
            externalParticipants,
            galeries,
            reports,
            employees,
            participantselect,
            open,
            add,
            edit,
            participantId,
            addPar,
            editPar,

            externalParticipantDialog,
            externalParticipantId,
            externalParticipantName,
            externalParticipantEmail,
            externalParticipantCompany,

            selectedGalerieFiles,
            galerieDialog,

            selectedReportFile,
            reportDialog,
            reportDocumentName,

            name,
            type,
            place,
            begin,
            end,
            hour,
            description,
            valuetab,
            search,

            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl,
        } = this.state

        const column = [
            {
                field: 'name',
                headerName: 'Nom',
                flex: 1 / 2
            },
            {
                field: 'type',
                headerName: 'Type',
                flex: 1 / 2
            },
            {
                field: 'place',
                headerName: 'Lieu',
                flex: 1 / 2
            },
            {
                field: 'begin',
                headerName: 'Début',
                flex: 1 / 2,
                renderCell: (cellValues) => { return this.formatDate(cellValues.row.begin) }
            },
            {
                field: 'end',
                headerName: 'Fin',
                flex: 1 / 2,
                renderCell: (cellValues) => { return this.formatDate(cellValues.row.end) }
            },
            {
                field: 'hour',
                headerName: 'Heure',
                flex: 1 / 2
            },
            {
                field: 'actions',
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align: 'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{ color: "#306bff" }} />
                            </IconButton>

                            <Menu
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({ anchorEl: [] })}
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml: 1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem onClick={() => { this.show(cellValues.row.id) }} style={{ fontSize: 12, minHeight: 20 }}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Voir
                                </MenuItem>

                                <MenuItem style={{ fontSize: 12, minHeight: 20 }}
                                    disabled={!this.props.accesses.write}
                                    onClick={() => { this.edit(cellValues.row.id) }}
                                >
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>

                                <MenuItem style={{ fontSize: 12, minHeight: 20, color: "red" }}
                                    disabled={!this.props.accesses.remove}
                                    onClick={() => { if (window.confirm('Êtes-vous sûr de le supprimer ?')) { this.delete(cellValues.row.id) } }}
                                >
                                    <ListItemIcon><DeleteIcon fontSize="small" style={{ color: "red" }} /></ListItemIcon>Supprimer
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )
                }
            },
        ]

        const displaythem = (selected) => {
            let resultat = ""
            selected.map((elem) => {
                if (employees.filter(c => c["id"] === elem)[0]) {
                    resultat = resultat + employees.filter(c => c["id"] === elem)[0].firstName + " " + employees.filter(c => c["id"] === elem)[0].lastName + ", "
                }
            })

            return resultat
        }

        let Dataevents = events
        if (search !== '') {
            Dataevents = events.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1; })
        }

        return (
            <React.Fragment>
                <Grid sx={{ flexGrow: 1 }} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({ add: true })} sx={{ mr: 2, fontSize: 12, textTransform: 'none', borderRadius: 1, bgcolor: '#306bff' }}>Ajouter un événement</Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <EventSwitch  onChange={(status)=>this.eventSwitch(status)} />
                        </Box>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{ display: 'flex', justifyContent: "flex-end" }}>
                            <Search onChange={this.ChangeSearch} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0} sx={{ height: 570, backgroundColor: '#ffffff', borderRadius: 2 }}>
                            <DataGrid
                                rows={Dataevents}
                                columns={column}
                                loading={loadingPage}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    borderRadius: 2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                    },
                                    '&.MuiDataGrid-root': {
                                        border: 'none',
                                    },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color: 'black',
                                        fontWeight: 600,
                                        fontSize: 18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{ width: 200 }} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card>
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={add}
                    onClose={() => this.setState({ add: false, ...defaultEvent })}
                    scroll={'paper'}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Ajouter un événement
                        </Typography>

                        <IconButton onClick={() => this.setState({ add: false, ...defaultEvent })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" onSubmit={this.handleSubmit} style={{ marginTop: -20 }}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom de l'événement"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Type d'événement"
                                        name="type"
                                        style={{ width: '100%' }}
                                        value={type}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Lieu de l'événement"
                                        name="place"
                                        style={{ width: '100%' }}
                                        value={place}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Date début de l'événement"
                                        type="date"
                                        name="begin"
                                        style={{ width: '100%' }}
                                        value={begin}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Date fin de l'événement"
                                        type="date"
                                        name="end"
                                        style={{ width: '100%' }}
                                        value={end}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Heure de l'événement"
                                        name="hour"
                                        style={{ width: '100%' }}
                                        value={hour}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        label="Description de l'événement"
                                        name="description"
                                        style={{ width: '100%' }}
                                        value={description}
                                        onChange={this.handleChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>

                            </Grid>
                        </DialogContent >

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={edit}
                    onClose={() => this.setState({ edit: false, ...defaultEvent })}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Modifier un événement
                        </Typography>

                        <IconButton onClick={() => this.setState({ edit: false, ...defaultEvent })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" onSubmit={this.handleSubmitEdit} style={{ marginTop: -40 }}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Nom de l'événement"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={name}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Type d'événement"
                                        name="type"
                                        style={{ width: '100%' }}
                                        value={type}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Lieu de l'événement"
                                        name="place"
                                        style={{ width: '100%' }}
                                        value={place}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Date début de l'événement"
                                        type="date"
                                        name="begin"
                                        style={{ width: '100%' }}
                                        value={begin}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        label="Date fin de l'événement"
                                        type="date"
                                        name="end"
                                        style={{ width: '100%' }}
                                        value={end}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Heure de l'événement"
                                        style={{ width: '100%' }}
                                        value={hour}
                                        name="hour"
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        label="Description de l'événement"
                                        name="description"
                                        style={{ width: '100%' }}
                                        value={description}
                                        onChange={this.handleChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>

                            </Grid>
                        </DialogContent >

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            Cliquer pour modifier
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"xl"}
                    open={open}
                    onClose={() => this.setState({ open: false })}
                    scroll={'paper'}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32, paddingBottom: 8 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Dossier de l'événement
                        </Typography>

                        <IconButton onClick={() => this.setState({ open: false })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TabContext value={valuetab}  >
                                    <Box sx={{ borderColor: 'divider' }}>
                                        <TabList onChange={this.handleChangetab} variant="scrollable" scrollButtons="auto">
                                            <Tab label="Détail de l'événement" value="1" style={{ fontSize: 12, fontWeight: 'bold' }} />
                                            <Tab label="Les participants internes" value="2" style={{ fontSize: 12, fontWeight: 'bold' }} />
                                            <Tab label="Les participants externes" value="3" style={{ fontSize: 12, fontWeight: 'bold' }} />
                                            <Tab label="Galerie" value="4" style={{ fontSize: 12, fontWeight: 'bold' }} />
                                            <Tab label="Rapports" value="5" style={{ fontSize: 12, fontWeight: 'bold' }} />
                                        </TabList>
                                    </Box>

                                    <TabPanel value="1" sx={{ p: 2, minHeight: "60vh" }}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} md={12}>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Nom</strong>: {name}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Type</strong>: {type}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Place</strong>: {place}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Date début</strong>: {new Date(begin).toLocaleDateString()}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Date fin</strong>: {new Date(end).toLocaleDateString()}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Heure</strong>: {hour}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{ fontSize: 12 }}><strong>Description</strong>: {description}</Typography>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value="2" sx={{ p: 2, minHeight: "60vh" }}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({ addPar: true, participantselect: [] })} sx={{ mr: 2, fontSize: 12, textTransform: 'none', borderRadius: 1, bgcolor: '#306bff' }}>Ajouter un participant</Button>
                                            </Grid>
                                        </Grid>

                                        <table className="table table-hover" style={{ marginTop: 20, fontSize: 12 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Nom</th>
                                                    <th scope="col">Adresse email</th>
                                                    <th scope="col">Modification</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {participants.length > 0 &&
                                                    participants.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    {item.employee.photo !== "" ?
                                                                        <img style={{ width: 50 }} src={item.employee.photo} />
                                                                        :
                                                                        <img style={{ width: 50 }} src={"https://cdn-icons-png.flaticon.com/512/3135/3135768.png"} />
                                                                    }
                                                                </td>
                                                                <td>{item.employee.firstName + " " + item.employee.lastName}</td>
                                                                <td>{item.employee.email}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editPar(item.employee.id) }}
                                                                        variant="contained"
                                                                        sx={{ background: '#306bff', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: '#306bff', color: 'white' } }}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removePar(item.employee.id) }}
                                                                        variant="contained"
                                                                        sx={{ background: 'red', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: 'red', color: 'white' } }}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="3" sx={{ p: 2, minHeight: "60vh" }}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({ externalParticipantDialog: true })} sx={{ mr: 2, fontSize: 12, textTransform: 'none', borderRadius: 1, bgcolor: '#306bff' }}>Ajouter un participant</Button>
                                            </Grid>
                                        </Grid>

                                        <table className="table table-hover" style={{ marginTop: 20, fontSize: 12 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nom</th>
                                                    <th scope="col">Adresse email</th>
                                                    <th scope="col">Structure</th>
                                                    <th scope="col">Modification</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {externalParticipants.length > 0 &&
                                                    externalParticipants.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>{item.email}</td>
                                                                <td>{item.organization}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => this.editExternalParticipant(item.id)}
                                                                        variant="contained"
                                                                        sx={{ background: '#306bff', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: '#306bff', color: 'white' } }}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeExternalParticipant(item.id) }}
                                                                        variant="contained"
                                                                        sx={{ background: 'red', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: 'red', color: 'white' } }}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="4" sx={{ p: 2, minHeight: "60vh" }}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({ galerieDialog: true })} sx={{ mr: 2, fontSize: 12, textTransform: 'none', borderRadius: 1, bgcolor: '#306bff' }}>Ajouter les images</Button>
                                            </Grid>
                                        </Grid>

                                        <table className="table table-hover" style={{ marginTop: 20, fontSize: 12 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Image</th>
                                                    <th scope="col">Visualiser</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {galeries.length > 0 &&
                                                    galeries.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>
                                                                    <img
                                                                        style={{ width: 50 }}
                                                                        src={item.file}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Link color="inherit" href={item.file} target={"_blank"} style={{ textDecoration: 'none', }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{ background: '#306bff', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: '#306bff', color: 'white' } }}
                                                                        >
                                                                            Voir
                                                                        </Button>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeGaleryImage(item.id) }}
                                                                        variant="contained"
                                                                        sx={{ background: 'red', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: 'red', color: 'white' } }}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="5" sx={{ p: 2, minHeight: "60vh" }}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{ textAlign: 'right' }}>
                                                <Button variant="contained" disabled={!this.props.accesses.write} onClick={() => this.setState({ reportDialog: true })} sx={{ mr: 2, fontSize: 12, textTransform: 'none', borderRadius: 1, bgcolor: '#306bff' }}>Ajouter un rapport</Button>
                                            </Grid>
                                        </Grid>

                                        <table className="table table-hover" style={{ marginTop: 20, fontSize: 12 }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">name</th>
                                                    <th scope="col">Visualiser</th>
                                                    <th scope="col">suppression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {reports.length > 0 &&
                                                    reports.map((item) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.name}</td>
                                                                <td>
                                                                    <Link color="inherit" href={item.file} target={"_blank"} style={{ textDecoration: 'none', }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{ background: '#306bff', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: '#306bff', color: 'white' } }}
                                                                        >
                                                                            Voir le rapport
                                                                        </Button>
                                                                    </Link>
                                                                </td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeReport(item.id) }}
                                                                        variant="contained"
                                                                        sx={{ background: 'red', color: 'white', height: 30, fontSize: 12, textTransform: 'none', ':hover': { background: 'red', color: 'white' } }}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </DialogContent >
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addPar}
                    onClose={() => this.setState({ addPar: false })}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Ajouter un(e) participant(e)
                        </Typography>

                        <IconButton onClick={() => this.setState({ addPar: false })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" onSubmit={this.handleSubmitPar} style={{ marginTop: -40 }}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <FormControl style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label" style={{ top: '-8px' }}>Les participants</InputLabel>
                                        <Select
                                            multiple
                                            label="Les participants"
                                            name="participantselect"
                                            style={{ width: '100%' }}
                                            value={participantselect}
                                            renderValue={(selected) => displaythem(selected)}
                                            onChange={this.handleChangeAcces}
                                            size="small"
                                        >
                                            {employees.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    <Checkbox checked={participantselect.indexOf(answer.id) > -1} sx={{ color: '#2f4cdd', '&.Mui-checked': { color: '#2f4cdd' } }} />
                                                    <ListItemText primary={answer.firstName + " " + answer.lastName + " (" + answer.email + ")"} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editPar}
                    onClose={() => this.setState({ editPar: false, participantselect: [] })}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Modifier un(e) participant(e)
                        </Typography>

                        <IconButton onClick={() => this.setState({ editPar: false, participantselect: [] })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" onSubmit={this.handleSubmitEditPar} style={{ marginTop: -40 }}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-select-currency"
                                        select
                                        label="Participant(e)"
                                        name="participantId"
                                        onChange={this.handleChange}
                                        size="small"
                                        style={{ width: '100%' }}
                                        value={participantId}
                                    >
                                        {employees.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.firstName} {option.lastName} ({option.email})
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            Cliquer pour modifier
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={externalParticipantDialog}
                    onClose={() => this.setState({ ...defaultExternalParticipant })}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            {externalParticipantId === null ? `Ajouter un(e) participant(e) externe` : "Modification un(e) participant(e) externe"}
                        </Typography>

                        <IconButton onClick={() => this.setState({ ...defaultExternalParticipant })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" style={{ marginTop: -40 }}
                        onSubmit={externalParticipantId === null ? this.handleSubmitExternalParticipant : this.handleSubmitEditExternalParticipant}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom complet"
                                        name="externalParticipantName"
                                        style={{ width: '100%' }}
                                        value={externalParticipantName}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Adresse email"
                                        name="externalParticipantEmail"
                                        style={{ width: '100%' }}
                                        value={externalParticipantEmail}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Structure"
                                        name="externalParticipantCompany"
                                        style={{ width: '100%' }}
                                        value={externalParticipantCompany}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            {externalParticipantId === null ? "Cliquer pour ajouter" : "Cliquer pour modifier"}
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={galerieDialog}
                    onClose={() => this.setState({ ...defaultGalerie })}
                    scroll={'paper'}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32, paddingBottom: 8 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Ajout des images de galerie
                        </Typography>

                        <IconButton onClick={() => this.setState({ ...defaultGalerie })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" onSubmit={this.handleSubmitGaleryFiles}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                                    <Dropzone onDrop={acceptedFiles => this.galeryFileDrop(acceptedFiles)} accept={["image/png", "image/jpg", "image/jpeg"]}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {selectedGalerieFiles.length === 0 ?
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color: "gray" }} />
                                                            <p>Faites glisser et déposez Les images ici, ou cliquez pour sélectionner les images</p>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color: "#306bff" }} />
                                                            {selectedGalerieFiles.length > 0 &&
                                                                selectedGalerieFiles.map((item) => {
                                                                    return (
                                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                            {item.path}
                                                                        </Typography>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>


                            </Grid>
                        </DialogContent>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={reportDialog}
                    onClose={() => this.setState({ ...defaultReport })}
                    scroll={'paper'}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 32, paddingBottom: 8 }} >
                        <Typography
                            component="h1"
                            variant="h1"
                            sx={{ color: "#313339", fontSize: 26 }}
                        >
                            Ajouter un rapport
                        </Typography>

                        <IconButton onClick={() => this.setState({ ...defaultGalerie })} >
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <form role="form" onSubmit={this.handleSubmitReportFiles}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du document"
                                        name="reportDocumentName"
                                        style={{ width: '100%' }}
                                        value={reportDocumentName}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                                <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                                    <Dropzone onDrop={acceptedFiles => this.reportFileDrop(acceptedFiles)} multiple={false}>
                                        {({ getRootProps, getInputProps }) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {selectedReportFile.length === 0 ?
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color: "gray" }} />
                                                            <p>Faites glisser et déposez le ici, ou cliquez pour sélectionner le rapport</p>
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color: "#306bff" }} />
                                                            {selectedReportFile.length > 0 &&
                                                                selectedReportFile.map((item) => {
                                                                    return (
                                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                            {item.path}
                                                                        </Typography>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </Grid>


                            </Grid>
                        </DialogContent>

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 2,
                                color: "white",
                                textTransform: 'none',
                                fontSize: 20,
                                height: 50,
                                backgroundColor: '#888da0',
                                borderRadius: 0,
                                ':hover': { backgroundColor: '#306bff' }
                            }}
                        >
                            Cliquer pour ajouter
                        </Button>
                    </form>
                </Dialog>


                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </React.Fragment>
        )

    }

}