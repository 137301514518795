import React, { Component } from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import ModeIcon from '@mui/icons-material/Mode';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import Dropzone from 'react-dropzone'
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import * as locales from 'react-date-range/dist/locale';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DescriptionIcon from '@mui/icons-material/Description';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Switch from '@mui/material/Switch';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Search from "../../Components/Search"
import StatusSelecter from '../../Components/StatusSelecter' 
import { format } from 'date-fns';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { findPaymentMethode, formatToInputDate } from "../../tools/functions";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const projectInitialState = {
    name: "",
    start: "",
    end: "",
    partners: [],
    status: "En cours",
    budget: 0,
    zone: "",
    description: ""
}

const projectDocumentInitialState = {
    name: "",
    option: "",
    link: "",
    docs: null,
}
const actionPlanInitialState = {
    action: "",
    employeeId: "",
    name: "",
    docs: null,
    description: "",
}
const lineBudgetOutInitialState = {
    name: "",
    type: "",
    mode: "Check",
    unitCost: 0,
    quantity: 1,
    date: "",
    contrepartie: "",
    provider: "",
    bankId: "",
    partners: [],
    comments: "",
    documentName: "",
    docs: null
}                       
const lineBudgetEntryInitialState = {
    name: "",
    amount: 0,
    date: "",
    bankId: "",
    mode: "Check"
}  


export default class Projects extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id_project: null,
            id_actionPlan: null,
            id_lineBudgetOut: null,
            id_lineBudgetEntry: null,

            projects: [],
            employees: [],
            partners: [],
            banks: [],

            project: { ...projectInitialState },
            projectDoc: { ...projectDocumentInitialState },
            actionPlan: { ...actionPlanInitialState },
            lineBudgetOut: { ...lineBudgetOutInitialState },
            lineBudgetEntry: { ...lineBudgetEntryInitialState },

            addImport: false,
            addMember: false,
            addBudget: false,
            addBudgetEn: false,
            addSuivi: false,
            addFile: false,
            addDocSuivi: false,
            editStatus: false,  
            editMember: false,    
            editSuivi: false,      
            editSuiviStatus: false,
            editBudgetEnStatus: false,
            editBudgetStatus: false,
            editBudgetEn: false,
            editBudget: false,
            open: false,
            add: false,
            editDoc: false,
            editDocStatus: false,
            documentId: null,
            
            bankId: null,
            memberId: null,
            linebudgetId: null,
            // statuss: "En cours",
            name: "",
            // link: "",
            // start: new Date(),
            // end: new Date(),
            // budget: 0,
            // zone: "",
            // description: "",
            // partenaireSelected: [],
            employeeId: null,
            actionPlanId: null,
            role: '',
            lineBudgets: [],
            lineBudgetEntries: [],
            members:[],
            actionPlans: [],
            documents: [],
            selectedFile: [],
            option: 'link',
            action: "",
            optionBudget:'Sortie',
            type: "Personnel",
            unitCost: 0,
            quantity: 0,
            amount: 0,
            contrepartie: "",
            provider: "",
            comments: "",
            documentName: "",

            valuetab:"1",
            status: "Actif",
            search: "",

            loading: false,
            loadingPage: true,
            isDid: false,
            isNotDit: false,
            message:"",

            anchorEl: [],
            anchorEl1: null,
            anchorEl2: null,
            anchorElOptionBudget: null,
        }
        this.ChangeStatus = this.ChangeStatus.bind(this)
        this.ChangeSearch = this.ChangeSearch.bind(this)
        this.handleClick1 = this.handleClick1.bind(this)
        this.handleClick2 = this.handleClick2.bind(this)
        this.handleSelect1 = this.handleSelect1.bind(this)
        this.handleSelect2 = this.handleSelect2.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleProjectChange = this.handleProjectChange.bind(this)
        this.handleProjectDocumentChange = this.handleProjectDocumentChange.bind(this)
        this.handleActionPlanChange = this.handleActionPlanChange.bind(this)
        this.handleActionPlanDocumentChange = this.handleActionPlanDocumentChange.bind(this)
        this.handleLineBudgetOutChange = this.handleLineBudgetOutChange.bind(this)
        this.handleLineBudgetEntryChange = this.handleLineBudgetEntryChange.bind(this)
        
        
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChangetab = this.handleChangetab.bind(this)
        this.handleSubmitStatus = this.handleSubmitStatus.bind(this)
        this.handleSubmitPJ = this.handleSubmitPJ.bind(this)
        this.handleSubmitDocStatus = this.handleSubmitDocStatus.bind(this)
        this.handleSubmitMember = this.handleSubmitMember.bind(this)
        this.handleSubmitEditMember = this.handleSubmitEditMember.bind(this)
        this.handleSubmitEditSuivi = this.handleSubmitEditSuivi.bind(this)
        this.handleSubmitsuiviStatus = this.handleSubmitsuiviStatus.bind(this)
        this.handleClickOnOptionBudget = this.handleClickOnOptionBudget.bind(this)
        this.handleSubmitLinebudget = this.handleSubmitLinebudget.bind(this)
        this.handleSubmitLinebudgetEntry = this.handleSubmitLinebudgetEntry.bind(this)
        this.handleSubmitBudgetStatus = this.handleSubmitBudgetStatus.bind(this)
        this.handleSubmitBudgetStatusOut = this.handleSubmitBudgetStatusOut.bind(this)
        this.handleSubmitLinebudgetEdit = this.handleSubmitLinebudgetEdit.bind(this)
    }

    fetchProjects(){
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/projects/all', config)
        .then(response => {       
            this.setState({ 
                projects: response.data,
                loadingPage: false, loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        });   
    }

    fetchPartners(config){
        axios.get('/api/v1/partners', config)
        .then(response => {       
            this.setState({ 
                partners: response.data,
            });
        }).catch(_ => {});   
    }

    fetchEmployees(config){
        axios.get('/api/v1/employees', config)
        .then(response => {
            this.setState({ 
                employees: response.data,
            });
        }).catch(_ => {});   
    }
    fetchBanks(config){
        axios.get('/api/v1/banks', config)
        .then(response => {
            this.setState({ 
                banks: response.data,
            });
        }).catch(_ => {});   
    }

    componentDidMount(){
        const ORG_NAME = window.ORG_NAME;
        document.title = `${ORG_NAME} | Liste des projets`
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        this.fetchProjects()
        this.fetchPartners(config)
        this.fetchEmployees(config)
        this.fetchBanks(config)
        // axios.get('/api/v1/projects/all', config)
        // .then(response => {       
        //     axios.get('/api/v1/partners', config)   
        //     .then(res => { 
        //         axios.get('/api/v1/employees', config)
        //         .then(respon => {
        //             axios.get('/api/v1/banks', config)
        //             .then(respo => {   
        //                 this.setState({ 
        //                     projects: response.data,
        //                     partners: res.data,
        //                     employees: respon.data,
        //                     banks: respo.data,
        //                     loadingPage: false, loading: false
        //                 });
        //             }).catch(error => {
        //                 this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        //             });
        //         }).catch(error => {
        //             this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        //         });
        //     }).catch(error => {
        //         this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        //     }); 
        // }).catch(error => {
        //     this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loadingPage: false });
        // });   
    }

    handleClickOnOptionBudget(event){this.setState({anchorElOptionBudget: event.currentTarget})}

    ChangeStatus(status){ this.setState({status: status}) }

    ChangeSearch(search){ this.setState({search: search}) }

    handleChangetab(event, newValue){ this.setState({ valuetab: newValue}) }

    handleClick(id, event){       
        let { anchorEl } = this.state
        anchorEl[id] = event.target;
        this.setState({ anchorEl });
    }

    handleClick1(event){this.setState({anchorEl1: event.currentTarget})}

    handleClick2(event){this.setState({anchorEl2: event.currentTarget})}

    handleSelect1(date){ this.setState({start: date, anchorEl1: null}) }

    handleSelect2(date){ this.setState({end: date, anchorEl2: null}) }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleProjectChange(e) {
        const { name, value } = e.target;
        const { project } = this.state

        if(name === 'partners'){
            const partners = typeof value === 'string' ? value.split(',') : value 
            this.setState({ project: { ...project, [name]: partners } }); 
            return
        }
        this.setState({ project: { ...project, [name]: value } });
    }

    handleProjectDocumentChange(e) {
        const { name, value } = e.target;
        const { projectDoc } = this.state
        this.setState({ projectDoc: { ...projectDoc, [name]: value } });
    }

    handleActionPlanChange(e) {
        const { name, value } = e.target;
        const { actionPlan } = this.state
        this.setState({ actionPlan: { ...actionPlan, [name]: value } });
    }
    handleActionPlanDocumentChange(e) {
        const { name, value } = e.target;
        const { actionPlan } = this.state
        this.setState({ actionPlan: { ...actionPlan, [name]: value } });
    }
    handleLineBudgetOutChange(e) {
        const { name, value } = e.target;
        const { lineBudgetOut } = this.state

        if(name === 'partners'){
            const partners = typeof value === 'string' ? value.split(',') : value 
            this.setState({ lineBudgetOut: { ...lineBudgetOut, [name]: partners } }); 
            return
        }
        this.setState({ lineBudgetOut: { ...lineBudgetOut, [name]: value } });
    }

    handleLineBudgetEntryChange(e) {
        const { name, value } = e.target;
        const { lineBudgetEntry } = this.state
        this.setState({ lineBudgetEntry: { ...lineBudgetEntry, [name]: value } });
    }

    handleSubmit(event) {        
        event.preventDefault()        
        this.setState({loading: true})  
        const { project, id_project } = this.state 

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }     
        
        let partners = []
        project.partners.forEach(element => {
            const b = {id: element}
            partners = partners.concat(b)
        });

        const bodyParameters = {
            name: project.name,
            start: project.start,
            end: project.end,
            zone: project.zone,
            description: project.description,
            status: project.status,
            budget: parseFloat(project.budget),
            partners: partners
        }
        if(id_project){
            axios.put(
                `/api/v1/projects/${id_project}`, 
                bodyParameters,
                config
            )
            .then(_ => {    
                this.setState({
                    message:"Projet modifié avec succès",
                    isDid: true,
                    add: false,
                    loading: false
                });
                this.fetchProjects()            
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });        
        }else{
            axios.post(
                '/api/v1/projects', 
                bodyParameters,
                config
            )
            .then(_ => {    
                this.setState({
                    message:"Projet ajouté avec succès",
                    isDid: true,
                    add: false,
                    loading: false
                });
                this.fetchProjects()            
            }).catch(error => {
                this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
            });        
        }
    }

    handleSubmitEditSuivi(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            "action": this.state.action,
            "employeeId": this.state.employeeId,   
            "description": this.state.description         
        }
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-action-plan/' + this.state.actionPlanId, 
            bodyParameters,
            config
        )
        .then(response => {    
            this.setState({
                message:"Plan de suivi modifié avec succès",
                isDid: true,
                editSuivi: false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });        
    }

    handleSubmitMember(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            "employeeId": this.state.employeeId,
            "role": this.state.role,
        }
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-member', 
            bodyParameters,
            config
        )
        .then(response => {    
            this.setState({
                message:"Membre ajouté avec succès",
                isDid: true,
                addMember: false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });        
    }

    handleSubmitEditMember(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = {
            "employeeId": this.state.employeeId,
            "role": this.state.role,
        }
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-member/' + this.state.memberId, 
            bodyParameters,
            config
        )
        .then(response => {    
            this.setState({
                message:"Membre modifié avec succès",
                isDid: true,
                editMember: false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });        
    }

    handleSubmitStatus(event) {
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.statuss
        }
        axios.post(
            '/api/v1/projects/' + this.state.id_project + '/status', 
            bodyParameters,
            config
        )
        .then(response => {   
            this.setState({
                message:"État du projet changé avec succès",
                isDid: true, 
                editStatus:false, 
                anchorEl: [],
                loading: false
            });
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });       
        });
    }

    handleSubmitPJ(){ 
        this.setState({loading: true})
        const { projectDoc, id_project } = this.state

        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        let bodyParameters = new FormData()
        bodyParameters.append("name", projectDoc.name)

        if(projectDoc.option === "link"){
            bodyParameters.append("link", projectDoc.link)        
            // bodyParameters.append("documents", [])
        }else{
            projectDoc.docs.forEach (d => {
                bodyParameters.append('documents', d)            
            })
            // bodyParameters.append("name", project.name)
            // bodyParameters.append("link", "")  
        }

        axios.put(
            `/api/v1/projects/${id_project}/project-documents`, 
            bodyParameters,
            config
        )
        .then(_ => {     
            this.setState({
                message:"Document(s) ajouté(s) avec succès",
                isDid: true,
                addFile: false,
                loading: false
            });
            this.show(id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitActionDoc(){ 
        this.setState({loading: true})
        const { actionPlan, id_project } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        let bodyParameters = new FormData()          
        bodyParameters.append("name", actionPlan.name)
        actionPlan.docs.forEach (d => {
            bodyParameters.append('documents', d)            
        })
        axios.put(
            '/api/v1/projects/' + id_project + '/project-action-plan/' + actionPlan.action + '/document', 
            bodyParameters,
            config
        )
        .then(_ => {     
            this.setState({
                message:"Document(s) ajouté avec succès",
                isDid: true,
                addDocSuivi: false,
                loading: false
            });
            this.show(id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitSuivi(){ 
        this.setState({loading: true})
        const { actionPlan, id_project, id_actionPlan } = this.state
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        let bodyParameters = new FormData()
        if(!id_actionPlan && actionPlan.docs && actionPlan.docs.length > 0){
            actionPlan.docs.forEach (d => {
                bodyParameters.append('documents', d)            
            })
            bodyParameters.append("name", actionPlan.name)        
        }
        bodyParameters.append("action", actionPlan.action)        
        bodyParameters.append("employeeId", actionPlan.employeeId)
        bodyParameters.append("description", actionPlan.description)
        const endpoint = id_actionPlan ? `/api/v1/projects/${id_project}/project-action-plan/${id_actionPlan}` : `/api/v1/projects/${id_project}/project-action-plan`
        axios.put(
            endpoint,
            bodyParameters,
            config
        )
        .then(_ => {     
            this.setState({
                message: id_actionPlan ? "Plan de suivi modifié avec succès" : "Plan de suivi ajouté avec succès",
                isDid: true,
                addSuivi: false,
            });
            this.show(id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitDocStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.status
        }
        axios.post(
            '/api/v1/projects/' + this.state.id_project + '/project-documents/' + this.state.documentId, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut du document changé avec succès",
                isDid: true, 
                editDocStatus:false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitsuiviStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.status
        }
        axios.post(
            '/api/v1/projects/' + this.state.id_project + '/project-action-plan/' + this.state.actionPlanId, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut du plan de suivi changé avec succès",
                isDid: true, 
                editSuiviStatus:false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitBudgetStatus(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.status
        }
        axios.post(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget-entry/' + this.state.linebudgetId, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut de la ligne budgétaire changé avec succès",
                isDid: true, 
                editBudgetEnStatus:false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitBudgetStatusOut(event) {        
        event.preventDefault()        
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }  
        const bodyParameters = {
            status: this.state.status
        }
        axios.post(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget/' + this.state.linebudgetId, 
            bodyParameters,
            config
        )
        .then(response => {  
            // console.log("return", response.data)     
            this.setState({
                message:"Statut de la ligne budgétaire changé avec succès",
                isDid: true, 
                editBudgetStatus:false,
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    fileDrop(files){ this.setState({ selectedFile: files })}
    projectDocumentDrop(files, name){
        if(name === 'projectDocument'){
            const { projectDoc } = this.state
            this.setState({ projectDoc: { ...projectDoc, docs: files } })
        }
        if(name === 'actionPlan'){
            const { actionPlan } = this.state
            this.setState({ actionPlan: { ...actionPlan, docs: files } })
        }
        if(name === 'lineBudgetOut'){
            const { lineBudgetOut } = this.state
            this.setState({ lineBudgetOut: { ...lineBudgetOut, docs: files } })
        }
    }

    fileDropImport(acceptedFiles){ this.setState({ selectedFile: acceptedFiles[0] })}

    editStatus(id){
        this.setState({loading: true })  
        const { project } = this.state
        const item = this.state.projects.filter(c => c.id === id)[0]
        this.setState({
            id_project: id,
            project: { ...project, status: item.status },
            // statuss: item.status,            
            loading: false,
            editStatus: true
        })
    }

    editDocStatus(id){
        this.setState({loading: true })  
        const item = this.state.documents.filter(c => c["id"] === id)[0]
        this.setState({
            documentId: id,
            status: item.status,            
            loading: false,
            editDocStatus: true
        })
    }

    editSuiviStatus(id){
        this.setState({loading: true })  
        const item = this.state.actionPlans.filter(c => c["id"] === id)[0]
        this.setState({
            actionPlanId: id,
            status: item.status,            
            loading: false,
            editSuiviStatus: true
        })
    }

    editBudgetEnStatus(id){
        this.setState({loading: true })  
        const item = this.state.lineBudgetEntries.filter(c => c["id"] === id)[0]
        this.setState({
            linebudgetId: id,
            status: item.status,            
            loading: false,
            editBudgetEnStatus: true
        })
    }

    editBudgetStatus(id){
        this.setState({loading: true })  
        const item = this.state.lineBudgets.filter(c => c["id"] === id)[0]
        this.setState({
            linebudgetId: id,
            status: item.status,            
            loading: false,
            editBudgetStatus: true
        })
    }

    removeDoc(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/projects/' + this.state.id_project + '/project-documents/' + id,
            config
        ).then(response => {     
            this.setState({
                message:"Document supprimé avec succès", 
                isDid: true, 
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    removeBudgetEn(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget-entry/' + id,
            config
        ).then(response => {     
            this.setState({
                message:"Ligne budgétaire supprimée avec succès", 
                isDid: true, 
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    removeBudget(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget/' + id,
            config
        ).then(response => {     
            this.setState({
                message:"Ligne budgétaire supprimée avec succès", 
                isDid: true, 
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    removeSuivi(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(
            '/api/v1/projects/' + this.state.id_project + '/project-action-plan/' + id,
            config
        ).then(response => {     
            this.setState({
                message:"Plan de suivi supprimé avec succès", 
                isDid: true, 
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    removeMember(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete(
            '/api/v1/projects/' + this.state.id_project + '/project-member/' + id,
            config
        ).then(response => {     
            this.setState({
                message:"Membre supprimé avec succès", 
                isDid: true, 
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });    
    }

    editDoc(id){
        this.setState({loading: true })  
        const item = this.state.documents.filter(c => c["id"] === id)[0]

        this.setState({
            documentId: id,
            name: item.name,
            link: item.file,
            option: item.extratLink  ? 'link' : 'File',
            selectedFile: [],
            loading: false,
            editDoc: true
        })
    }

    editBudgetEn(id){
        this.setState({loading: true })  
        const item = this.state.lineBudgetEntries.filter(c => c.id === id)[0]
        this.setState({
            id_lineBudgetEntry: id,
            lineBudgetEntry: {
                name: item.name,
                amount: item.amount,
                date: formatToInputDate(item.date),
                bankId: item.bankId,
                mode: item.paymentMethode,
            },
            loading: false,
            addBudgetEn: true
        })
    }

    editBudget(id){
        this.setState({loading: true })  
        const item = this.state.lineBudgets.filter(c => c.id === id)[0]
        const partners = []
        const items = item.partners
        for (let i = 0; i < items.length; i++) {
            partners.push(items[i].partnerId)
        }                        
        this.setState({
            id_lineBudgetOut: id,
            lineBudgetOut: {
                name: item.name,
                type: item.type,
                unitCost: item.unitCost,
                quantity: item.quantity,
                date: formatToInputDate(item.date),
                contrepartie: item.contrepartie,
                provider: item.provider,
                bankId: item.bankId,
                mode: item.paymentMethode,
                comments: item.comments,
                partners: partners,
                docs: null
            },
            loading: false,
            addBudget: true
        })
    }

    editMember(id){
        this.setState({loading: true })  
        const item = this.state.members.filter(c => c["id"] === id)[0]

        this.setState({
            memberId: id,
            employeeId: item.employee.id,
            role: item.role,
            loading: false,
            editMember: true
        })
    }

    editSuivi(id){
        this.setState({loading: true })  
        const item = this.state.actionPlans.filter(c => c.id === id)[0]
        this.setState({
            actionPlan: {
                action: item.action,
                employeeId: item.employeeId,
                description: item.description,
                
            },
            id_actionPlan: id,
            loading: false,
            addSuivi: true
        })
    }

    makeComplete(id){
        this.setState({loading: true })  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-action-plan/' + id + '/complete',{}, config)
        .then(response => {     
            this.setState({message:"État changé avec succès", isDid: true,});
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });  
        }); 
    }

    makeCompleteBudget(id){
        this.setState({loading: true })  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget/' + id + '/complete',{}, config)
        .then(response => {     
            this.setState({message:"État changé avec succès", isDid: true,});
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });  
        }); 
    }

    show(id){
        this.setState({loading: true })  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/projects/' + id, config)
        .then(response => {            
            this.setState({ 
                id_project: id,
                name: response.data.name,
                start: new Date (response.data.start),
                end: response.data.end,
                zone: response.data.zone,
                description: response.data.description,
                statuss: response.data.status,
                budget: response.data.budget.amount,
                lineBudgets: response.data.budget.lineBudgets,
                lineBudgetEntries: response.data.budget.lineBudgetEntries,
                members: response.data.members,
                actionPlans: response.data.actionPlans,
                documents: response.data.documents,
                open: true,
                loading: false
            });
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    openEdit(id){
        this.setState({loading: true })  
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/projects/' + id, config)
        .then(response => {   
            const data = response.data
            const partners = []

            data.partners.forEach(item => {
                const partner = item.partner
                partners.push(partner.id)
            });

            const project = {
                name: data.name,
                start: formatToInputDate(data.start),
                end: formatToInputDate(data.end),
                partners: partners,
                status: data.status,
                budget: data.budget.amount,
                zone: data.zone,
                description: data.description
            }
            this.setState({
                id_project: id,
                project: { ...project }, 
                add: true, 
                loading: false
            })    
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    deleteStatus(id){
        this.setState({loading: true })  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.delete( 
            '/api/v1/projects/' + id,
            config
        ).then(response => {     
            this.setState({message:"Statut changé avec succès", isDid: true, anchorEl: []});
            this.componentDidMount()            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });  
        }); 
    }

    selectOptionBudget(item){ this.setState({optionBudget: item, anchorElOptionBudget: null })}

    handleSubmitLinebudget(event) {        
        event.preventDefault()  
        this.setState({loading: true})
        const { lineBudgetOut, id_project, id_lineBudgetOut } = this.state 
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        let partners = []

        lineBudgetOut.partners.forEach(element => {
            const b = {id: element}
            partners = partners.concat(b)
        });

        let bodyParameters = new FormData()
        if(lineBudgetOut.docs){
            if(lineBudgetOut.docs.length > 0 )  {
                lineBudgetOut.docs.forEach (d => {
                    bodyParameters.append('documents', d)
                })     
                bodyParameters.append("documentName", this.state.documentName)
            }
        }
        bodyParameters.append("name", lineBudgetOut.name)
        bodyParameters.append("type", lineBudgetOut.type)
        bodyParameters.append("unitCost", lineBudgetOut.unitCost)
        bodyParameters.append("date", lineBudgetOut.date)
        bodyParameters.append("quantity", lineBudgetOut.quantity)
        bodyParameters.append("contrepartie", lineBudgetOut.contrepartie)
        bodyParameters.append("provider", lineBudgetOut.provider)
        bodyParameters.append("bankId", lineBudgetOut.bankId)
        bodyParameters.append("mode", lineBudgetOut.mode)
        bodyParameters.append("comments", lineBudgetOut.comments)
        bodyParameters.append("partners", JSON.stringify(partners))

        const endpoint = id_lineBudgetOut ? `/api/v1/projects/${id_project}/project-line-budget/${id_lineBudgetOut}` : `/api/v1/projects/${id_project}/project-line-budget`
        axios.put(
            endpoint,
            bodyParameters,
            config
        )
        .then(_ => {     
            this.setState({
                message: id_lineBudgetOut ? "Ligne budgétaire modifiée avec succès" : "Ligne budgétaire ajoutée avec succès",
                isDid: true,
                addBudget: false,
                loading: false
            });
            this.show(id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitLinebudgetEdit(event) {        
        event.preventDefault()  
        this.setState({loading: true})  
         
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        let partners = []

        this.state.partenaireSelected.map((elem) => {
            const b = {id: elem}
            partners = partners.concat(b)
        })

        const bodyParameters = {
            name: this.state.name ,
            type: this.state.type,
            unitCost: parseFloat(this.state.unitCost),
            quantity: parseFloat(this.state.quantity),
            date: format(new Date(this.state.start), 'yyyy-MM-dd'),
            contrepartie: this.state.contrepartie,
            provider: this.state.provider,
            bankId: this.state.bankId,
            partners: partners,
        }
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget/' + this.state.linebudgetId, 
            bodyParameters,
            config
        )
        .then(response => {     
            this.setState({
                message:"Ligne budgétaire modifiée avec succès",
                isDid: true,
                editBudget: false,
                loading: false
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitLinebudgetEntry(event) {        
        event.preventDefault()  
        this.setState({loading: true})  
        const { lineBudgetEntry, id_project, id_lineBudgetEntry } = this.state  
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }

        const bodyParameters = {
            name: lineBudgetEntry.name ,
            amount: parseFloat(lineBudgetEntry.amount),
            date: lineBudgetEntry.date,
            bankId:lineBudgetEntry.bankId,
            mode:lineBudgetEntry.mode
        }
        const endpoint = id_lineBudgetEntry ? `/api/v1/projects/${id_project}/project-line-budget-entry/${id_lineBudgetEntry}` : `/api/v1/projects/${id_project}/project-line-budget-entry`
        axios.put(
            endpoint,
            bodyParameters,
            config
        )
        .then(_ => {     
            this.setState({
                message: id_lineBudgetEntry ? "Ligne budgétaire modifiée avec succès" : "Ligne budgétaire ajoutée avec succès",
                isDid: true,
                addBudgetEn: false,
                loading: false
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    handleSubmitImport(){ 
        this.setState({loading: true})   
        const tokenJson = localStorage.getItem("TOKEN");
        const token = JSON.parse(tokenJson);        
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        }
        const bodyParameters = new FormData();
        bodyParameters.append("file", this.state.selectedFile)
        axios.put(
            '/api/v1/projects/' + this.state.id_project + '/project-line-budget/import', 
            bodyParameters,
            config
        )
        .then(response => {     
            this.setState({
                message:"Fichier importé avec succès",
                isDid: true,
                addImport: false,
                loading: false
            });
            this.show(this.state.id_project)            
        }).catch(error => {
            this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        });
    }

    render(){

        const {
            id_project,
            id_actionPlan,
            id_lineBudgetOut,
            id_lineBudgetEntry,

            projects,
            project,
            projectDoc,
            actionPlan,
            lineBudgetOut,
            lineBudgetEntry,
            
            partners,

            lineBudgetEntries,
            employees,
            banks,
            addBudget,
            addBudgetEn,
            optionBudget,
            anchorElOptionBudget,
            open,
            add,
            addImport,
            addMember,
            addFile,
            addDocSuivi,
            editDoc,
            editMember,
            editDocStatus,
            name,
            statuss,
            start,
            end,
            budget,
            status,
            search,
            description,
            zone,
            employeeId,
            role,
            addSuivi,
            lineBudgets,
            documents,
            actionPlans,
            editStatus,
            members,
            selectedFile,
            editSuiviStatus,
            editBudgetEnStatus,
            editBudgetStatus,

            valuetab,
            loading,
            loadingPage,
            isDid,
            isNotDit,
            message,
            anchorEl,
            anchorEl1,
            anchorEl2
        } = this.state

        const column = [
            { 
                field: 'name', 
                headerName: 'Projet',
                flex: 1
            },
            { 
                field: 'start', 
                headerName: 'Démarrage',
                flex: 1/2,
                renderCell: (cellValues) => {return  new Date(cellValues.row.start).toLocaleDateString()}
            },
            { 
                field: 'end', 
                headerName: 'Clôture',
                flex: 1/2,
                renderCell: (cellValues) => {return  new Date(cellValues.row.end).toLocaleDateString()}
            },
            { 
                field: 'description', 
                headerName: 'Description',
                flex: 2
            },
            { 
                field: 'zone', 
                headerName: "La zone d'execution",
                flex: 1/2
            },
            { 
                field: 'status', 
                headerName: 'État',
                maxWidth: 100,
            },            
            { 
                field: 'archived', 
                headerName: 'Statut',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    if(!cellValues.row.archived) {
                        return (
                            <Tooltip title="Statut actif">
                                <IconButton disabled={!this.props.accesses.approve} onClick={() => this.deleteStatus(cellValues.row.id)}>   
                                    <Switch disabled={!this.props.accesses.approve} checked={true} />
                                </IconButton> 
                            </Tooltip>                            
                        )
                    }else{
                        return (
                            <Tooltip title="Statut non actif">
                                <IconButton disabled={!this.props.accesses.approve} onClick={() => this.deleteStatus(cellValues.row.id)}>   
                                    <Switch disabled={!this.props.accesses.approve} checked={false} />
                                </IconButton>
                            </Tooltip>
                            
                        )
                    }                                       
                }
            },
            // { 
            //     field: 'budget', 
            //     headerName: 'Budget',
            //     flex: 1/2,
            //     renderCell: (cellValues) => {return cellValues.row.budget.amount.toLocaleString()}
            // },
            { 
                field: 'actions', 
                headerName: 'Actions',
                maxWidth: 100,
                headerAlign: 'center',
                align:'center',
                renderCell: (cellValues) => {
                    return (
                        <React.Fragment>
                            <IconButton onClick={e => this.handleClick(cellValues.row.id, e)}>
                                <MoreVertIcon style={{color: "#306bff"}} />
                            </IconButton>

                            <Menu                                                            
                                id={cellValues.row.id}
                                anchorEl={anchorEl[cellValues.row.id]}
                                keepMounted
                                open={Boolean(anchorEl[cellValues.row.id])}
                                onClose={() => this.setState({anchorEl: []})}    
                                PaperProps={{
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 0,
                                        ml:1,
                                    },
                                }}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem  onClick={() => { this.show(cellValues.row.id) }} style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><VisibilityIcon fontSize="small" /></ListItemIcon>Détails
                                </MenuItem>

                                <MenuItem  disabled={!this.props.accesses.write} onClick={() => { this.openEdit(cellValues.row.id) }}  style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Modifier
                                </MenuItem>

                                <MenuItem  disabled={!this.props.accesses.write} onClick={() => { this.editStatus(cellValues.row.id) }}  style={{fontSize: 12, minHeight: 20}}>
                                    <ListItemIcon><ModeIcon fontSize="small" /></ListItemIcon>Changer l'état du projet
                                </MenuItem>

                                {/* 
                                    <MenuItem onClick={() => {if(window.confirm('Êtes-vous sûr de le supprimer ?')){this.delete(cellValues.row.id)}}} style={{color:"red"}}>
                                        <ListItemIcon><DeleteIcon fontSize="small" style={{color:"red"}} /></ListItemIcon>Supprimer
                                    </MenuItem> 
                                */}
                            </Menu>
                        </React.Fragment>                   
                    )
                }
            }
        ]

        const displaydate = (value) => {
            var date = new Date(value);
            if(date.getMonth() == 0){
                return date.getDate() + " janvier " + date.getFullYear()
            }
            if(date.getMonth() == 1){
                return date.getDate() + " Février " + date.getFullYear()
            }
            if(date.getMonth() == 2){
                return date.getDate() + " Mars " + date.getFullYear()
            }
            if(date.getMonth() == 3){
                return date.getDate() + " Avril " + date.getFullYear()
            }
            if(date.getMonth() == 4){
                return date.getDate() + " Mai " + date.getFullYear()
            }
            if(date.getMonth() == 5){
                return date.getDate() + " Juin " + date.getFullYear()
            }
            if(date.getMonth() == 6){
                return date.getDate() + " Juillet " + date.getFullYear()
            }
            if(date.getMonth() == 7){
                return date.getDate() + " Août " + date.getFullYear()
            }
            if(date.getMonth() == 8){
                return date.getDate() + " Septembre " + date.getFullYear()
            }
            if(date.getMonth() == 9){
                return date.getDate() + " Octobre " + date.getFullYear()
            }
            if(date.getMonth() == 10){
                return date.getDate() + " Novembre " + date.getFullYear()
            }
            if(date.getMonth() == 11){
                return date.getDate() + " Décembre " + date.getFullYear()
            }
        }

        const displaythem = (selected) => {
            let resultat = ""
            selected.map((elem) => {
                if(partners.filter(c => c["id"] === elem)[0]){
                    resultat = resultat + partners.filter(c => c["id"] === elem)[0].name + ", "    
                }                
            })
            return resultat
        }

        let Dataprojects=[]
        if(status == 'Tout'){
            Dataprojects = projects
        }
        if(status == 'Actif'){
            Dataprojects = projects.filter(c => c["archived"] === false)
        }
        if(status == 'Non actif'){
            Dataprojects = projects.filter(c => c["archived"] === true)
        }
        if(search !== ''){
            Dataprojects = projects.filter(elem => { return elem.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;})
        }

        return(
            <React.Fragment>
                <Grid sx={{ flexGrow: 1}} container spacing={4} >
                    <Grid item xs={6} sm={6} md={4} >
                        <Button
                            variant="contained" 
                            disabled={!this.props.accesses.write} 
                            onClick={() => this.setState({project: { ...projectInitialState}, id_project: null, add: true})} 
                            sx={{borderRadius:1, bgcolor: '#306bff', fontSize: 12}}>Nouveau</Button>
                    </Grid> 

                    <Grid item xs={6} sm={6} md={4} >
                        <Box sx={{display: 'flex', justifyContent:"flex-end"}}>
                            <StatusSelecter  onChange={this.ChangeStatus} />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} >
                        <Search  onChange={this.ChangeSearch} />                                     
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} >
                        <Card elevation={0}  sx={{height:570, width: '100%', backgroundColor:'#ffffff', borderRadius:2}}>
                            <DataGrid
                                rows={Dataprojects}
                                columns={column}
                                loading={loadingPage}
                                sortModel={[{
                                    field: 'name',
                                    sort: 'asc',
                                }]}
                                elevation={10}
                                sx={{
                                    fontSize: 12,
                                    boxShadow: 10,
                                    borderRadius:2,
                                    '.MuiDataGrid-columnSeparator': {
                                        display: 'none',
                                        },
                                        '&.MuiDataGrid-root': {
                                        border: 'none',
                                        },
                                    '.css-1jbbcbn-MuiDataGrid-columnHeaderTitle': {
                                        color:'black',
                                        fontWeight: 600,
                                        fontSize:18
                                    }
                                }}
                                localeText={{
                                    noRowsLabel: <img style={{width:200}} src="https://static.vecteezy.com/system/resources/previews/011/537/764/original/find-folder-empty-state-single-isolated-icon-with-flat-style-free-vector.jpg" />,
                                    MuiTablePagination: {
                                        labelRowsPerPage: 'Ligne par page'
                                    }
                                }}
                            />
                        </Card> 
                    </Grid>
                </Grid>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={add}
                    onClose={() => this.setState({add: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_project ? "Modifier un projet" : "Ajouter un projet"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({add: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmit} style={{marginTop:-20}}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Nom du projet"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={project.name}
                                        onChange={this.handleProjectChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={6}>                                    
                                    <TextField
                                        name="start"
                                        type="date"
                                        label="Debut du projet"
                                        style={{ width: '100%' }}
                                        value={project.start}
                                        onChange={this.handleProjectChange} 
                                        size="small"
                                    />
                                </Grid>  

                                <Grid item xs={6}>                                    
                                    <TextField
                                        name="end"
                                        type="date"
                                        label="Fin du projet"
                                        style={{ width: '100%' }}
                                        value={project.end}
                                        onChange={this.handleProjectChange} 
                                        size="small"
                                    />
                                </Grid> 

                                <Grid item xs={12}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label" style={{top:'-8px'}}>Partenaires</InputLabel>
                                        <Select
                                            multiple
                                            label="Partenaires"
                                            name="partners"
                                            style={{ width: '100%' }}
                                            value={project.partners}
                                            renderValue={(selected) => displaythem(selected)}
                                            onChange={this.handleProjectChange}
                                            size="small"
                                        >
                                            {partners.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    <Checkbox checked={project.partners.indexOf(answer.id) > -1} sx={{color:'#2f4cdd', '&.Mui-checked': {color:'#2f4cdd'}}} />
                                                    <ListItemText primary={answer.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut du projet"
                                        name="status"
                                        style={{ width: '100%' }}
                                        value={project.status}
                                        onChange={this.handleProjectChange}
                                        size="small"
                                    >
                                        <MenuItem key="En attente" value="En attente">En attente</MenuItem>                                        
                                        <MenuItem key="En cours" value="En cours">En cours</MenuItem>
                                        <MenuItem key="Terminer" value="Terminer">Terminer</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        type="number"
                                        id="outlined-required"
                                        label="Budget du projet"
                                        name="budget"
                                        style={{ width: '100%' }}
                                        value={project.budget}
                                        onChange={this.handleProjectChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="La zone d'execution du projet"
                                        name="zone"
                                        style={{ width: '100%' }}
                                        value={project.zone}
                                        onChange={this.handleProjectChange}
                                        size="small"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Description du projet"
                                        name="description"
                                        style={{ width: '100%' }}
                                        value={project.description}
                                        onChange={this.handleProjectChange}
                                        size="small"
                                        multiline
                                        rows={4}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            {id_project ? "Cliquer modifier" : "Cliquer pour ajouter"}
                        </Button>
                    </form>
                </Dialog>

                <Dialog
                    fullScreen                    
                    TransitionComponent={Transition}
                    open={open}
                    onClose={() => this.setState({open: false})}
                    scroll={'paper'}
                >
                    <AppBar sx={{ position: 'relative', background: '#fff' }}>
                        <Toolbar>
                            <Typography sx={{ ml: 2, flex: 1, color:"#313339" }} variant="h6" component="div">
                                Projet: {name}
                            </Typography>

                            <IconButton onClick={() =>  this.setState({open: false })} >   
                                <CloseIcon /> 
                            </IconButton>
                        </Toolbar>
                    </AppBar>                    

                    <DialogContent dividers={true}>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TabContext value={valuetab} >
                                    <Box sx={{  borderColor: 'divider'}}>
                                        <TabList onChange={this.handleChangetab} variant="scrollable" scrollButtons="auto">
                                            <Tab label="Détails du projet" value="1" style={{fontSize: 12, fontWeight: 'bold'}}/>   
                                            <Tab label="Détails budgétaires" value="2" style={{fontSize: 12, fontWeight: 'bold'}} />    
                                            <Tab label="Les membres du projet" value="3" style={{fontSize: 12, fontWeight: 'bold'}} />
                                            <Tab label="Plans de suivi" value="4" style={{fontSize: 12, fontWeight: 'bold'}} />  
                                            <Tab label="Documents du projet" value="5" style={{fontSize: 12, fontWeight: 'bold'}} />                                             
                                        </TabList>
                                    </Box>
                                     
                                    <TabPanel value="1" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}> 
                                            <Grid item xs={12} md={10}>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Projet</strong>: {name}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Début</strong>: {new Date(start).toLocaleDateString()}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Clôture</strong>: {new Date(end).toLocaleDateString()}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Statut</strong>: {statuss}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Budget</strong>: {budget}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Zone d"excution</strong>: {zone}</Typography>
                                                <Typography component="p" variant="p" color="text.secondary" sx={{fontSize: 12}}><strong>Description</strong>: {description}</Typography>
                                            </Grid>
                                        </Grid>                                    
                                    </TabPanel>
                                    
                                    <TabPanel value="2" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>                                                
                                                <Box 
                                                    style={{
                                                        display: 'flex', 
                                                        flexDirection:'row', 
                                                        justifyContent:'flex-end'
                                                    }}
                                                >  
                                                    {optionBudget === "Sortie" &&
                                                        <Button 
                                                            variant="contained" 
                                                            onClick={() => this.setState({addImport: true})}
                                                            sx={{mr:2, fontSize: 12, textTransform: 'none', px: 0.5, borderRadius:1, bgcolor: '#306bff'}}
                                                        >
                                                            Importer des lignes budgétaires
                                                        </Button>
                                                    }

                                                    <Button 
                                                        variant="contained"
                                                        disabled={!this.props.accesses.write}
                                                        onClick={() => {
                                                            optionBudget === "Sortie"  ? 
                                                            this.setState({ lineBudgetOut: {...lineBudgetOutInitialState}, id_lineBudgetOut: null, addBudget: true}) : 
                                                            this.setState({ lineBudgetEntry: {...lineBudgetEntryInitialState}, id_lineBudgetEntry: null, addBudgetEn: true})}}
                                                        sx={{mr:2, fontSize: 12, textTransform: 'none', px: 0.5, borderRadius:1, bgcolor: '#306bff'}}
                                                    >
                                                        Ajouter une ligne budgétaire
                                                    </Button>
                                                    
                                                    <Card elevation={1}  sx={{height:45, mr:2, p:2, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center',}}>
                                                        <Typography sx={{marginRight: 4}}>Type: <span style={{color:'#306bff'}}> {optionBudget}</span></Typography>

                                                        <Box sx={{ flexGrow: 1 }} />

                                                        <IconButton onClick={this.handleClickOnOptionBudget}>
                                                            <ExpandMoreIcon sx={{color:'#306bff'}}/>
                                                        </IconButton>

                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={anchorElOptionBudget}
                                                            open={Boolean(anchorElOptionBudget)}
                                                            onClose={() => {this.setState({ anchorElOptionBudget: null})}}
                                                            MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                            }}
                                                            PaperProps={{
                                                                sx: {
                                                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                                                    mt: 2,
                                                                    ml: -2
                                                                }
                                                            }}
                                                        >
                                                            <MenuItem 
                                                                onClick={() => this.selectOptionBudget("Sortie")} 
                                                                sx={optionBudget === "Sortie" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                                            >
                                                                Sortie
                                                            </MenuItem>

                                                            <MenuItem 
                                                                onClick={() => this.selectOptionBudget("Entrée")} 
                                                                sx={optionBudget === "Entrée" ? {color:'#306bff', fontSize: 12, minHeight: 20} : {fontSize: 12, minHeight: 20}}
                                                            >
                                                                Entrée
                                                            </MenuItem>
                                                        </Menu>
                                                    </Card>
                                                </Box>                                                    
                                            </Grid> 
                                        </Grid>

                                        {optionBudget === "Sortie" ?
                                            <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Nom</th>
                                                        <th scope="col">Type</th>
                                                        <th scope="col">coût unitaire</th>                                        
                                                        <th scope="col">Qté</th>
                                                        <th scope="col">Contre partie</th>
                                                        <th scope="col">Fournisseur</th>                                        
                                                        <th scope="col">Date</th>
                                                        <th scope="col">Commentaires</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Banque</th>
                                                        <th scope="col">Réglement</th>
                                                        <th scope="col" >Complété</th>
                                                        <th scope="col">Documents</th> 
                                                        <th scope="col">Modifier</th>                                        
                                                        <th scope="col">Supression</th>                                                      
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {lineBudgets.length > 0 &&
                                                        lineBudgets.map((elem) => {
                                                            return(
                                                                <tr>
                                                                    <td>{elem.name}</td>
                                                                    <td>{elem.type}</td>
                                                                    <td>{elem.unitCost.toLocaleString()}</td>
                                                                    <td>{elem.quantity.toLocaleString()}</td>
                                                                    <td>{elem.contrepartie}</td>
                                                                    <td>{elem.provider}</td>       
                                                                    <td>{new Date(elem.date).toLocaleDateString()}</td>  
                                                                    <td>{elem.comments}</td>  
                                                                    <td onClick={!this.props.accesses.approve ? null : () => { this.editBudgetStatus(elem.id) }} style={{ cursor: 'pointer'}}>
                                                                        {elem.status === "Processing"  && "Traitement" }
                                                                        {elem.status === "Approved"  && "Approuvé" }
                                                                        {elem.status === "Disaproved"  && "Refusé" }
                                                                    </td>  
                                                                    <td>{elem.bank.name} | {elem.bank.account}</td>    
                                                                    <td>{findPaymentMethode(elem.paymentMethode)}</td>    
                                                                    <td>
                                                                        {elem.completed ? 
                                                                            <Tooltip title="Non complété">
                                                                                <IconButton disabled={elem.status !== "Approved"} onClick={!this.props.accesses.approve ? null : () => this.makeCompleteBudget(elem.id)}>   
                                                                                    <Switch checked={true} />
                                                                                </IconButton> 
                                                                            </Tooltip>  
                                                                        :    
                                                                            <Tooltip title="Complété">
                                                                                <IconButton disabled={elem.status !== "Approved"} onClick={!this.props.accesses.approve ? null : () => this.makeCompleteBudget(elem.id)}>   
                                                                                    <Switch checked={false} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        }     
                                                                    </td>       
                                                                    <td>
                                                                        {elem.documents.length > 0 &&
                                                                            elem.documents.map((item) => {
                                                                                return(
                                                                                    <Link color="inherit" href={item.file} target={"_blank"} style={{textDecoration: 'none', }}>
                                                                                        <VisibilityIcon sx={{ fontSize: 18}} />
                                                                                    </Link>
                                                                                )
                                                                            })
                                                                        }
                                                                    </td> 
                                                                    <td>
                                                                        {this.props.accesses.write && 
                                                                            <Link color="inherit" href="#" style={{textDecoration: 'none', }}  onClick={() => { this.editBudget(elem.id) }} >
                                                                                <EditIcon sx={{ fontSize: 18}} />
                                                                            </Link>
                                                                        }
                                                                        {!this.props.accesses.write && <EditIcon sx={{ fontSize: 18}} color="#ddd"/>}
                                                                    </td>

                                                                    <td>
                                                                    {this.props.accesses.remove && 
                                                                        <Link color="inherit" href="#" style={{textDecoration: 'none', }}  onClick={() => { this.removeBudget(elem.id) }} >
                                                                            <DeleteIcon sx={{ fontSize: 18}} />
                                                                        </Link>
                                                                    }
                                                                    {!this.props.accesses.remove && <DeleteIcon sx={{ fontSize: 18}} color="#ddd"/>}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>                                            
                                            </table>
                                        :
                                            <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Nom</th>
                                                        <th scope="col">Montant</th>
                                                        <th scope="col">Date</th>                                        
                                                        <th scope="col">Banque</th>
                                                        <th scope="col">Réglement</th>
                                                        <th scope="col">Statut</th>
                                                        <th scope="col">Modifier</th>                                        
                                                        <th scope="col">Supression</th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {lineBudgetEntries.length > 0 &&
                                                        lineBudgetEntries.map((elem) => {
                                                            return(
                                                                <tr>
                                                                    <td>{elem.name}</td>
                                                                    <td>{elem.amount.toLocaleString()}</td>
                                                                    <td>{new Date(elem.date).toLocaleDateString()}</td> 
                                                                    <td>{elem.bank.name} | {elem.bank.account}</td>
                                                                    <td>{findPaymentMethode(elem.paymentMethode)}</td> 
                                                                    <td onClick={() => { this.editBudgetEnStatus(elem.id) }} style={{ cursor: 'pointer'}}>
                                                                        {elem.status === "Processing"  && "Traitement" }
                                                                        {elem.status === "Approved"  && "Approuvé" }
                                                                        {elem.status === "Disaproved"  && "Refusé" }
                                                                    </td>
                                                                    <td>
                                                                        {this.props.accesses.write && elem.status !== "Approved" &&
                                                                            <Link color="inherit" href="#" style={{textDecoration: 'none'}}  onClick={() => { this.editBudgetEn(elem.id) }} >
                                                                                <EditIcon sx={{ fontSize: 18}} />
                                                                            </Link>
                                                                        }
                                                                        {(elem.status === "Approved" || !this.props.accesses.write) && <EditIcon sx={{ fontSize: 18, color: "#e0e0e0"}} />}
                                                                    </td>

                                                                    <td>
                                                                        {this.props.accesses.remove && elem.status !== "Approved" &&
                                                                            <Link color="inherit" href="#" style={{textDecoration: 'none'}}  onClick={() => { this.removeBudgetEn(elem.id) }} >
                                                                                <DeleteIcon sx={{ fontSize: 18}} />
                                                                            </Link>
                                                                        }
                                                                        {(elem.status === "Approved" || !this.props.accesses.remove) && <DeleteIcon sx={{ fontSize: 18, color: "#e0e0e0"}} />}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>                                                
                                            </table>
                                        }
                                    </TabPanel>

                                    <TabPanel value="3" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button 
                                                    variant="contained" 
                                                    disabled={!this.props.accesses.write}
                                                    onClick={() => this.setState({addMember: true})}
                                                    sx={{mr:2, fontSize: 12, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                                                >
                                                    Ajouter un membre
                                                </Button>
                                            </Grid> 
                                        </Grid>
                                    
                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Employé</th>
                                                    <th scope="col">Rôle</th>
                                                    <th scope="col">Modification</th>                                        
                                                    <th scope="col">Supression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {members.length > 0 &&
                                                    members.map((elem) => {
                                                        return(
                                                            <tr>
                                                                <td>{elem.employee.firstName} {elem.employee.lastName} ({elem.employee.firstName} {elem.employee.email})</td>
                                                                <td>{elem.role}</td>
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editMember(elem.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', textTransform: 'none', fontSize: 12, ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>

                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeMember(elem.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', color:'white', textTransform: 'none', fontSize: 12, ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }                          
                                            </tbody>
                                        </table>                                            
                                    </TabPanel>

                                    <TabPanel value="4" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Box style={{display: 'flex', flexDirection:'row', justifyContent:'flex-end'}} >
                                                    <Button 
                                                        variant="contained" 
                                                        disabled={!this.props.accesses.write}
                                                        onClick={() => this.setState({ actionPlan: { ...actionPlanInitialState }, addDocSuivi: true})}
                                                        sx={{mr:2, fontSize: 12, boxShadow: 0, textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                                                    >
                                                        Ajouter un document
                                                    </Button>
                                                    
                                                    <Button 
                                                        variant="contained" 
                                                        disabled={!this.props.accesses.write}
                                                        onClick={() => this.setState({ actionPlan: { ...actionPlanInitialState }, id_actionPlan: null, addSuivi: true})}
                                                        sx={{mr:2, fontSize: 12, boxShadow: 0,  textTransform: 'none', borderRadius:1, bgcolor: '#306bff'}}
                                                    >
                                                        Ajouter une action
                                                    </Button>
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Action</th>
                                                    <th scope="col">Employé</th>
                                                    <th scope="col">Statut</th>
                                                    <th scope="col">Description</th>
                                                    <th scope="col" style={{width:"70px !important"}}>Voir</th>
                                                    <th scope="col" >Complété</th>
                                                    <th scope="col">Modification</th>                                        
                                                    <th scope="col">Supression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {actionPlans.length > 0 &&
                                                    actionPlans.map((item) => {
                                                        return(
                                                            <tr>
                                                                <td>{item.action}</td>
                                                                <td>{item.employee.firstName} {item.employee.lastName} ({item.employee.firstName} {item.employee.email})</td>
                                                                <td onClick={!this.props.accesses.approve ? null : () => { this.editSuiviStatus(item.id) }} style={{ cursor: 'pointer'}}>
                                                                    {item.status === "Processing"  && "Traitement" }
                                                                    {item.status === "Approved"  && "Approuvé" }
                                                                    {item.status === "Disaproved"  && "Refusé" }
                                                                </td>
                                                                <td>{item.description} </td>
                                                                <td>
                                                                    {item.documents.length > 0 &&
                                                                        item.documents.map((elem) => {
                                                                            return(
                                                                                <Link color="inherit" href={elem.file} target={"_blank"} style={{textDecoration: 'none', marginRight: 4 }}>
                                                                                    <VisibilityIcon fontSize="small" />
                                                                                </Link>
                                                                            )
                                                                        })
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.completed ? 
                                                                        <Tooltip title="Non complété">
                                                                            <IconButton disabled={item.status !== "Approved"} onClick={() => this.makeComplete(item.id)}>   
                                                                                <Switch checked={true} />
                                                                            </IconButton> 
                                                                        </Tooltip>  
                                                                    :    
                                                                        <Tooltip title="Complété">
                                                                            <IconButton disabled={item.status !== "Approved"} onClick={() => this.makeComplete(item.id)}>   
                                                                                <Switch checked={false} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    }     
                                                                </td>
                                                                
                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.write}
                                                                        onClick={() => { this.editSuivi(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'#306bff', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                    >
                                                                        Modifier
                                                                    </Button>
                                                                </td>                                                                

                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove}
                                                                        onClick={() => { this.removeSuivi(item.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>        
                                                        )
                                                    })
                                                }                             
                                            </tbody>
                                        </table>
                                    </TabPanel>

                                    <TabPanel value="5" sx={{p:2, minHeight:"60vh"}}>
                                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                            <Grid item xs={12} sm={12} md={12} style={{textAlign:'right'}}>
                                                <Button 
                                                    variant="contained" 
                                                    disabled={!this.props.accesses.write}
                                                    onClick={() => this.setState({addFile: true})}
                                                    sx={{mr:2, boxShadow: 0, borderRadius:1, bgcolor: '#306bff', fontSize: 12, textTransform: 'none'}}
                                                >
                                                    Ajouter des documents
                                                </Button>
                                            </Grid> 
                                        </Grid> 

                                        <table className="table table-hover" style={{marginTop:20, fontSize: 12}}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Nom du document</th>
                                                    <th scope="col">Statut</th>
                                                    <th scope="col">Voir</th>
                                                    {/* <th scope="col">Modifier</th>                                         */}
                                                    <th scope="col">Supression</th>
                                                </tr>
                                            </thead>

                                            <tbody>
                                                {documents.length > 0 &&
                                                    documents.map((elem) => {
                                                        return(
                                                            <tr>
                                                                <td>{elem.name}</td>
                                                                <td onClick={!this.props.accesses.approve ? null : () => { this.editDocStatus(elem.id) }}>
                                                                    {elem.status === "Processing"  && "Traitement" }
                                                                    {elem.status === "Approved"  && "Approuvé" }
                                                                    {elem.status === "Disaproved"  && "Refusé" }
                                                                </td>
                                                                <td>
                                                                    <Link color="inherit" href={elem.extratLink ? elem.file : elem.file} target={"_blank"} style={{textDecoration: 'none', }}>
                                                                        <Button
                                                                            variant="contained"
                                                                            sx={{background:'#306bff', boxShadow: 0, color:'white', height:30, fontSize: 12, textTransform: 'none', ':hover':{background:'#306bff', color:'white'}}}
                                                                        >
                                                                            Voir la pièce jointe
                                                                        </Button>
                                                                    </Link>
                                                                </td>

                                                                <td>
                                                                    <Button
                                                                        disabled={!this.props.accesses.remove} 
                                                                        onClick={() => { this.removeDoc(elem.id) }}
                                                                        variant="contained"
                                                                        sx={{background:'red', boxShadow: 0, textTransform: 'none', color:'white', height:30, ':hover':{background:'red', color:'white'}}}
                                                                    >
                                                                        Supprimer
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }                          
                                            </tbody>
                                        </table>
                                    </TabPanel>
                                </TabContext>                                
                            </Grid>
                        </Grid>
                    </DialogContent >
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editStatus}
                    onClose={() => this.setState({editStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer l'état d'un projet
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="État du projet"
                                        name="status"
                                        style={{ width: '100%' }}
                                        value={project.status}
                                        onChange={this.handleProjectChange}
                                        size="small"
                                    >
                                        <MenuItem key="En attente" value="En attente">En attente</MenuItem>
                                        <MenuItem key="En cours" value="En cours">En cours</MenuItem>
                                        <MenuItem key="Terminer" value="Terminer">Terminer</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addFile}
                    onClose={() => this.setState({addFile: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter le(s) fichier(s)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addFile: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    id="outlined-required"
                                    label="Nom du document"
                                    name="name"
                                    style={{ width: '100%' }}
                                    value={projectDoc.name}
                                    onChange={this.handleProjectDocumentChange}
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    select
                                    label="Option"
                                    name="option"
                                    style={{ width: '100%' }}
                                    value={projectDoc.option}
                                    onChange={this.handleProjectDocumentChange}
                                    size="small"
                                >
                                    <MenuItem key="link" value="link">Lien</MenuItem>
                                    <MenuItem key="File" value="File">Fichier</MenuItem>
                                </TextField>
                            </Grid> 

                            {projectDoc.option === "link" &&
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        id="outlined-required"
                                        label="Lien du document"
                                        name="link"
                                        style={{ width: '100%' }}
                                        value={projectDoc.link}
                                        onChange={this.handleProjectDocumentChange}
                                        size="small"
                                    />
                                </Grid>
                            }
                            {projectDoc.option !== "link" &&
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.projectDocumentDrop(acceptedFiles, 'projectDocument')}>
                                        {({getRootProps, getInputProps}) => (
                                            <section style={{ textAlign: 'center'}}>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} accept="image/*, application/pdf" />

                                                    {projectDoc.docs === null &&
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                        </React.Fragment>
                                                    }
                                                    {projectDoc.docs !== null &&
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  /> 
                                                            {projectDoc.docs.length > 0 &&
                                                                projectDoc.docs.map((doc,key)=>{
                                                                return(
                                                                    <div>
                                                                        <span style={{textTransform: 'none'}}>{doc?.name || 'Inconnu'}</span>
                                                                    </div>
                                                                )
                                                            }) }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )}
                                        {/* {({getRootProps, getInputProps}) => (
                                            <section>
                                                <div {...getRootProps()}>
                                                    <input {...getInputProps()} />

                                                    {selectedFile.length === 0 ? 
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                            <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                        </React.Fragment>
                                                    :
                                                        <React.Fragment>
                                                            <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                            {selectedFile.length > 0 &&
                                                                selectedFile.map((item) => {
                                                                    return(
                                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                            {item.path}
                                                                        </Typography>
                                                                    )
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            </section>
                                        )} */}
                                    </Dropzone>
                                </Grid>
                            }
                        </Grid>
                    </DialogContent>

                    <DialogActions>                        
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={() => this.handleSubmitPJ()}
                        >
                            Soumettre le(s) fichier(s)
                        </Button>
                    </DialogActions>
                </Dialog>                

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addDocSuivi}
                    onClose={() => this.setState({addDocSuivi: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter le(s) fichier(s)
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addDocSuivi: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    select
                                    label="Plan de suivi"
                                    name="action"
                                    style={{ width: '100%' }}
                                    value={actionPlan.action}
                                    onChange={this.handleActionPlanChange}
                                    size="small"
                                >
                                    {actionPlans.map((answer) => (
                                        <MenuItem key={answer.id} value={answer.id}>{answer.action}</MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    label="Nom du document"
                                    name="name"
                                    style={{ width: '100%' }}
                                    value={actionPlan.name}
                                    onChange={this.handleActionPlanChange}
                                    size="small"
                                />
                            </Grid>
                            
                            <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                <Dropzone onDrop={acceptedFiles  => this.projectDocumentDrop(acceptedFiles, 'actionPlan')}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{ textAlign: 'center'}}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*, application/pdf" />

                                                {actionPlan.docs === null &&
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                    </React.Fragment>
                                                }
                                                {actionPlan.docs !== null &&
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  /> 
                                                        {actionPlan.docs.length > 0 &&
                                                            actionPlan.docs.map((doc,key)=>{
                                                            return(
                                                                <div>
                                                                    <span style={{textTransform: 'none'}}>{doc?.name || 'Inconnu'}</span>
                                                                </div>
                                                            )
                                                        }) }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>                        
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={() => this.handleSubmitActionDoc()}
                        >
                            Soumettre le(s) fichier(s)
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addBudget}
                    onClose={() => this.setState({addBudget: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_lineBudgetOut ? `Modifier une ligne de budgétaire | Sortie` : `Ajouter une ligne de budgétaire | Sortie`}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addBudget: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <form onSubmit={this.handleSubmitLinebudget} >
                        <DialogContent dividers>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Nom de la ligne budgétaire"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.name}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Type"
                                        name="type"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.type}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    >
                                        <MenuItem key="Personnel" value="Personnel">Personnel</MenuItem>
                                        <MenuItem key="Équipement" value="Équipement">Équipement</MenuItem>
                                        <MenuItem key="Fonctionnement" value="Fonctionnement">Fonctionnement</MenuItem>
                                        <MenuItem key="Activités" value="Activités">Activités</MenuItem>
                                        <MenuItem key="Autres" value="Autres">Autres</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Coût unitaire"
                                        name="unitCost"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.unitCost}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>                                    
                                    <TextField
                                        label="Date"
                                        type="date"
                                        name="date"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.date}
                                        onChange={this.handleLineBudgetOutChange} 
                                        size="small"
                                    />
                                </Grid> 

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Quantité"
                                        name="quantity"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.quantity}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>
                                
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Contre partie du financement"
                                        name="contrepartie"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.contrepartie}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        label="Fournisseur ou mode d'acquisition"
                                        name="provider"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.provider}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        required
                                        label="Banque"
                                        name="bankId"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.bankId}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    >
                                        {
                                            banks.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>{answer.name} | {answer.account}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Mode de réglement"
                                        name="mode"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.mode}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    >
                                        <MenuItem key="Check" value="Check">Chèque</MenuItem>
                                        <MenuItem key="Cash" value="Cash">Espèces</MenuItem>
                                        <MenuItem key="ElectronicPayment" value="ElectronicPayment">Paiement électronique</MenuItem>
                                        <MenuItem key="Paiement mobile" value="MobilePayment">Paiement mobile</MenuItem>
                                        <MenuItem key="Ordre de transfert" value="TransferOrder">Ordre de virement</MenuItem>
                                        <MenuItem key="Autre" value="Other">Autre</MenuItem>
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl  style={{ width: '100%' }}>
                                        <InputLabel id="demo-simple-select-helper-label" style={{top:'-8px'}}>Partenaires</InputLabel>
                                        <Select
                                            multiple
                                            required
                                            label="Partenaires"
                                            name="partners"
                                            style={{ width: '100%' }}
                                            value={lineBudgetOut.partners}
                                            renderValue={(selected) => displaythem(selected)}
                                            onChange={this.handleLineBudgetOutChange}
                                            size="small"
                                        >
                                            {partners.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>
                                                    <Checkbox checked={lineBudgetOut.partners.indexOf(answer.id) > -1} sx={{color:'#2f4cdd', '&.Mui-checked': {color:'#2f4cdd'}}} />
                                                    <ListItemText primary={answer.name} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        label="Commentaires"
                                        name="comments"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.comments}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                        multiline
                                        rows={3}
                                    />
                                </Grid>

                                {!id_lineBudgetOut &&
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        label="Nom du document, si le document est selectionné"
                                        name="documentName"
                                        style={{ width: '100%' }}
                                        value={lineBudgetOut.documentName}
                                        onChange={this.handleLineBudgetOutChange}
                                        size="small"
                                    />
                                </Grid> }                          
                                
                                {!id_lineBudgetOut &&
                                <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                    <Dropzone onDrop={acceptedFiles  => this.projectDocumentDrop(acceptedFiles, "lineBudgetOut")}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{ textAlign: 'center'}}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*, application/pdf" />

                                                {lineBudgetOut.docs === null &&
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                    </React.Fragment>
                                                }
                                                {lineBudgetOut.docs !== null &&
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  /> 
                                                        {lineBudgetOut.docs.length > 0 &&
                                                            lineBudgetOut.docs.map((doc,key)=>{
                                                            return(
                                                                <div>
                                                                    <span style={{textTransform: 'none'}}>{doc?.name || 'Inconnu'}</span>
                                                                </div>
                                                            )
                                                        }) }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )}
                                    </Dropzone>
                                </Grid>}
                            </Grid>
                        </DialogContent>

                        <DialogActions>                        
                            <Button 
                                variant="contained"
                                fullWidth
                                type="submit" 
                                ///onClick={() => this.handleSubmitLinebudget()}
                            >
                                
                                {id_lineBudgetOut ? `Cliquer pour modifier` : `Cliquer pour ajouter`}
                            </Button>
                        </DialogActions>    
                    </form>

                    
                </Dialog>                 

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addBudgetEn}
                    onClose={() => this.setState({addBudgetEn: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_lineBudgetEntry ? "Modifier une ligne budgétaire | Entrée" : "Ajouter une ligne budgétaire | Entrée"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addBudgetEn: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <form onSubmit={this.handleSubmitLinebudgetEntry} >
                        <DialogContent dividers>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12} md={16}>
                                    <TextField
                                        required
                                        label="Nom de la ligne budgétaire"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={lineBudgetEntry.name}
                                        onChange={this.handleLineBudgetEntryChange}
                                        size="small"
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        type="number"
                                        label="Montant"
                                        name="amount"
                                        style={{ width: '100%' }}
                                        value={lineBudgetEntry.amount}
                                        onChange={this.handleLineBudgetEntryChange}
                                        size="small"
                                        inputProps={{
                                            maxLength: 13,
                                            step: 1,
                                            min: 0
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>                                    
                                    <TextField
                                        label="Date"
                                        type="date"
                                        name="date"
                                        style={{ width: '100%' }}
                                        value={lineBudgetEntry.date}
                                        onChange={this.handleLineBudgetEntryChange} 
                                        size="small"
                                    />
                                </Grid> 

                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        required
                                        label="Banque"
                                        name="bankId"
                                        style={{ width: '100%' }}
                                        value={lineBudgetEntry.bankId}
                                        onChange={this.handleLineBudgetEntryChange}
                                        size="small"
                                    >
                                        {
                                            banks.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>{answer.name} | {answer.account}</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        required
                                        select
                                        label="Mode de réglement"
                                        name="mode"
                                        style={{ width: '100%' }}
                                        value={lineBudgetEntry.mode}
                                        onChange={this.handleLineBudgetEntryChange}
                                        size="small"
                                    >
                                        <MenuItem key="Check" value="Check">Chèque</MenuItem>
                                        <MenuItem key="Cash" value="Cash">Espèces</MenuItem>
                                        <MenuItem key="ElectronicPayment" value="ElectronicPayment">Paiement électronique</MenuItem>
                                        <MenuItem key="Paiement mobile" value="MobilePayment">Paiement mobile</MenuItem>
                                        <MenuItem key="Ordre de transfert" value="TransferOrder">Ordre de virement</MenuItem>
                                        <MenuItem key="Autre" value="Other">Autre</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <DialogActions>                        
                            <Button 
                                variant="contained"
                                fullWidth
                                type="submit"
                            >
                                
                                {id_lineBudgetEntry ? "Cliquer pour modifier" : "Cliquer pour ajouter"}
                            </Button>
                        </DialogActions>    
                    </form>
                </Dialog> 

{/* todo */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editDocStatus}
                    onClose={() => this.setState({editDocStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut du document
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editDocStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitDocStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de l'heure suplementaire"
                                        name="status"
                                        style={{ width: '100%' }}
                                        value={status}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addMember}
                    onClose={() => this.setState({addMember: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Ajouter un employé
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addMember: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitMember} style={{marginTop:-20}}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Employé"
                                        name="employeeId"
                                        style={{ width: '100%' }}
                                        value={employeeId}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        {
                                            employees.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>{answer.firstName} {answer.lastName} ({answer.email})</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Rôle de l'employé"
                                        name="role"
                                        style={{ width: '100%' }}
                                        value={role}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour ajouter
                        </Button>
                    </form>
                </Dialog>                

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={editMember}
                    onClose={() => this.setState({editMember: false})}
                    scroll={'paper'}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Modifier un employé
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editMember: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitEditMember} style={{marginTop:-20}}>
                        <DialogContent dividers={true}>
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Employé"
                                        name="employeeId"
                                        style={{ width: '100%' }}
                                        value={employeeId}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        {
                                            employees.map((answer) => (
                                                <MenuItem key={answer.id} value={answer.id}>{answer.firstName} {answer.lastName} ({answer.email})</MenuItem>
                                            ))
                                        }
                                    </TextField>
                                </Grid>
                            
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        required
                                        id="outlined-required"
                                        label="Rôle de l'employé"
                                        name="role"
                                        style={{ width: '100%' }}
                                        value={role}
                                        onChange={this.handleChange}
                                        size="small"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                                Cliquer pour modifier
                        </Button>
                    </form>
                </Dialog>                

{/* ACtion done */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={addSuivi}
                    onClose={() => this.setState({addSuivi: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            {id_actionPlan ? "Modifier une action" : "Ajouter une action"}
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addSuivi: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    required
                                    label="Action"
                                    name="action"
                                    style={{ width: '100%' }}
                                    value={actionPlan.action}
                                    onChange={this.handleActionPlanChange}
                                    size="small"
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField
                                    required
                                    select
                                    label="Employé"
                                    name="employeeId"
                                    style={{ width: '100%' }}
                                    value={actionPlan.employeeId}
                                    onChange={this.handleActionPlanChange}
                                    size="small"
                                >
                                    {
                                        employees.map((answer) => (
                                            <MenuItem key={answer.id} value={answer.id}>{answer.firstName} {answer.lastName} ({answer.email})</MenuItem>
                                        ))
                                    }
                                </TextField>
                            </Grid>

                            {!id_actionPlan && 
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        label="Nom du document"
                                        name="name"
                                        style={{ width: '100%' }}
                                        value={actionPlan.name}
                                        onChange={this.handleActionPlanChange}
                                        size="small"
                                    />
                                </Grid>
                            }
                             
                            {!id_actionPlan && 
                            <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                <Dropzone onDrop={acceptedFiles  => this.projectDocumentDrop(acceptedFiles, 'actionPlan')}>
                                    {({getRootProps, getInputProps}) => (
                                        <section style={{ textAlign: 'center'}}>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} accept="image/*, application/pdf" />

                                                {actionPlan.docs === null &&
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                    </React.Fragment>
                                                }
                                                {actionPlan.docs !== null &&
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  /> 
                                                        {actionPlan.docs.length > 0 &&
                                                            actionPlan.docs.map((doc,key)=>{
                                                            return(
                                                                <div>
                                                                    <span style={{textTransform: 'none'}}>{doc?.name || 'Inconnu'}</span>
                                                                </div>
                                                            )
                                                        }) }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )}
                                    {/* {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />

                                                {selectedFile.length === 0 ? 
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez le(s) fichier(s) ici, ou cliquez pour sélectionner le fichier</p>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                        {selectedFile.length > 0 &&
                                                            selectedFile.map((item) => {
                                                                return(
                                                                    <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                                        {item.path}
                                                                    </Typography>
                                                                )
                                                            })
                                                        }
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )} */}
                                </Dropzone>
                            </Grid>
                            }

                            <Grid item xs={12} md={12}>
                                <TextField
                                    label="Description du projet"
                                    name="description"
                                    style={{ width: '100%' }}
                                    value={actionPlan.description}
                                    onChange={this.handleActionPlanChange}
                                    size="small"
                                    multiline
                                    rows={4}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>                        
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={() => this.handleSubmitSuivi()}
                        >
                            Soumettre une action
                        </Button>
                    </DialogActions>
                </Dialog>

{/* todo */}
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editSuiviStatus}
                    onClose={() => this.setState({editSuiviStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut du plan de suivi
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editSuiviStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitsuiviStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de l'heure suplementaire"
                                        name="status"
                                        style={{ width: '100%' }}
                                        value={status}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog> 

                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editBudgetEnStatus}
                    onClose={() => this.setState({editBudgetEnStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut de la ligne budgétaire
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editBudgetEnStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitBudgetStatus} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de l'heure suplementaire"
                                        name="status"
                                        style={{ width: '100%' }}
                                        value={status}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog> 
                
                <Dialog
                    fullWidth={true}
                    maxWidth={"md"}
                    open={editBudgetStatus}
                    onClose={() => this.setState({editBudgetStatus: false})}
                >
                    <Box style={{display: 'flex', flexDirection:'row', justifyContent:'space-between', padding:32}} >
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Changer le statut de la ligne budgétaire
                        </Typography>

                        <IconButton onClick={() =>  this.setState({editBudgetStatus: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </Box>

                    <form onSubmit={this.handleSubmitBudgetStatusOut} style={{marginTop:-40}}>
                        <DialogContent >
                            <Grid sx={{ flexGrow: 1 }} container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        select
                                        label="Statut de l'heure suplementaire"
                                        name="status"
                                        style={{ width: '100%' }}
                                        value={status}
                                        onChange={this.handleChange}
                                        size="small"
                                    >
                                        <MenuItem key="Processing" value="Processing">Traitement</MenuItem>
                                        <MenuItem key="Approved" value="Approved">Approuvé</MenuItem>
                                        <MenuItem key="Disaproved" value="Disaproved">Refusé</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                        </DialogContent >

                        <Button  
                            type="submit" 
                            fullWidth
                            variant="contained" 
                            sx={{
                                mt:2,
                                color:"white", 
                                textTransform: 'none', 
                                fontSize:20, 
                                height:50, 
                                backgroundColor:'#888da0',  
                                borderRadius:0, 
                                ':hover':{backgroundColor:'#306bff'} 
                            }}
                        >
                            Cliquer pour changer
                        </Button>   
                    </form>
                </Dialog>

                <Dialog
                    fullWidth={true}
                    maxWidth={"sm"}
                    open={addImport}
                    onClose={() => this.setState({addImport: false})}
                    scroll='paper'    
                >
                    <DialogTitle id="scroll-dialog-title" style={{display: 'flex', flexDirection:'row', justifyContent:'space-between',}}>
                        <Typography 
                            component="h1" 
                            variant="h1" 
                            sx={{color:"#313339", fontSize:26}}
                        >
                            Importer des lignes budgétaires
                        </Typography>

                        <IconButton onClick={() =>  this.setState({addImport: false })} >   
                            <CloseIcon /> 
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <Grid sx={{ flexGrow: 1 }} container spacing={2}>                             
                            <Grid item xs={12} md={12} style={{textAlign: "center"}}> 
                                <Dropzone onDrop={acceptedFiles  => this.fileDropImport(acceptedFiles)}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />

                                                {selectedFile.length === 0 ? 
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"gray"}}  />
                                                        <p>Faites glisser et déposez le fichier ici, ou cliquez pour sélectionner le fichier</p>
                                                    </React.Fragment>
                                                :
                                                    <React.Fragment>
                                                        <DescriptionIcon sx={{ fontSize: 140, color:"#306bff"}}  />
                                                        
                                                        <Typography component="p" variant="p" color="text.secondary" sx={{}}>
                                                            {selectedFile.path}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>                        
                        <Button 
                            variant="contained"
                            fullWidth
                            onClick={() => this.handleSubmitImport()}
                        >
                            Importer le fichier
                        </Button>
                    </DialogActions>
                </Dialog>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorEl1}
                    open={Boolean(anchorEl1)}
                    onClose={() => {this.setState({ anchorEl1: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={start}
                        onChange={this.handleSelect1}
                        locale={locales['fr']}
                    />
                </Menu>

                <Menu
                    elevation={12}
                    id="basic-menu"
                    anchorEl={anchorEl2}
                    open={Boolean(anchorEl2)}
                    onClose={() => {this.setState({ anchorEl2: null})}}
                    PaperProps={{
                        sx: {
                            mt:4,
                            ml: '-16px',
                        }
                    }}
                >
                    <Calendar
                        date={end}
                        onChange={this.handleSelect2}
                        locale={locales['fr']}
                        minDate={start}
                    />
                </Menu>

                <Backdrop sx={{ color: '#000000', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>                
            </React.Fragment>
        )

    }

}