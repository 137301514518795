import React, { Component } from "react";
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import axios from "axios";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export default class ProjectSelecter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            projectID: null,
            anchorEl: null,
        }
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        const token = JSON.parse(localStorage.getItem("TOKEN"))
        const config = {
            headers: {'Authorization': 'Bearer '+token}
        };
        axios.get('/api/v1/projects', config)
        .then(response => {            
            this.setState({ 
                projects: response.data
            });
        }).catch(error => {
            console.log("error", error)
        });      
    }

    handleClick(event){
        this.setState({anchorEl: event.currentTarget})
    }

    selectProject(id){
        this.setState(
            {
                projectID:  id,
                anchorEl: null
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(id)
                }
            }        
        )
    }

    render() { 

        const {projects, projectID, anchorEl} = this.state

        let  projet = ""
        if(projectID === null){projet = "Tous les projets"}
        if(projects.filter(c => c["id"] === projectID)[0] !== undefined){
            projet = projects.filter(c => c["id"] === projectID)[0].name
        }

        return(
            <React.Fragment>
                <Card elevation={0}  sx={{ px: 2, py:1, backgroundColor:'#ffffff', borderRadius:3, display: 'flex', alignItems: 'center'}}>
                    <Typography >Projet: <span style={{color:'#306bff', fontSize: 12}}>{projet}</span></Typography>

                    <Box sx={{ flexGrow: 1 }} />

                    <IconButton onClick={this.handleClick}>
                        <ExpandMoreIcon sx={{color:'#306bff', fontSize: 18}}/>
                    </IconButton>

                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => {this.setState({ anchorEl: null})}}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                        PaperProps={{
                            sx: {
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 2,
                                ml: -2
                            }
                        }}
                    >
                        <MenuItem 
                            onClick={() =>this.selectProject(null)} 
                            sx={projectID === null ? {color:'#306bff'} : { fontSize: 12 }}
                        >
                            Tous les projets
                        </MenuItem>

                        {projects.map((elem) => (
                            <MenuItem 
                                onClick={() =>this.selectProject(elem.id)} 
                                sx={projectID === elem.id ? {color:'#306bff', fontSize: 12} : {fontSize: 12}}
                            >
                                {elem.name}
                            </MenuItem>
                        ))}
                    </Menu>
                </Card>
            </React.Fragment>
        )
    }

}