import React, { Component } from "react";
import { BrowserRouter, Routes, Route, Link} from 'react-router-dom'; 
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import PersonIcon from '@mui/icons-material/Person';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import FolderIcon from '@mui/icons-material/Folder';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import EngineeringIcon from '@mui/icons-material/Engineering';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import InventoryIcon from '@mui/icons-material/Inventory';
import MoveToInboxIcon from '@mui/icons-material/MoveToInbox';
import ReceiptIcon from '@mui/icons-material/Receipt';
import TimelineIcon from '@mui/icons-material/Timeline';
import axios from 'axios';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import Dashboard from '../Pages/Dashboard'
import Projects from '../Pages/Projects'
import Banks from '../Pages/Banks'
import Registers from '../Pages/Registers'
import Invoices from '../Pages/Invoices'
import Members from '../Pages/Members'
import Accounting from '../Pages/Accounting'
import Employes from '../Pages/Employes'
import Sanction from '../Pages/Sanction'
import Conge from '../Pages/Conge'
import Partners from "../Pages/Partners"
import Providers from '../Pages/Providers'
import Csociales from '../Pages/Csociales'
import Roles from '../Pages/Roles'
import Events from '../Pages/Events'
import Letters from '../Pages/Letters'
import Users from '../Pages/Users'
import Logs from '../Pages/Logs'
import Profil from '../Pages/Profil'

const drawerWidth = 240;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

export default class Layout extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open:true,
            loading: true,

            openAccounting: false,
            openSetting: false,

            name: "",
            accesses: [],

            seleted:"",

            pagetitle: "Dashboard",

            isDid: false,
            isNotDit: false,
            message:"",
        }
        this.componentDidMount = this.componentDidMount.bind(this)
    }

    componentDidMount() {
        this.setUrl(window.location.pathname)
        const accesses = JSON.parse(localStorage.getItem("ACCESSES"));
        const name = JSON.parse(localStorage.getItem("NAME"))
        // const id = JSON.parse(localStorage.getItem("ID"));
        // const token = JSON.parse(localStorage.getItem("TOKEN"))
        // const config = {
        //     headers: {'Authorization': 'Bearer '+token}
        // };
        // axios.get('/api/v1/users/' + id, config)
        // .then(response => {            
        //     this.setState({ 
        //         accesses: response.data.role.accesses,
        //         name: response.data.name,
        //         loading: false
        //     });
        // }).catch(error => {
        //     this.setState({ message: error.response.data?.message || "Une erreur inattendue s'est produite", isNotDit: true, loading: false });
        //     window.location.href = '/';
        // }); 

        if (window.innerWidth > 800) {
            this.setState({ open: true })
        } else {
            this.setState({ open: false })
        }

        this.setState({ name: name ? name : '', accesses: accesses ? accesses : [], loading: false });

    }

    setUrl(item) {
        this.setState({seleted: item})
        if (item === "/dashboard") {
            this.setState({ pagetitle: "Dashboard" })
        }
        else if (item === "/banks") {   
            this.setState({ pagetitle: "Gestion des banques"})
        }
        else if (item === "/registers") {   
            this.setState({ pagetitle: "Gestion des caisse"})
        }
        else if (item === "/invoices") {   
            this.setState({ pagetitle: "Gestion des factures"})
        }
        else if (item === "/csociales") {   
            this.setState({ pagetitle: "cotisations sociales"})
        }
        else if (item === "/employes") {   
            this.setState({ pagetitle: "Gestion des ressources humaines"})
        }
        else if (item === "/sanction") {   
            this.setState({ pagetitle: "Sanctions disciplinaire"})
        }     
        else if (item === "/conge") {   
            this.setState({ pagetitle: "Types de congés"})
        }  
        else if (item === "/partners") {   
            this.setState({ pagetitle: "Gestion des partenaires techniques et financiers"})
        }
        else if (item === "/providers") {   
            this.setState({ pagetitle: "Gestion des fournisseurs"})
        }
        else if (item === "/roles") {   
            this.setState({ pagetitle: "Rôles"})
        }
        else if (item === "/events") {   
            this.setState({ pagetitle: "Gestion des Événements"})
        }
        else if (item === "/letters") {   
            this.setState({ pagetitle: "Gestion des Courriers"})
        }
        else if (item === "/users") {   
            this.setState({ pagetitle: "Gestion des utilisateurs"})
        } 
        else if (item === "/projects") {   
            this.setState({ pagetitle: "Liste des projets"})
        }  
        else if (item === "/members") {   
            this.setState({ pagetitle: "Membres du projet"})
        } 
        else if (item === "/accounting") {   
            this.setState({ pagetitle: "La comptabilité"})
        }         
        else if (item === "/logs") {   
            this.setState({ pagetitle: "Traces des activites des utilisateurs"})
        } 
        else if (item === "/profil") {   
            this.setState({ pagetitle: "Gérer votre compte"})
        }  

        if (window.innerWidth > 800) {
            this.setState({ open: true })
        } else {
            this.setState({ open: false })
        }
    }

    logout() {
        localStorage.clear();
        window.location.href = '/';
    }  

    render() {

        const {
            name,
            accesses,
            open,
            loading,
            pagetitle,
            seleted,
            openAccounting,
            openSetting,

            isDid,
            isNotDit,
            message,
        } = this.state

        console.log("accesses", accesses.find(e=>e.module === 'Dashboard'))

        const dashboard = accesses.find(e=>e.module === 'Dashboard')
        const project = accesses.find(e=>e.module === 'Gestion des Projets')
        const bank = accesses.find(e=>e.module === 'Gestion des banques')
        const cash = accesses.find(e=>e.module === 'Gestion des caisses')
        const invoice = accesses.find(e=>e.module === 'Facturation')
        const rh = accesses.find(e=>e.module === 'Gestion RH')
        const letter = accesses.find(e=>e.module === 'Gestion des courriers')
        const event = accesses.find(e=>e.module === 'Gestion des événements')
        const user = accesses.find(e=>e.module === 'Gestion des utilisateurs')
        const role = accesses.find(e=>e.module === 'Gestion des rôles')
        const contribution = accesses.find(e=>e.module === 'Cotisations sociales')
        const discipline = accesses.find(e=>e.module === 'Sanctions disciplinaire')
        const vacation = accesses.find(e=>e.module === 'Types de congés')
        const partner = accesses.find(e=>e.module === 'Gestion des partenaires techniques et financiers')
        const provider = accesses.find(e=>e.module === 'Gestion des fournisseurs')
        const log = accesses.find(e=>e.module === 'Traces des activites des utilisateurs')

        const ORG_NAME = window.ORG_NAME;

        return (
            <BrowserRouter >
                <Box sx={{ display: 'flex', backgroundColor:'#ecf0f5', minHeight:"100vh" }}>
                    <CssBaseline />

                    <AppBar position="fixed" open={open} elevation={24}>
                        <Toolbar>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={() => this.setState({open: !open})}
                                edge="start"
                                sx={{
                                marginRight: 5,
                                ...(open && { display: 'none' }),
                                }}
                            >
                                <MenuIcon sx={{fontSize:32, color:"white"}} />
                            </IconButton>

                            <Typography component="h2" variant="h2" fontSize={12} noWrap >{pagetitle}</Typography>

                            <Box sx={{ flexGrow: 1 }} />

                            <Avatar sx={{boxShadow: 12, bgcolor: 'white', mr:1, color:"#306bff" }} elevation={10}>{name.charAt(0)}</Avatar>

                            <Typography variant="p" noWrap component="div" mr={2}>{name}</Typography>

                            <Tooltip title="Se deconnecter">
                                <IconButton color="primary" component="span" onClick={() => this.logout()} >
                                    <FingerprintIcon  sx={{fontSize:32, color:"white"}} />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </AppBar>

                    <Drawer variant="permanent" open={open}>
                        <DrawerHeader>
                            <Typography component="h1" variant="h5" noWrap color="text.secondary" sx={{textAlign: 'center', width:'100%', fontWeight: 800}}>
                                {ORG_NAME}
                            </Typography>

                            <IconButton onClick={() => this.setState({open: !open})}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </DrawerHeader>

                        <Divider sx={{bgcolor:'black'}} />

                        {!loading ?                        
                            <List>
                                {dashboard && dashboard.read &&
                                    <Link to="/dashboard"  onClick={() => this.setUrl("/dashboard")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'Dashboard'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                    fontSize: 12
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                        fontSize: 20
                                                    }}
                                                >
                                                    <DonutLargeIcon fontSize="12" sx={seleted === "/dashboard" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Dashboard'} primaryTypographyProps={{fontSize: 12}} sx={seleted === "/dashboard" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                {project && project.read &&
                                    <Link to="/projects"  onClick={() => this.setUrl("/projects")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'projects'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                        fontSize: 20
                                                    }}
                                                >
                                                    <FolderIcon fontSize="12" sx={seleted === "/projects" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Gestion Projets'} primaryTypographyProps={{fontSize: 12}} sx={seleted === "/projects" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                {(bank || cash || invoice) &&
                                    <ListItem key={'openAccounting'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton 
                                            onClick={() => this.setState({openAccounting: !openAccounting})}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 1.5 : 'auto',
                                                    justifyContent: 'center',
                                                    fontSize: 20
                                                }}
                                            >
                                                <AccountBalanceWalletIcon fontSize="12" sx={(seleted === "/banks" || seleted === "/registers" | seleted === "/banks" | seleted === "/banks") ? {color:"#306bff"} : null}/>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Comptabilité'} sx={(seleted === "/banks" || seleted === "/registers" | seleted === "/banks" | seleted === "/banks") ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            {openAccounting ? <ExpandLess sx={(seleted === "/banks" || seleted === "/registers" | seleted === "/banks" | seleted === "/banks") ? {color:"#306bff"} : null} /> : <ExpandMore sx={(seleted === "/banks" || seleted === "/banks" | seleted === "/registers" | seleted === "/banks") ? {color:"#306bff"} : null} />}
                                        </ListItemButton> 
                                    </ListItem>
                                }

                                <Collapse in={openAccounting} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding> 
                                        {bank && bank.read &&
                                            <Link to="/banks" onClick={() => this.setUrl("/banks")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'banks'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <AccountBalanceIcon fontSize="12" sx={seleted === "/banks" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Gestion Banques" sx={seleted === "/banks" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {cash && cash.read &&
                                            <Link to="/registers" onClick={() => this.setUrl("/registers")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'roles'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <InventoryIcon fontSize="12" sx={seleted === "/registers" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Gestion Caisse" sx={seleted === "/registers" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {invoice && invoice.read &&
                                            <Link to="/invoices" onClick={() => this.setUrl("/invoices")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'invoices'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <ReceiptIcon  fontSize="12" sx={seleted === "/invoices" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Gest. des factures" sx={seleted === "/invoices" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }
                                    </List>
                                </Collapse>

                                {rh && rh.read &&
                                    <Link to="/employes"  onClick={() => this.setUrl("/employes")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'employes'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                        fontSize: 20
                                                    }}
                                                >
                                                    <Diversity3Icon fontSize="12" sx={seleted === "/employes" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Gestion RH'}  sx={seleted === "/employes" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                {letter && letter.read &&
                                    <Link to="/letters"  onClick={() => this.setUrl("/letters")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'letters'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <MoveToInboxIcon fontSize="12" sx={seleted === "/letters" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Gestion des courriers'}  sx={seleted === "/letters" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                {event && event.read &&          
                                    <Link to="/events"  onClick={() => this.setUrl("/events")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'events'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                        fontSize: 20
                                                    }}
                                                >
                                                    <EventAvailableIcon fontSize="12" sx={seleted === "/events" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Gestion Événements'}  sx={seleted === "/events" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                {user && user.read &&
                                    <Link to="/users"  onClick={() => this.setUrl("/users")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'users'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                        fontSize: 20
                                                    }}
                                                >
                                                    <PeopleAltIcon fontSize="12" sx={seleted === "/users" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Utilisateurs'}  sx={seleted === "/users" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                {(role|| contribution || discipline || vacation || partner || provider) &&
                                    <ListItem key={'openSetting'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton 
                                            onClick={() => this.setState({openSetting: !openSetting})}
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 1.5 : 'auto',
                                                    justifyContent: 'center',
                                                    fontSize: 20
                                                }}
                                            >
                                                <SettingsIcon fontSize="12" sx={(seleted === "/roles" || seleted === "/csociales" | seleted === "/sanction" | seleted === "/conge" | seleted === "/partners" | seleted === "/providers") ? {color:"#306bff"} : null}/>
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Paramètres'} sx={(seleted === "/roles" || seleted === "/csociales" | seleted === "/sanction" | seleted === "/conge" | seleted === "/partners" | seleted === "/providers") ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            {openSetting ? <ExpandLess sx={(seleted === "/roles" || seleted === "/csociales" | seleted === "/sanction" | seleted === "/conge" | seleted === "/partners" | seleted === "/providers") ? {color:"#306bff"} : null} /> : <ExpandMore sx={(seleted === "/roles" || seleted === "/csociales" | seleted === "/sanction" | seleted === "/conge" | seleted === "/partners" | seleted === "/providers") ? {color:"#306bff"} : null} />}
                                        </ListItemButton> 
                                    </ListItem>
                                }

                                <Collapse in={openSetting} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {role && role.read &&
                                            <Link to="/roles" onClick={() => this.setUrl("/roles")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'roles'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <ManageAccountsIcon fontSize="12" sx={seleted === "/roles" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Rôle" sx={seleted === "/roles" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {contribution && contribution.read &&
                                            <Link to="/csociales" onClick={() => this.setUrl("/csociales")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'csociales'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <CurrencyExchangeIcon fontSize="12" sx={seleted === "/csociales" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="cotisations sociales" sx={seleted === "/csociales" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link> 
                                        }

                                        {discipline && discipline.read &&    
                                            <Link to="/sanction" onClick={() => this.setUrl("/sanction")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'sanction'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <DoDisturbAltIcon fontSize="12" sx={seleted === "/sanction" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Sanct. disciplinaire" sx={seleted === "/sanction" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link>
                                        }

                                        {vacation && vacation.read &&
                                            <Link to="/conge" onClick={() => this.setUrl("/conge")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'conge'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <EventNoteIcon fontSize="12" sx={seleted === "/conge" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Types de congés" sx={seleted === "/conge" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link> 
                                        }

                                        {partner && partner.read &&
                                            <Link to="/partners" onClick={() => this.setUrl("/partners")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'partners'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <Diversity2Icon fontSize="12" sx={seleted === "/partners" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Gestion partenaires" sx={seleted === "/partners" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link> 
                                        }

                                        {provider && provider.read &&
                                            <Link to="/providers" onClick={() => this.setUrl("/providers")}  style={{textDecoration: 'none'}}>
                                                <ListItem key={'providers'} disablePadding sx={{ display: 'block' }}>                                
                                                    <ListItemButton sx={{ pl: 4 }}>
                                                        <ListItemIcon sx={{fontSize: 16, minWidth: 28}}>
                                                            <EngineeringIcon fontSize="12" sx={seleted === "/providers" ? {color:"#306bff"} : null}/>
                                                        </ListItemIcon>
                                                        <ListItemText primaryTypographyProps={{fontSize: 12}} primary="Gestion fournisseurs" sx={seleted === "/providers" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                                    </ListItemButton>
                                                </ListItem>
                                            </Link> 
                                        }                                        
                                    </List>
                                </Collapse>

                                {log && log.read &&
                                    <Link to="/logs"  onClick={() => this.setUrl("/logs")}  style={{textDecoration: 'none'}}>
                                        <ListItem key={'logs'} disablePadding sx={{ display: 'block' }}>
                                            <ListItemButton
                                                sx={{
                                                    minHeight: 48,
                                                    justifyContent: open ? 'initial' : 'center',
                                                    px: 2.5,
                                                }}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: 0,
                                                        mr: open ? 1.5 : 'auto',
                                                        justifyContent: 'center',
                                                        fontSize: 20
                                                    }}
                                                >
                                                    <TimelineIcon fontSize="12" sx={seleted === "/logs" ? {color:"#306bff"} : null}/>
                                                </ListItemIcon>
                                                <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Traces des activites'}  sx={seleted === "/logs" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}} />
                                            </ListItemButton>
                                        </ListItem>
                                    </Link>
                                }

                                <Link to="/profil"  onClick={() => this.setUrl("/profil")}  style={{textDecoration: 'none'}}>
                                    <ListItem key={'profil'} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                justifyContent: open ? 'initial' : 'center',
                                                px: 2.5,
                                            }}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    mr: open ? 1.5 : 'auto',
                                                    justifyContent: 'center',
                                                    fontSize: 20
                                                }}
                                            >
                                                <PersonIcon fontSize="12" sx={seleted === "/profil" ? {color:"#306bff"} : null} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{fontSize: 12}} primary={'Profil'}  sx={seleted === "/profil" ? { opacity: open ? 1 : 0, color:'#306bff'} : { opacity: open ? 1 : 0, color:'black'}}  />
                                        </ListItemButton>
                                    </ListItem>
                                </Link>
                            </List>
                        :
                            null
                        }
                    </Drawer>

                    <Box component="main" sx={{ flexGrow: 1, p: 2, width: !open ? "calc(100%  - 60px)" : "100%" }}>    
                        <DrawerHeader />   

                        {!loading ? 
                            <Routes>                            
                                {dashboard && dashboard.read &&
                                    <Route 
                                        path="dashboard" 
                                        element={<Dashboard  accesses={dashboard} />} 
                                    /> 
                                }

                                {project && project.read &&
                                    <Route 
                                        path="projects" 
                                        element={<Projects accesses={project} />}  
                                    />
                                }

                                {bank && bank.read &&
                                    <Route 
                                        path="banks" 
                                        element={<Banks accesses={bank} />}  
                                    />
                                }

                                {cash && cash.read &&
                                    <Route 
                                        path="registers" 
                                        element={<Registers accesses={cash} />}  
                                    />     
                                }  

                                {invoice && invoice.read &&   
                                    <Route 
                                        path="invoices" 
                                        element={<Invoices accesses={invoice} />}  
                                    />
                                }

                                {rh && rh.read &&
                                    <Route 
                                        path="employes" 
                                        element={<Employes accesses={rh} />}  
                                    />
                                }

                                {letter && letter.read &&
                                    <Route 
                                        path="letters" 
                                        element={<Letters accesses={letter} />}  
                                    />
                                }
                                
                                {event && event.read &&
                                    <Route 
                                        path="events" 
                                        element={<Events accesses={event} />}  
                                    />    
                                }
                                
                                {user && user.read && 
                                    <Route 
                                        path="users" 
                                        element={<Users accesses={user} onChange={this.componentDidMount} />}  
                                    />  
                                }

                                {role && role.read &&
                                    <Route 
                                        path="roles" 
                                        element={<Roles accesses={role} onChange={this.componentDidMount} />}  
                                    />
                                }
                                
                                {contribution && contribution.read && 
                                    <Route 
                                        path="csociales" 
                                        element={<Csociales accesses={contribution} />}  
                                    />
                                }

                                {discipline && discipline.read &&
                                    <Route 
                                        path="sanction" 
                                        element={<Sanction accesses={discipline} />}  
                                    />
                                }

                                {vacation && vacation.read &&
                                    <Route 
                                        path="conge" 
                                        element={<Conge accesses={vacation} />}  
                                    />  
                                }  

                                {partner && partner.read &&
                                    <Route 
                                        path="partners" 
                                        element={<Partners accesses={partner} />}  
                                    />      
                                }

                                {provider && provider.read &&
                                    <Route 
                                        path="providers" 
                                        element={<Providers accesses={provider} />}  
                                    />     
                                }  

                                {log && log.read &&
                                    <Route 
                                        path="logs" 
                                        element={<Logs accesses={log} />}  
                                    />
                                }   
                                
                                {/* <Route 
                                    path="members" 
                                    element={<Members accesses={accesses[0]} />}  
                                /> */}

                                <Route 
                                    path="profil" 
                                    element={<Profil  onChange={this.componentDidMount}/>} 
                                /> 
                            </Routes>
                        :
                            null
                        }
                    </Box>
                </Box>

                {/* <Backdrop sx={{ color: 'yellow', zIndex: (theme) => theme.zIndex.drawer + 1000 }} open={true}>
                    <CircularProgress color="inherit" />
                </Backdrop>             */}

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isDid} autoHideDuration={6000} onClose={() => this.setState({ isDid: false })} >
                    <Alert severity="success" sx={{ width: '100%' }} onClose={() => this.setState({ isDid: false })} >{message}</Alert>
                </Snackbar>

                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={isNotDit} autoHideDuration={6000} onClose={() => this.setState({ isNotDit: false })} >
                    <Alert severity="error" sx={{ width: '100%' }} onClose={() => this.setState({ isNotDit: false })} >{message}</Alert>
                </Snackbar>
            </BrowserRouter>
        )

    }

}